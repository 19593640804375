import { JsonModel } from 'kn-react';

export default class PlanModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }



  static fromJSON(json){
    const model = new PlanModel();
    return JsonModel.modelFromJSON(model, json);
  }


  toJSON(){
    return JsonModel.modelToJSON(this);
  }



  static isAdsAvailable(plans){
    return plans.filter(p => p.isAdsIncluded).length;
  }

  static isReviewsAvailable(plans){
    return plans.filter(p => p.isReviewsIncluded).length;
  }

  static isSitesAvailable(plans){
    return plans.filter(p => p.isSitesIncluded).length;
  }

  static isSocialAvailable(plans){
    return plans.filter(p => p.isSocialIncluded).length;
  }

  static isVirtualAvailable(plans){
    return plans.filter(p => p.isVirtualIncluded).length;
  }



  static availableServices(plans) {
    const services = {
      isAdsAvailable: 'Ads',
      isReviewsAvailable: 'Reviews',
      isSitesAvailable: 'Sites',
      isSocialAvailable: 'Social',
      isVirtualAvailable: 'Virtual',
    };


    const availableServices = [];

    Object.keys(services).forEach(k => {
      if( PlanModel[k](plans) ){
        availableServices.push(services[k])
      }
    });

    return availableServices;
  }



  capitalizedBillingPeriod(){
    if( !this.billingPeriod || !this.billingPeriod.split('').length ){
      return '';
    }

    return this.billingPeriod.slice(0,1).toUpperCase() + this.billingPeriod.slice(1).toLowerCase();
  }



  isCreditCardRequired = () => (
    this.planAmountCents > 0 ||
    this.setupFeeCents > 0
  )
}