import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
	<SvgIcon className={props.className || ''} xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" xml-space="preserve">
		<g>
			<path d="M20,18l-3-2v1H3V3h1L2,0L0,3h1v15.5C1,18.8,1.2,19,1.5,19L17,19v1L20,18z"></path>
			<rect x="5" y="9" width="2" height="6"></rect>
			<rect x="9" y="6" width="2" height="9"></rect>
			<rect x="13" y="3" width="2" height="12"></rect>
		</g>
	</SvgIcon>

);

