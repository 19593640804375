import React from 'react';
import ReactToPrint from 'react-to-print';

import { format, constructPostMeetingTranscript } from 'kn-react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Print } from '@material-ui/icons';


import TranscriptContent from '../TranscriptContent/TranscriptContent';
import Alert from '../Alert/Alert';


const SUMMARY_TRANSCRIPT_NAME = 'Summary Transcript';
const SUMMARY_NOTES_NAME = 'Summary Notes';

const AUDIO_VIDEO_SUMMARIES = 'Audio/Video Summary';
const TEXT_SUMMARIES = 'Text Summary';

const SUMMARY_BREAKOUT_TYPE = 'Summary';
const MEETING_SUMMARY_NOTE_TYPE = 'MEETING_SUMMARY';

const TABS = [
  SUMMARY_TRANSCRIPT_NAME,
  SUMMARY_NOTES_NAME
];


class MeetingSummary extends React.Component {
  constructor(props){
    super(props);

    this.audioVideoPrintableComponent = React.createRef();
    this.textPrintableComponent = React.createRef();


    const summaries = this.constructSummaries();

    let activeSummaryId;
    let activeSummaryType;
    if( summaries && summaries.length ){
      activeSummaryId = summaries[ 0 ].id;
      activeSummaryType = summaries[ 0 ].type;
    }


    this.state = {
      tab: 0,
      text: null,
      activeSummaryType,
      activeSummaryId
    };
  }


  onClickListItem = (activeSummaryType, activeSummaryId) => {
    return this.setState({
      activeSummaryType,
      activeSummaryId
    });
  }


  renderAddNote = text => (
    <div className={this.props.classes.addNote}>
      <Button
        variant='outlined'
        color='primary'
        onClick={() => this.props.onClickInsert( text )}
      >
        { this.props.addNoteButtonLabel || 'Add to Note' }
      </Button>
    </div>
  )


  renderNotes = notes => {
    const { classes } = this.props;

    return (
      <List>
        {
          notes.map((n, i) => (
            <React.Fragment>
              <ListItem
                key={i}
                className={classes.note}
              >
                <ListItemText
                  primary={(
                    <React.Fragment>
                      {
                        (
                          !this.props.canInsertDetails ||
                          !n.content
                        ) ?
                          null
                        :
                          this.renderAddNote( n.content )
                      }

                      { n.content }
                    </React.Fragment>
                  )}
                  primaryTypographyProps={{
                    className: classes.noteContent
                  }}
                  secondary={format.dateTime( n.createdAt )}
                  secondaryTypographyProps={{
                    className: classes.noteDateTime
                  }}
                />
              </ListItem>
            </React.Fragment>
          ))
        }
      </List>
    );
  }


  renderAudioVideoSummary = () => {
    const { classes } = this.props;

    const meeting = this.props.meetings.find(
      m => m.id === this.state.activeSummaryId
    );

    let notes = this.props.notes.filter(
      n => n.noteSourceId === this.state.activeSummaryId
    );


    notes = notes.sort((a, b) => a.createdAt - b.createdAt)


    const text = constructPostMeetingTranscript(
      meeting
    );

    return (
      <div
        className={classes.audioVideoWrapper}
        ref={divRef => {
          this.audioVideoPrintableComponent = divRef;
        }}
      >
        <Typography
          variant={'h6'}
        >
          Audio/Video Summary
        </Typography>

        <Typography
          variant={'caption'}
        >
          { format.dateTime( meeting.endedAt ) }
        </Typography>

        <video
          controls={true}
          src={meeting.videoRecordingUrl}
          className={classes.video}
        />

        <div
          className={classes.transcriptAndNotes}
        >
          <Tabs
            value={this.state.tab}
            onChange={(e, tab) => this.setState({ tab})}
            className={classes.tabs}
          >
            <Tab
              label={SUMMARY_TRANSCRIPT_NAME}
              disabled={!Boolean(text)}
            />

            <Tab
              label={SUMMARY_NOTES_NAME}
              disabled={!Boolean(notes && notes.length)}
            />
          </Tabs>

          <div className={classes.loadingWarning}>
            <div className={classes.loadingWarningText}>
              <Typography variant='caption'>
                Tabs may take a few seconds to process and appear.
              </Typography>
            </div>

            {
              this.props.cannotPrint ?
                null
              :
                <ReactToPrint
                  trigger={() => (
                    <IconButton>
                      <Print className={classes.print}/>
                    </IconButton>
                  )}
                  content={() => this.audioVideoPrintableComponent}
                  bodyClass={classes.printWindow}
                />
            }
          </div>

          <div
            className={classes.tabContentWrapper}
          >
            {
              TABS[ this.state.tab ] === SUMMARY_TRANSCRIPT_NAME ?
                <React.Fragment>
                  {
                    (
                      !this.props.canInsertDetails ||
                      !text
                    ) ?
                      null
                    :
                      this.renderAddNote( text )
                  }

                  <TranscriptContent
                    text={text}
                  />
                </React.Fragment>
              :
                this.renderNotes( notes )
            }
          </div>
        </div>
      </div>
    );
  }



  renderNoteSummary = () => {
    const { classes } = this.props;

    let note = this.props.notes.find(
      n => n.id === this.state.activeSummaryId
    );

    return (
      <div
        className={classes.audioVideoWrapper}
        ref={divRef => {
          this.textPrintableComponent = divRef;
        }}
      >
        <Typography
          variant={'h6'}
        >
          Text Summary
        </Typography>

        <Typography
          variant={'caption'}
        >
          { format.dateTime( note.createdAt ) }
        </Typography>

        <div
          className={classes.transcriptAndNotes}
        >
          <div className={classes.loadingWarning}>
            <ReactToPrint
              trigger={() => (
                <IconButton>
                  <Print className={classes.print}/>
                </IconButton>
              )}
              content={() => this.textPrintableComponent}
              bodyClass={classes.printWindow}
            />
          </div>

          <div
            className={classes.tabContentWrapper}
          >
            { this.renderNotes([ note ]) }
          </div>
        </div>
      </div>
    );
  }



  constructSummaries = () => {
    let summaries = [];

    this.props.meetings.forEach(
      m => {
        const meetingRoom = this.props.meetingRooms.find(
          r => r.id === m.meetingRoomId
        );

        if( meetingRoom.breakoutType === SUMMARY_BREAKOUT_TYPE ){
          summaries.push({
            type: AUDIO_VIDEO_SUMMARIES,
            postedAt: m.endedAt,
            id: m.id,
          });
        }
      }
    );

    this.props.notes.forEach(
      n => {
        if( n.noteType === MEETING_SUMMARY_NOTE_TYPE ){
          summaries.push({
            type: TEXT_SUMMARIES,
            postedAt: n.createdAt,
            id: n.id,
          });
        }
      }
    );


    return summaries.sort(
      (a, b) => a.postedAt - b.postedAt
    );
  }



  render(){
    const { classes } = this.props;


    const summaries = this.constructSummaries();

    if( !summaries.length ){
      return (
        <Paper
          className={classes.noSummariesPaper}
        >
          <Typography
            variant='caption'
            className={classes.noSummariesText}
          >
            No summaries available
          </Typography>
        </Paper>
      )
    }


    return (
      <Paper
        className={classes.paper}
      >
        {
          !this.props.canInsertDetails ?
            null
          :
            <Alert warning>
              Please select the textual summary you want to add.
            </Alert>
        }

        <Typography>
          Meeting Summaries
        </Typography>

        <div className={classes.summaryWrapper}>
          <div
            className={classes.summaries}
            id='meeting-summary-summaries'
          >
            <List>
              {
                summaries.map((s, i) => (
                  <ListItem
                    key={i}
                    button={true}
                    onClick={() => this.onClickListItem(s.type, s.id)}
                    selected={s.id === this.state.activeSummaryId}
                  >
                    <ListItemText
                      primary={s.type}
                      secondary={format.dateTime(s.postedAt)}
                    />
                  </ListItem>
                ))
              }
            </List>
          </div>

          <div
            className={classes.details}
            id='meeting-summary-details'
          >
            {
              this.state.activeSummaryType === AUDIO_VIDEO_SUMMARIES ?
                this.renderAudioVideoSummary()
              :
                this.state.activeSummaryType === TEXT_SUMMARIES ?
                  this.renderNoteSummary()
                :
                  <div className={classes.noSummariesPaper}>
                    <Typography
                      variant='caption'
                      className={classes.noSummariesText}
                    >
                      Click on a summary to the left to get started
                    </Typography>
                  </div>
            }
          </div>
        </div>
      </Paper>
    )
  }
}


const styles = theme => ({
  paper: {
    height: '100%',
    margin: 'auto',
    padding: 20,
    // overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'wrap'
    // position: 'relative',
  },
  tabs: {
    marginBottom: 15
  },
  loadingWarning: {
    marginBottom: 15,
    // marginTop: -5,
    opacity: 0.5,
    display: 'flex',
  },
  printWindow: {
    backgroundColor: 'white',
    // breakInside: 'avoid',
    // marginLeft: -215,
    // marginRight: -250,
    // paddingRight: 40
    padding: 50
  },
  print: {
    fontSize: 24
  },
  loadingWarningText: {
    flex: 1
  },
  title: {
    position: 'absolute',
    top: 0,
    left: 15,
    height: 50,
    paddingTop: 25,
    right: 0,
  },
  summaries: {
    position: 'absolute',
    top: 0,
    left: 15,
    bottom: 0,
    // right: 215,
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      left: 15,
      bottom: '50%',
      right: 15,
      borderBottom: '1px solid lightGrey',
    },
  },
  details: {
    position: 'absolute',
    top: 0,
    left: 215,
    bottom: 0,
    right: 15,
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      top: '50%',
      left: 15,
      bottom: 0,
      right: 15,
      padding: 0,
    },
    // breakInside: 'avoid',
  },
  video: {
    maxWidth: '100%',
    borderRadius: 5,
    marginTop: 15,
    marginBottom: 15
  },
  audioVideoWrapper: {
    padding: 20,
    // breakInside: 'avoid',
    // maxWidth: 750,
    // maxWidth: 1000,
  },
  transcriptAndNotes: {

  },
  tabContentWrapper: {
    // breakInside: 'avoid',
  },
  note: {
    border: '1px solid lightgrey',
    borderRadius: 20,
    padding: 20,
    marginBottom: 15,
    breakInside: 'avoid',
  },
  noteContent: {
    // breakInside: 'avoid',
  },
  noteDateTime: {
    marginTop: 10
  },
  noSummariesPaper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  noSummariesText: {
    margin: 'auto',
    color: 'darkgrey'
  },
  addNote: {
    width: '100%',
    marginBottom: 10
  },
  summaryWrapper: {
    position: 'relative',
    height: '100%',
  }
})


MeetingSummary.defaultProps = {
  meetingRooms: [],
  meetings: [],
  notes: [],
  canInsertDetails: false,
  onClickInsert: () => {},
  addNoteButtonLabel: null,
  cannotPrint: false
}


export default withStyles(styles)(
  MeetingSummary
)