import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';


const styles = theme => ({
  content: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: 'transparent',
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    maxWidth: 300,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 400,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },

});

const simpleModal = props => {
  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.open}
        onClose={props.onClose}
      >
        <div className={props.classes.content}>
          {props.children}
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(simpleModal);

// export default SimpleModalWrapped;