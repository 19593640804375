import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import StarRate from '@material-ui/icons/StarRate';
import servicesColors from '../ServicesColors/servicesColors';

const reviewsChip = props => (
  <Chip
    avatar={ <Avatar><StarRate/></Avatar> }
    label="Reviews"
    classes={{ root: props.classes.root, avatar: props.classes.avatar}}
  />
)

const styles = theme => ({
  root: {
    color: servicesColors.reviews.primary,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${servicesColors.reviews.primary}`,
  },
  avatar: {
    backgroundColor: servicesColors.reviews.primary,
    color: servicesColors.reviews.contrastText,
  },
});

export default withStyles(styles)(reviewsChip);