import React from 'react';
import Axios from 'axios';

import { withContext, log } from 'kn-react';

import { UserRoleContext } from '../UserRoles/UserRoleProvider';


class GoogleMapsMap extends React.Component{
  state = {
    mapsApiKey: ''
  }


  componentDidMount = () => {
    let url = `/api/core/google_maps/api_key`;

    if( this.props.baseUrl ){
      url = this.props.baseUrl + url;
    }

    return Axios.get(
      url,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      response => this.setState({ mapsApiKey: response.data.api_key })
    ).catch(error => {
      if( ![401, 403].includes( error.response.status ) ){
        log(error)
      }
    });
  }


  render(){
    if(
      !this.state.mapsApiKey ||
      !this.props.parameters ||
      !Object.keys( this.props.parameters ).length
    ){
      return <React.Fragment></React.Fragment>;
    }


    let parameterString = Object.keys( this.props.parameters ).map(
      k => `${ k }=${ this.props.parameters[ k ] }`
    ).join('&');

    // Refer to https://developers.google.com/maps/documentation/embed/embedding-map#choosing_map_modes
    if( parameterString ){
      parameterString = '&' + parameterString;
    }


    return (
      <iframe
        width={this.props.width}
        height={this.props.height}
        frameBorder="0"
        style={{ border:0 }}
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/${ this.props.mapMode }?key=${ this.state.mapsApiKey }${ parameterString }`}
        allowFullScreen
      />
    );
  }
}


GoogleMapsMap.defaultProps = {
  width: 450,
  height: 250,
  mapMode: 'place',
  parameters: {},
  getUserRoleAuthHeaders: () => {},
  baseUrl: null
}


export default withContext(
  UserRoleContext,
  GoogleMapsMap
)