import React from 'react';

import { opportunityConstants, gradeOpportunity } from 'kn-react';

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Person from '@material-ui/icons/Person';
import Email from '@material-ui/icons/Email';
import Call from '@material-ui/icons/Call';
import LocationOn from '@material-ui/icons/LocationOn';
import Grade from '@material-ui/icons/Grade';


const { CONTACT_KEY, ADDRESS_KEY, AREA_MAP_KEY, PHOTO_KEY, VIDEO_KEY, PHOTO_UPLOAD, VIDEO_UPLOAD, ADDRESS_AUTOSUGGEST, MULTIPLE_CHOICE, MULTIPLE_INPUT } = opportunityConstants;



const KEYS_TO_SKIP = new Set([
  AREA_MAP_KEY,
  CONTACT_KEY,
  ADDRESS_KEY,
  PHOTO_KEY,
  VIDEO_KEY
]);


const STEP_TYPES_TO_SKIP = new Set([
  PHOTO_UPLOAD,
  VIDEO_UPLOAD
]);



class OpportunityInformation extends React.Component {
  renderNoData = () => (
    <span className={this.props.classes.noDataText}>
      No user provided data
    </span>
  )



  renderInformation = options => {
    const { classes } = this.props;
    const { data, key, headline, value } = ( options || key );


    let primaryValue = value;
    let primaryHeadline = headline;

    if( data ){
      if( data.type === MULTIPLE_INPUT ){
        if( !data.value ){
          return;
        }


        const children = [];

        const headlinePrefix = (
          data.label ?
            `${ data.label } - `
          :
            ''
        );

        const valueKeys = Object.keys( data.value );
        for( let i in valueKeys ){
          const responseValue = data.value[ valueKeys[ i ] ] || {};

          const inputHeadline = responseValue.label || '';
          const inputValue = responseValue.value || '';

          const child = this.renderInformation({
            headline: `${ headlinePrefix }${ inputHeadline }`,
            value: inputValue,
            key: `${ key || data.key }-${ i }`
          });


          children.push( child );
        }


        return children;
      }


      primaryHeadline = data.label;


      switch ( data.type ) {
        case ADDRESS_AUTOSUGGEST:
          primaryValue = (
            data.value ?
              data.value.fullAddress
            :
              ''
          );
          break;
        case MULTIPLE_CHOICE:
          primaryValue = (
            data.value ?
              data.value.label
            :
              ''
          );
          break;
        default:
          break;
      }
    }


    return (
      <Grid
        item
        xs={12}
        md={6}
        key={key || data.key}
      >
        <Typography variant='caption' className={classes.question}>
          { primaryHeadline }
        </Typography>

        <Typography variant='body1' className={classes.response}>
          { primaryValue || this.renderNoData() }
        </Typography>
      </Grid>
    );
  }



  render(){
    const { classes } = this.props;


    const additionalData = (
      (
        !this.props.opportunity ||
        !this.props.opportunity.additionalData
      ) ?
        []
      :
        this.props.opportunity.additionalData
    );


    const areaMapQuestion = fetchOpportunityResponseByKey(
      AREA_MAP_KEY,
      additionalData
    );

    const addressQuestion = fetchOpportunityResponseByKey(
      ADDRESS_KEY,
      additionalData
    );

    const otherAdditionalData = additionalData.filter(
      d => !KEYS_TO_SKIP.has( d.key ) && !STEP_TYPES_TO_SKIP.has( d.type )
    );


    const grade = gradeOpportunity(
      this.props.opportunity,
      this.props.steps
    );


    const name = (
      this.props.opportunity ?
        this.props.opportunity.name
      :
        ''
    );

    const phoneNumber = (
      this.props.opportunity ?
        this.props.opportunity.phoneNumber
      :
        ''
    );

    const email = (
      this.props.opportunity ?
        this.props.opportunity.email
      :
        ''
    );


    return(
      <div>
        <div>
          <Typography variant='h6' className={classes.information}>
            { this.props.title || 'Information' }
          </Typography>

          <Grid container spacing={8}>
            <Grid item xs={4}>
              <div className={classes.sectionHeader}>
                <Person className={classes.sectionHeaderIcon}/>

                <Typography variant='caption' className={classes.sectionHeaderText}>
                  Name
                </Typography>
              </div>

              <Typography variant='body1' className={classes.response}>
                { name ? name : this.renderNoData( name ) }
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <div className={classes.sectionHeader}>
                <Call className={classes.sectionHeaderIcon}/>

                <Typography variant='caption' className={classes.sectionHeaderText}>
                  Phone Number
                </Typography>
              </div>

              <Typography variant='body1' className={classes.response}>
                {
                  phoneNumber ?
                    <a href={`tel:${ phoneNumber }`}>
                      { phoneNumber }
                    </a>
                  :
                    this.renderNoData( phoneNumber )
                }
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <div className={classes.sectionHeader}>
                <Email className={classes.sectionHeaderIcon}/>

                <Typography variant='caption' className={classes.sectionHeaderText}>
                  Email
                </Typography>
              </div>

              <Typography variant='body1' className={classes.response}>
                {
                  email ?
                    <a href={`mailto:${ email }`}>
                      { email }
                    </a>
                  :
                    this.renderNoData( email )
                }
              </Typography>
            </Grid>

            {
              !grade ?
                null
              :
                <Grid item xs={4}>
                  <div className={classes.sectionHeader}>
                    <Grade className={classes.sectionHeaderIcon}/>

                    <Typography variant='caption' className={classes.sectionHeaderText}>
                      Grade
                    </Typography>
                  </div>

                  <Typography variant='body1' className={classes.response}>
                    Grade: <b>{ grade }</b>
                  </Typography>
                </Grid>
            }

            {
              !addressQuestion.value.fullAddress ?
                null
              :
                <Grid item xs={grade ? 8 : 12}>
                  <div className={classes.sectionHeader}>
                    <LocationOn className={classes.sectionHeaderIcon}/>

                    <Typography variant='caption' className={classes.sectionHeaderText}>
                      Address
                    </Typography>
                  </div>

                  <Typography variant='body1' className={classes.response}>
                    { addressQuestion.value.fullAddress }
                  </Typography>
                </Grid>
            }
          </Grid>

          <br />

          <Typography variant='body1' className={classes.response}>
            { this.props.opportunity.message }
          </Typography>

          <br />

          {
            !areaMapQuestion || !areaMapQuestion.polygon ?
              null
            :
              <Typography variant='body1' className={classes.response}>
                <Button
                  onClick={this.props.onClickPavementArea}
                  component='a'
                  variant='outlined'
                  color='primary'
                >
                  View Pavement Area
                </Button>
              </Typography>
          }

          {
            !otherAdditionalData || !otherAdditionalData.length ?
              null
            :
              <Grid container className={classes.otherAdditionalData}>
                {
                  otherAdditionalData.map(
                    data => this.renderInformation({ data })
                  )
                }
                <br />
              </Grid>
          }
        </div>
      </div>
    );
  }
}


const styles = theme => ({
  information: {
    marginBottom: 15
  },
  response: {
    marginBottom: 10
  },
  question: {

  },
  sectionHeader: {
    borderBottom: '1px solid lightgrey',
    display: 'flex',
    marginBottom: 2
  },
  sectionHeaderIcon: {
    fontSize: 12,
    marginRight: 5,
    marginTop: 4
  },
  sectionHeaderText: {
    fontSize: 12
  },
  noDataText: {
    opacity: 0.5
  },
  otherAdditionalData: {

  }
});


OpportunityInformation.defaultProps = {
  opportunity: {},
  onClickPavementArea: () => {},
  title: ''
}


export default withStyles(styles)(
  OpportunityInformation
);



export const fetchOpportunityResponseByKey = (key, additionalData, defaultObject={ value: '' }) => {
  if( !Array.isArray( additionalData ) ){
    return defaultObject;
  }


  return additionalData.find(
    d => d.key === key
  ) || defaultObject;
}