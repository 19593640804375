import React from 'react';
import { createPortal } from 'react-dom';


import { withStyles } from '@material-ui/core';



class IFrame extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      nodeToMount: null
    }
  }


  setContentRef = contentRef => {
    if(
      !contentRef ||
      !contentRef.contentWindow ||
      !contentRef.contentWindow.document
    ){
      return;
    }


    this.setState({
      nodeToMount: contentRef.contentWindow.document.body
    })
  }


  render() {
    const { children, classes, ...props } = this.props
    const { nodeToMount } = this.state

    return (
      <iframe
        {...props}
        className={classes.iframe}
        ref={this.setContentRef}
      >
        {nodeToMount && createPortal(children, nodeToMount)}
      </iframe>
    )
  }
}



const styles = theme => ({
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%'
  }
})



export default withStyles(styles)(
  IFrame
);