import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 32 21">
    <g fill="none" fillRule="evenodd">
      <g id="card" transform="translate(-1 2)">
        <path fill="#1D91CE" d="M27.58 19H3.42A2.4 2.4 0 0 1 1 16.62V2.38A2.4 2.4 0 0 1 3.42 0h24.16A2.4 2.4 0 0 1 30 2.38v14.25A2.4 2.4 0 0 1 27.58 19z" />
        <polyline fill="#FFFFFF" points="5.063 11.896 4.591 13 .36 13 3.699 6 11.674 6 12.479 7.546 13.231 6 16.064 6 17.492 6 23.983 6 24.902 6.961 25.889 6 30.882 6 27.337 9.492 30.729 13 25.897 13 24.816 11.969 23.744 13 17.492 13 16.064 13 6.496 13 5.977 11.896" />
        <path fill="#1D91CE" d="M5.98 11.97h-.92.92zM16.2 7h-2.1l-1.58 3.35L10.82 7h-2.1v4.85L6.55 7H4.58l-2.32 5h1.42l.47-1.14h2.7L7.39 12H10V7.93L11.85 12h1.22l1.84-4v4h1.29V7zm8.67 1.62L23.37 7h-6.02v5H23.17l1.65-1.64L26.48 12h1.55l-2.37-2.53L28.1 7h-1.62l-1.61 1.62zM21.7 11h-3.06V9.9h3.06V8.9h-3.06V8h3.06v-.85l2.27 2.27-2.27 2.28V11zM5.53 7.82l.88 2.03H4.58l.95-2.03z" />
      </g>
    </g>
  </SvgIcon>
);