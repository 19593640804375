import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import PrimaryAndSecondaryColors from '../../PrimaryAndSecondaryColors/PrimaryAndSecondaryColors';


class PrimaryAndSecondaryQuestion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      color: '',
      colorsFromLogo: [],
      showPrimaryAdvanced: false,
      showSecondaryAdvanced: false,
      showPrimaryAdvanced: false,
      showSecondaryAdvanced: false
    }
  }


  onChange = color => {
    this.setState({ color: color.hex });
    this.props.onChange( color.hex );
  }


  getColors = colorsFromLogo => {
    if( colorsFromLogo && colorsFromLogo.length ){
      colorsFromLogo = [ ...new Set( colorsFromLogo ) ];

      const existingColorsSet = new Set( this.state.colorsFromLogo );
      const newColorsSet = new Set( colorsFromLogo );

      const hasSameColors = (
        existingColorsSet.size === newColorsSet.size &&
        [ ...newColorsSet ].every(c => existingColorsSet.has( c ))
      );

      if( !hasSameColors ){
        this.setState({ colorsFromLogo });
      }
    }
  }


  render() {
    const { questions, classes, responseDataByQuestionId } = this.props;

    const primaryColorQuestion = questions.find(
      q => q.isPrimaryColor
    );
    const secondaryColorQuestion = questions.find(
      q => q.isSecondaryColor
    );


    const primaryColorResponse = responseDataByQuestionId[ primaryColorQuestion.id ];
    const secondaryColorResponse = (
      secondaryColorQuestion ?
        responseDataByQuestionId[ secondaryColorQuestion.id ]
      :
        null
    );


    return (
      <PrimaryAndSecondaryColors
        uploadLogoUrl={this.props.uploadLogoUrl}
        primaryColorSelectedColor={primaryColorResponse}
        secondaryColorSelectedColor={secondaryColorResponse}
        previewUrl={this.props.previewUrl}
        shouldRenderSecondaryColor={Boolean( secondaryColorQuestion )}
        logoUrl={this.props.logoUrl}
        onChangePrimaryColor={choice => this.props.onChange(primaryColorQuestion, choice)}
        onChangeSecondaryColor={choice => this.props.onChange(secondaryColorQuestion, choice)}
      />
    )
  }
}

PrimaryAndSecondaryQuestion.defaultProps = {
  previewUrl: '',
  logoUrl: '',
  questions: [],
  responseDataByQuestionId: {},
  onChange: () => {},
  uploadLogoUrl: "/sites/setup/logo"
}


const styles = theme => ({
})



export default withStyles(styles)(
  PrimaryAndSecondaryQuestion
);