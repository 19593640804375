/*global chrome*/
export default link => {
  if(link.isActive) {
    return link.isActive(link)
  } else if( typeof window === 'undefined' ){
    return false;
  } else if( link.exactPathMatch ) {
    return window.location.pathname === link.path;
  } else {
    return window.location.pathname.startsWith(link.path);
  }
}