import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import GoogleAuth from '../GoogleAuth/GoogleAuth';
import GoogleIcon from '../Icons/GoogleIcon';
import Clear from '@material-ui/icons/Clear';
import Refresh from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import ReviewsSignOutConfirmationDialog from '../ReviewsSignOutConfirmationDialog/ReviewsSignOutConfirmationDialog';


import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Hidden, Button } from '@material-ui/core';
import { log, withContext } from 'kn-react';
import GoogleAuthMyBusinessAccountsModal from './GoogleAuthMyBusinessAccountsModal';
import GoogleAuthLocationModal from './GoogleAuthLocationModal';
import { GoogleAuthContext } from './GoogleAuthProvider'
import Alert from '../Alert/Alert';

const googleAuthLocation = props => {
  if( props.isProcessingGoogleAccount ){
    return <CircularProgress />;
  }


  const { classes, googleAccount, googleAccountLocation } = props;

  const loginRequired = (googleAccount.id) ? false : true;
  const fullyLoggedIn = googleAccountLocation.apiLocationId && googleAccount.apiAccountId;
  /*
    Process flow for Google oauth is:
      Google Account --> Google My Business --> Google Location
  */

  const primaryListItems = (
    <React.Fragment>
      <ListItemIcon>
        <GoogleIcon/>
      </ListItemIcon>

      {
        (fullyLoggedIn) ?
          <ListItemText
            secondary={ googleAccountLocation.locationName }
            primary={ googleAccount.email }
          />
        :
          <ListItemText
            secondary="No Google Location found."
            primary={ googleAccount.email }
          />
      }
    </React.Fragment>
  );

  const secondaryListItemsDesktop = (
    <ListItemSecondaryAction style={{ paddingTop: 15 }}>
      {
        (props.canSignIn) ?
          <Tooltip title='Refresh'>
            <IconButton onClick={props.startGoogleOauth}>
              <Refresh/>
            </IconButton>
          </Tooltip>
        :
          null
      }

      {
        (props.canSignOut) ?
          <Tooltip title='Sign Out'>
            <IconButton onClick={props.openConfirmationDialog}>
              <Clear/>
            </IconButton>
          </Tooltip>
        :
          null
      }
    </ListItemSecondaryAction>
  )

  const secondaryListItemsMobile = (
    <Grid container spacing={16} item xs={12}>
      {
        (props.canSignIn) ?
          <Grid item xs={6}>
            <Button
              onClick={props.startGoogleOauth}
              variant='outlined'
              size='small'
            >
              <Refresh/>
              Refresh
            </Button>
          </Grid>
        :
          null
      }

      {
        (props.canSignOut) ?
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              onClick={props.openConfirmationDialog}
              variant='outlined'
              small='small'
            >
              <Clear/>
              Sign Out
            </Button>
          </Grid>
        :
          null
      }
    </Grid>
  )

  log('googleAccount', googleAccount)
  log('googleAccountLocation', googleAccountLocation)

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        {
          (loginRequired) ?
            <div style={{ textAlign: 'center' }}>
              <GoogleAuth
                canSignIn={props.canSignIn}
              />
            </div>
          :
            null
        }


        {
          (props.myBusinessDialogOpen) ?
            <GoogleAuthMyBusinessAccountsModal
              onClose={props.closeMyBusinessDialog}
              getGoogleMyBusinessAccountsCallback={props.getGoogleMyBusinessAccountsCallback}
              onSelectAccount={props.onSelectAccount}
              dialogOpen={props.myBusinessDialogOpen}
            />
          :
            null
        }

        {
          (props.locationsDialogOpen && googleAccount.apiAccountId) ?
            <GoogleAuthLocationModal
              onClose={props.closeLocationsDialog}
              getGoogleLocationsCallback={props.getGoogleLocationsCallback}
              onSelectLocation={props.onSelectLocation}
              dialogOpen={props.locationsDialogOpen}
            />
          :
            null
        }

        {
          (loginRequired || !googleAccount) ?
            null
          :
            props.googleAccountLocationLoading ?
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            :
              <div>
                <List className={classes.authGrouping}>
                  <Hidden xsDown>
                    <ListItem>
                      { primaryListItems }
                      { secondaryListItemsDesktop }
                    </ListItem>
                  </Hidden>

                  <Hidden smUp>
                    <div style={{ textAlign: 'center' }}>
                      <ListItem dense>
                        { primaryListItems }
                      </ListItem>
                      <ListItem>
                        { secondaryListItemsMobile }
                      </ListItem>
                    </div>
                  </Hidden>
                </List>


                <ReviewsSignOutConfirmationDialog
                  dialogOpen={ props.confirmationDialogOpen }
                  sourceName='Google'
                  onClose={ props.closeConfirmationDialog }
                  onSignOut={ props.onSignOut }
                />

                {
                  (googleAccountLocation.apiLocationId) ?
                    null
                  :
                    <Alert warning>
                      Please create a Google Location and <Link href="#" onClick={ props.startGoogleOauth }>try again</Link>.
                    </Alert>
                }

              </div>
        }

        {
          (googleAccountLocation && googleAccountLocation.isError) ?
            <Alert warning>
              Something went wrong. Please refresh your account and <Link href="#" onClick={ props.startGoogleOauth }>try again</Link>.
            </Alert>
          :
            null
        }
      </Grid>
    </Grid>
  );

}

const styles = theme => ({
  authGrouping: {
    padding: 10,
    width: '100%'
  },
  button: {
    margin: 10
  },
  leftIcon: {
    marginRight: 10
  }
})

googleAuthLocation.defaultProps = {
  canSignIn: true,
  closeMyBusinessDialog: function() {},
  getGoogleMyBusinessAccountsCallback: function() {},
  onSelectAccount: function (account) { },
  myBusinessDialogOpen: false,
  closeLocationsDialog: function() {},
  getGoogleLocationsCallback: function() {},
  onSelectLocation: function (location) {},
  locationsDialogOpen: false,
  googleAccount: {},
  googleAccountLocation: {},
  openConfirmationDialog: function() {},
  onSignOut: function() {},
  tryAgain: function() {},
  closeConfirmationDialog: function(){},
  canSignOut: true,
  googleAccountLocationLoading: false
}

export default withContext(
  GoogleAuthContext,
  withStyles(styles, { withTheme: true })(
    googleAuthLocation
  )
);