import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import WebsiteVisitorIcon from '../Icons/WebsiteVisitorIcon';
import servicesColors from '../ServicesColors/servicesColors';

const sitesChip = props => (
  <Chip
    avatar={ <Avatar><WebsiteVisitorIcon/></Avatar> }
    label="Sites"
    classes={{ root: props.classes.root, avatar: props.classes.avatar}}
  />
)

const styles = theme => ({
  root: {
    color: servicesColors.sites.primary,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${servicesColors.sites.primary}`,
  },
  avatar: {
    backgroundColor: servicesColors.sites.primary,
    color: servicesColors.sites.contrastText,
  },
});

export default withStyles(styles)(sitesChip);