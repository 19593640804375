import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 32 21">
  <g fill="none" fillRule="evenodd">
      <g id="card" transform="translate(0 2)">
        <path fill="#EBF1F8" d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19z"/>
        <path fill="#F27712" d="M15.24 19h11.34A2.4 2.4 0 0 0 29 16.62v-2.85A46.81 46.81 0 0 1 15.24 19z"/>
        <path fill="#000000" d="M28 10.9h-1.03l-1.16-1.53h-.11v1.53h-.84V7.1h1.24c.97 0 1.53.4 1.53 1.12 0 .59-.35.97-.98 1.09L28 10.9zm-1.24-2.65c0-.37-.28-.56-.8-.56h-.26v1.15h.24c.54 0 .82-.2.82-.59zM21.92 7.1h2.38v.64h-1.54v.85h1.48v.65h-1.48v1.03h1.54v.64h-2.38V7.1zM19.22 11L17.4 7.09h.92l1.15 2.56 1.16-2.56h.9L19.69 11h-.45zm-7.61-.01c-1.28 0-2.28-.87-2.28-2 0-1.1 1.02-1.99 2.3-1.99.36 0 .66.07 1.03.23v.88a1.5 1.5 0 0 0-1.05-.43c-.8 0-1.41.58-1.41 1.31 0 .77.6 1.32 1.45 1.32.38 0 .67-.12 1.01-.42v.88c-.38.16-.7.22-1.05.22zM9.07 9.74c0 .74-.61 1.25-1.49 1.25-.64 0-1.1-.22-1.49-.72l.55-.47c.19.34.51.51.91.51.38 0 .65-.23.65-.53 0-.17-.08-.3-.25-.4a3.48 3.48 0 0 0-.58-.22c-.79-.25-1.06-.52-1.06-1.05 0-.62.58-1.09 1.34-1.09.48 0 .91.15 1.27.43l-.44.5a.92.92 0 0 0-.68-.3c-.36 0-.62.18-.62.42 0 .2.15.31.65.48.96.3 1.24.58 1.24 1.2zM4.94 7.1h.84v3.81h-.84V7.1zm-2.7 3.81H1V7.1h1.24c1.36 0 2.3.78 2.3 1.9 0 .57-.28 1.11-.77 1.47-.42.3-.89.44-1.54.44zm.98-2.86c-.28-.22-.6-.3-1.15-.3h-.23v2.52h.23c.54 0 .88-.1 1.15-.3.29-.24.46-.6.46-.97s-.17-.72-.46-.95z"/>
        <path fill="#F27712" d="M15 7c-1.1 0-2 .88-2 1.97 0 1.16.86 2.03 2 2.03 1.12 0 2-.88 2-2s-.87-2-2-2z"/>
    </g>
  </g>
  </SvgIcon>
);