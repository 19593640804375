import React from 'react';

import { SvgIcon } from '@material-ui/core';


const weekCalendarIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <rect width="16" height="16" x="4" y="5" rx="2" stroke={props.fill}/>
      <path d="M16 3v4M8 3v4m-4 4h16m-9 4h1m0 0v3" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


weekCalendarIcon.defaultProps = {
  fill: '#5E5F64'
}


export default weekCalendarIcon;