import { JsonModel } from 'kn-react';


export default class ToggleMapModel {

  constructor(props){
    if(!props){
      props = {
        includedGeographies: [],
        excludedGeographies: [],
      }
    }

    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    const model = new ToggleMapModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

}
