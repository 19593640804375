/*global window*/
/*global document*/
import React from 'react';
import Axios from 'axios';
import JssProvider from 'react-jss/lib/JssProvider'

import { log } from 'kn-react';

import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';


export const DEFAULT_CREATE_GENERATE_CLASS_NAME = createGenerateClassName({
  productionPrefix: 'go-boost__',
});

const DEFAULT_SUBDOMAIN = 'lets';
const DEFAULT_HOSTNAME = 'goboost.io';

const DEFAULT_STATE = {
  loadingTheme: true,
  theme: {
    muiTheme: createMuiTheme({})
  },
  sheetsManager: new Map()
};

export const ThemeContext = React.createContext(DEFAULT_STATE);

class ThemeProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.updateTheme();
  }

  updateTheme = () => {
    if( typeof window === 'undefined' ){
      return;
    }


    let { hostname } = window.location;

    if( hostname.includes('localhost') ){
      hostname = hostname.replace('localhost', DEFAULT_HOSTNAME);
    }

    if( this.props.useDefaultTheme ){
      hostname = `${ DEFAULT_SUBDOMAIN }.${ DEFAULT_HOSTNAME }`;
    } else if( !hostname ){
      hostname = DEFAULT_HOSTNAME;
    }


    let url = `/api/core/themes/hostname/${ hostname }`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }


    return Axios.get(
      url
    ).then(response => {
      log('updateTheme response', response);
      let theme = response.data.theme.theme_data;

      theme = {
        ...theme,
        muiTheme: createMuiTheme({
          ...theme.muiTheme,
          typography: {
            ...(theme.muiTheme.typography || {}),
            useNextVariants: true,
          }
        })
      };


      return this.setState(
        { theme },
        () => {
          this.updateTitle();
          this.updateFavicon();
        }
      );
    }).catch(error => {
      log('updateTheme error', error);
      this.props.onThemeError();
    }).finally(
      () => this.setState({ loadingTheme: false })
    );
  }

  updateTitle = () => {
    if(
      typeof document === 'undefined' ||
      this.props.shouldNotUpdateTitle
    ){
      return;
    }


    const { theme } = this.state;
    window.document.title = theme.pageTitle || 'GoBoost';
  }

  updateFavicon = () => {
    if(
      typeof document === 'undefined' ||
      this.props.shouldNotRenderFavicon
    ){
      return;
    }


    const { theme } = this.state;

    if(!theme.favicon) return;

    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = theme.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  render(){
    const { classes } = this.props;
    const { theme, loadingTheme } = this.state;

    if( loadingTheme && this.props.isLinearProgressDisabled ){
      return <React.Fragment></React.Fragment>;
    } else if( loadingTheme ) {
      return <LinearProgress classes={{ barColorPrimary: classes.barColorPrimary }} color="primary"/>;
    }


    let children = (
      <MuiThemeProvider
        theme={theme.muiTheme}
      >
        { this.props.children }
      </MuiThemeProvider>
    );


    if( !this.props.disableGenerateClassName ){
      const generateClassName = this.props.generateClassName || DEFAULT_CREATE_GENERATE_CLASS_NAME;

      children = (
        <JssProvider generateClassName={generateClassName}>
          { children }
        </JssProvider>
      );
    }


    return (
      <ThemeContext.Provider
        value={{
          ...this.state,
        }}
      >
        { children }
      </ThemeContext.Provider>
    );
  }
}


ThemeProvider.defaultProps = {
  onThemeError: () => {},
  baseUrl: null,
  useDefaultTheme: false,
  shouldNotRenderFavicon: false,
  shouldNotUpdateTitle: false,
  generateClassName: null,
  isLinearProgressDisabled: false,
  disableGenerateClassName: false
};


const styles = theme => ({
  barColorPrimary: {
    backgroundColor: '#CCCCCC'
  }
});

export default withStyles(styles)(ThemeProvider);
