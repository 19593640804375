import React from 'react';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import StarsRating from '../StarsRating/StarsRating';
import withStyles from '@material-ui/core/styles/withStyles';
import RobotIcon from '../Icons/RobotIcon';
import IconButton from '@material-ui/core/IconButton';

const reviewListItem = props => {

  const { classes, headerText, bodyText } = props;

  return (
    <React.Fragment>
      <List style={{ backgroundColor: 'white' }}>
        <ListItem disableGutters style={{ padding: 0 }}>
          <IconButton color="primary"
            disableFocusRipple
            disableRipple
            className={classes.iconButton}
          >
            <RobotIcon className={classes.robotIcon}/>
          </IconButton>
          <ListItemText>
            <Typography variant="h5" className={classes.headerText}>
              { headerText }
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem disableGutters style={{ display: 'inherit', paddingBottom: 0 }}>
          <Typography className={classes.bodyText}>
            { bodyText }
          </Typography>
        </ListItem>
      </List>
      { props.divider ? <Divider/> : null }
    </React.Fragment>
  );
}

const styles = theme => ({
  bodyText: {
    color: '#3f4c4f',
    paddingTop: 0,
    paddingLeft: 18,
  },
  headerText: {
    color: '#3f4c4f',
    paddingLeft: -20,
  },
  avatar: {
    color: theme.palette.primary.contrastText,
  },
  robotIcon: {
    color: "primary",
  }
})


reviewListItem.defaultProps = {

}

export default withStyles(styles)(reviewListItem);