import React from 'react';
import TextField from '@material-ui/core/TextField';

class TimePicker extends React.Component {

  constructor(props) {
    super(props);

    const time = props.time ? parseTime(props.time) : {};
    this.state = {
      hh: time.hh || '12',
      mm: time.mm || '00',
      amPm: time.amPm || 'AM'
    };
  }

  onChange = (key, value) => {
    this.setState(
      { [key]: value },
      () => {
        const { hh, mm, amPm} = this.state;
        this.props.onChange(formatTime(hh, mm, amPm));
      }
    );
  }

  render(){
    const { disabled } = this.props;

    return (
      <React.Fragment>
        <TextField
          select
          SelectProps={{ native: true }}
          style={{ textAlign: 'center' }}
          disabled={disabled}
          value={this.state.hh}
          onChange={e => this.onChange('hh', e.target.value)}
          margin="dense"
          id={this.props.idPrefix ? this.props.idPrefix+'-hours' : undefined}
          name={this.props.namePrefix ? this.props.namePrefix+'-hours' : undefined}
        >
          {
            ['1','2','3','4','5','6','7','8','9','10','11','12'].map(h => (
              <option key={h} value={h}>{h}</option>
            ))
          }
        </TextField>

        <TextField
          select
          SelectProps={{ native: true }}
          style={{ marginLeft: 8 }}
          disabled={disabled}
          value={this.state.mm}
          onChange={e => this.onChange('mm', e.target.value)}
          margin="dense"
          id={this.props.idPrefix ? this.props.idPrefix+'-minutes' : undefined}
          name={this.props.namePrefix ? this.props.namePrefix+'-minutes' : undefined}
        >
          {
            ['00','15','30','45'].map(h => (
              <option key={h} value={h}>{h}</option>
            ))
          }
        </TextField>

        <TextField
          select
          SelectProps={{ native: true }}
          style={{ marginLeft: 8 }}
          disabled={disabled}
          value={this.state.amPm}
          onChange={e => this.onChange('amPm', e.target.value)}
          margin="dense"
          id={this.props.idPrefix ? this.props.idPrefix+'-meridiem' : undefined}
          name={this.props.namePrefix ? this.props.namePrefix+'-meridiem' : undefined}
        >
          {
            ['AM','PM'].map(h => (
              <option key={h} value={h}>{h}</option>
            ))
          }
        </TextField>
      </React.Fragment>
    );
  }

}



TimePicker.defaultProps = {
  onChange: () => {},
  disabled: false,
  idPrefix: null,
  namePrefix: null,
};

export default TimePicker;


const parseTime = time => {
  const colonSplit = time.split(':');
  const spaceSplit = colonSplit[1].split(' ');
  return {
    hh: colonSplit[0],
    mm: spaceSplit[0],
    amPm: spaceSplit[1],
  };
};

const formatTime = (hh, mm, amPm) => `${hh}:${mm} ${amPm}`;