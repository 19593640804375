/*global window*/

export default (hostname) => {
  if(
    !hostname &&
    typeof window !== 'undefined'
  ){
    hostname = window.location.hostname;
  } else if( !hostname ){
    hostname = '';
  }

  const host = hostname.split('.')[0] || '';

  return host.endsWith('-demo') || host === 'demo'
}