import React from 'react';
import { Redirect } from 'react-router-dom';
import { withContext, log } from 'kn-react';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';
import { UserRoleContext } from './UserRoleProvider';

const userRoleGuard = props => {
  log('userRoleGuard', props)
  log('isCompanyRole', props.currentUserRole.isCompanyRole());
  log('isOrganizationRole', props.currentUserRole.isOrganizationRole());
  const isAuthorized = props.authorizeRole(props.currentUserRole);
  log('userRoleGuard', isAuthorized)

  if(isAuthorized){
    log('userRoleGuard Authorized');
    return props.children;
  } else {
    log('userRoleGuard Unauthorized');
    props.showSnackbar('Unauthorized.');
    return <Redirect to="/"/>;
  }
}

export default withContext(UserRoleContext, SnackbarContext, userRoleGuard);