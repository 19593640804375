import React from 'react';

import { format } from 'kn-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DesktopIcon from '../Icons/DesktopIcon';
import PhoneIcon from '../Icons/PhoneIcon';
import TabletIcon from '../Icons/TabletIcon';
import OtherDevicesIcon from '../Icons/OtherDevicesIcon';

class DevicesTypeTable extends React.Component {

	render() {

		const { classes, desktopVisitors, mobileVisitors, tabletVisitors, otherVisitors } = this.props;

		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Visitors</TableCell>
						<TableCell>% Users</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<div className={classes.iconAndText}>
								<DesktopIcon />
								<span className={classes.iconText}>Desktop</span>
							</div>
						</TableCell>
						<TableCell>
							{format.commas(desktopVisitors)}
						</TableCell>
						<TableCell />
					</TableRow>
					<TableRow>
						<TableCell>
							<div className={classes.iconAndText}>
								<PhoneIcon />
								<span className={classes.iconText}>Mobile</span>
							</div>
						</TableCell>
						<TableCell>
							{format.commas(mobileVisitors)}
						</TableCell>
						<TableCell />
					</TableRow>
					<TableRow>
						<TableCell>
							<div className={classes.iconAndText}>
								<TabletIcon />
								<span className={classes.iconText}>Tablet</span>
							</div>
						</TableCell>
						<TableCell>
							{format.commas(tabletVisitors)}
						</TableCell>
						<TableCell />
					</TableRow>
					<TableRow>
						<TableCell>
							<div className={classes.iconAndText}>
								<OtherDevicesIcon />
								<span className={classes.iconText}>Mobile</span>
							</div>
						</TableCell>
						<TableCell>
							{otherVisitors}
						</TableCell>
						<TableCell />
					</TableRow>
				</TableBody>
			</Table>
		)
	}
}

const styles = theme => ({
	iconText: {
		lineHeight: '24px',
		paddingLeft: 10
	},
	iconAndText: {
		position: 'relative',
		marginLeft: 15,
	}
})

OtherDevicesIcon.defaultProps = {
	desktopVisitors: null,
	mobileVisitors: null,
	tabletVisitors: null,
	otherVisitors: null,
}

export default withStyles(styles, { withTheme: true })(DevicesTypeTable);