import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const textStatusIndicator = props => {
  const classOptions = [
    'primary',
    'secondary',
    'bootstrapPrimary',
    'bootstrapSecondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark'
  ];

  const optionClassName = classOptions.find(o => props[o]);


  return (
    <Typography
      variant={props.typographyVariant}
      color="textSecondary"
      className={ props.classes.text }
    >
      <span
        className={ props.classes[optionClassName] }
        style={{ marginRight: 3, fontSize: '1.0rem' }}
      >
        ●
      </span>
      {props.text}
    </Typography>
  );
}


textStatusIndicator.defaultProps = {
  text: '',
  typographyVariant: 'body2',
  default: true,
};


const styles = theme => ({
  text: {
    color: theme.palette.text.secondary,
  },
  primary: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  bootstrapPrimary: {
    color: '#004085',
  },
  bootstrapSecondary: {
    color: '#383d41',
  },
  success: {
    color: '#70d63d',
  },
  danger: {
    color: '#D53C4C',
  },
  warning: {
    color: '#F5C970',
  },
  info: {
    color: '#0c5460',
  },
  light: {
    color: '#818182',
  },
  dark: {
    color: '#1b1e21',
  }
});

export default withStyles(styles)(textStatusIndicator);