import React from 'react';

import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import NoDataWrapper from '../NoDataWrapper/NoDataWrapper';

class BarLineChart extends React.PureComponent {
	render() {

		const { data } = this.props;

		return (
			<NoDataWrapper data={data}>
				<ResponsiveContainer width={this.props.width} height={this.props.height}>
					<ComposedChart
						data={data}
						margin={this.props.chartMargin}
					>

						{
							(!this.props.gridProps) ? null : <CartesianGrid {...this.props.gridProps} />
						}

						{
							(!this.props.xAxisProps) ? null : <XAxis {...this.props.xAxisProps} />
						}

						{
							(!this.props.yAxes) ? null : this.props.yAxes.map(axis => {
								return (
									<YAxis key={axis} {...axis} />
								)
							})

						}

						{
							(!this.props.yAxis) ?
								null :
								<YAxis {...this.props.yAxis} />
							})
						}


						<Tooltip {...this.props.tooltipProps}/>

						{
							(this.props.hideLegend) ? null : <Legend {...this.props.legendProps} />
						}

						{
							(!this.props.barStyles) ? null : Object.keys(this.props.barStyles).map(style => {
								return (
									<Bar key={style} {...this.props.barStyles[style]} />
								)
							})
						}

						{
							(!this.props.lineStyles) ? null : Object.keys(this.props.lineStyles).map(style => {
								return (
									<Line key={style} {...this.props.lineStyles[style]} />
								)
							})
						}
					</ComposedChart>
				</ResponsiveContainer>
			</NoDataWrapper>
		);
	}
}

BarLineChart.defaultProps = {
	width: '100%',
	height: 500,
	xAxisProps: {
		dataKey: 'name',
		interval: 'preserveStartEnd',
	},
	chartStyle: null,
	gridProps: {
		strokeDasharray: "0 0",
		stroke: 'whitesmoke',
		vertical: false
	},
	chartMargin: {
		top: 20,
		bottom: 20,
		left: 20,
		right: 20
	},
	yAxis: null,
	hideLegend: false,
	legendProps: null,
	tooltipProps: null,
	lineStyles: null,
	barStyles: null
}


export default BarLineChart;