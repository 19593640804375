import React from 'react';
import Tablet from '@material-ui/icons/Tablet';
import withStyles from '@material-ui/core/styles/withStyles';

class TabletIcon extends React.Component {

  render() {

    const { classes } = this.props;

    return (
      <Tablet className={classes.tabletIcon} />
    )
  }
}

const styles = theme => ({
  tabletIcon: {
    color: '#ff9a48',
    float: 'left'
  }
})

TabletIcon.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(TabletIcon);