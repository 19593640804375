import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


class ImpersonatingFooter extends React.PureComponent {

  render(){
    const { classes, loggedInAs } = this.props;
    return (
      <div className={classes.root}>
          <div className={classes.loggedInAs}>Signed in as</div>
          <div>{loggedInAs} </div>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              onClick={ this.props.stopImpersonating }
              fullWidth
            >
              Sign out
            </Button>
          </div>
      </div>
    );
  }

}

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    zIndex: 1201,
  },
  loggedInAs: {
    fontSize: 8,
  },
  buttonWrapper: {
    position: 'absolute',
    width: 100,
    top: 7,
    right: 15,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    color: theme.palette.secondary.contrastText,
  }
});

export default withStyles(styles)(ImpersonatingFooter);