import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

import ChatBox from '../../ChatBox/ChatBox';

class DropdownQuestion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    };
  }

  componentDidMount = () => {
    this.setState({ value: this.props.responseData || '' })
  }

  onChange = e => {
    this.setState({ value: e.target.value })
    this.props.onChange(e.target.value);
  }

  render() {
    const options = [
      <option key={0} value="" disabled>{this.props.disabledOptionText}</option>
    ]
    // const options = [];
    this.props.question.data.options.forEach( (o,i) => {
      options.push(<option key={i + 1} value={o}>{o}</option>);
    });

    return (
      <FormControl component="fieldset" style={{ marginBottom: 25 }}>
        <ChatBox>
          <Typography variant="subtitle1">{ this.props.question.prompt }</Typography>
          <Typography variant="caption">{ this.props.question.helperText }</Typography>
        </ChatBox>
        <FormGroup>
          <div style={{ paddingTop: 15 }}>
            <TextField
              select
              SelectProps={{
                native: true
              }}
              value={ this.state.value }
              onChange={ this.onChange }
              fullWidth
            >
              {options}
            </TextField>
          </div>
        </FormGroup>
      </FormControl>
    )
  }
}

export default DropdownQuestion;