/*global window*/
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { log, parseSearchParams } from 'kn-react';


class GoogleAuthCallback extends React.Component {

  componentDidMount() {
    if( typeof window === 'undefined' ){
      return;
    }


    const searchParams = parseSearchParams(window.location.search);
    log('searchParams', searchParams);

    if (searchParams.code && searchParams.state) {
      const targetOrigin = searchParams.state.slice(0, searchParams.state.lastIndexOf('-'));
      window.opener.postMessage(
        { googleCode: searchParams.code, oauthState: searchParams.state },
        targetOrigin
      );
    }

    window.close();
  }

  render() {
    return <LinearProgress />;
  }
}

export default GoogleAuthCallback;
