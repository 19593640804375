import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

class SearchAd extends React.PureComponent {

  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.searchAd }>
        <a href="#" className={ classes.searchAdLink } onClick={e => e.preventDefault()}>
          { this.props.headline }
        </a>
        <span className={ classes.searchAdAd }>Ad</span>
        <span className={ classes.searchAdUrl }>
            { this.props.displayUrl }
        </span>

        {
          this.props.showPhoneNumber ?
            <span className={ classes.searchAdPhone}>
              { this.props.phoneNumber }
            </span>
          :
            null
        }


        <div className={ classes.searchAdDescription }>
        { this.props.description }
        </div>
      </div>
    );
  }

}

SearchAd.defaultProps = {
  headline: 'Mitchell Heating Co. | 24/7 Emergency Services‎',
  displayUrl: 'mitchellplumbing.mydex.com/Heating_Service‎',
  phoneNumber: '(855) 212-3359',
  description: '$25 Off Heating Repair Services. Where Integrity Matters. Call For Fast Service!',
  showPhoneNumber: true,
};


const styles = {
  searchAd: {
    fontFamily: "'Roboto',arial,sans-serif",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 7,
    marginBottom: 28,
    maxWidth: 632,
    overflow: 'hidden',
  },

  searchAdLink: {
    textDecoration: 'none',
    display: 'block',
    color: '#1a0dab',
    fontSize: 18,
  },

  searchAdAd: {
    backgroundColor: '#fff',
    borderRadius: 3,
    color: '#006621',
    display: 'inline-block',
    fontSize: 11,
    border: '1px solid #006621',
    paddingTop: 1,
    paddingRight: 3,
    paddingBottom: 0,
    paddingLeft: 2,
    lineHeight: '11px',
    verticalAlign: 'baseline',
    marginRight: 7,
  },

  searchAdUrl: {
    color: '#006621',
    fontSize: 18,
    maxWidth: 453,
    fontSize: 14,
  },

  searchAdPhone: {
    color: '#545454',
    fontSize: 'small',
    marginLeft: 8,
    whiteSpace: 'nowrap',
    verticalAlign: 'bottom',
  },

  searchAdDescription: {
    color: '#545454',
    lineHeight: '18px',
    fontSize: 'small',
  }
};


export default withStyles(styles)(SearchAd);