import React from 'react';
import Link from 'react-router-dom/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import ExpandMore from '@material-ui/icons/ExpandMore';
import isLinkActive from '../../isLinkActive';


class MobileSubNavigation extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = link => {
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const links = this.props.links || [];

    const activeLink = links.find(l => isLinkActive(l)) || {};

    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="When device is locked"
            onClick={this.handleClickListItem}
          >
            {
              activeLink.icon ?
                <ListItemIcon className={ classes.activeListItemIcon }>
                  { activeLink.icon }
                </ListItemIcon>
              : null
            }
            <ListItemText
              primary={activeLink.primaryText}
              secondary={activeLink.secondaryText}
              classes={{ primary: classes.activeListItemText }}
            />
            {/* {
              activeLink.secondaryAction ?
                <ListItemSecondaryAction>{activeLink.secondaryAction}</ListItemSecondaryAction>
              : null
            } */}
                <ExpandMore/>
          </ListItem>
        </List>
        <Menu
          id="MobileSubNavigationMenu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          classes={{ paper: classes.menuPaper}}
        >
          {
            links.map((l,i) => {
              if(l.divider) {
                <Divider key={i}/>
              } else {
                return (
                  <MenuItem
                    key={i}
                    selected={l === activeLink}
                    onClick={() => this.handleMenuItemClick(l)}
                    classes={ l === activeLink ? { selected: classes.activeListItemText } : null}
                    component={Link}
                    to={l.path}
                  >
                    <ListItemText
                      primary={l.primaryText}
                      secondary={l.secondaryText}
                    />
                  </MenuItem>
                );
              }

            })
          }
        </Menu>
      </div>
    );
  }
}


const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuPaper: {
    width: '100%',
  },
  activeListItemIcon: {
    color: theme.palette.primary.main,
  },
  activeListItemText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
});

export default withStyles(styles)(MobileSubNavigation);
