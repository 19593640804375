import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import DesktopSubNavigation from './DesktopSubNavigation/DesktopSubNavigation';
import MobileSubNavigation from './MobileSubNavigation/MobileSubNavigation';

const subNavigation = props => {
  return (
    <React.Fragment>
      <MobileSubNavigation links={props.links} history={props.history}/>
      <DesktopSubNavigation links={props.links} history={props.history}>
        { props.children }
      </DesktopSubNavigation>
    </React.Fragment>
  );
}

const styles = theme => ({

});


export default withStyles(styles)(subNavigation);