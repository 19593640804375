import React from 'react';
import { withContext } from 'kn-react';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { ThemeContext } from '../../../Theme/ThemeProvider';


const appDrawerBrand = props => {
  const { classes, theme } = props;
  const appDrawerBrand = theme.appDrawerBrand || {};
  return (
    <div className={classes.toolbar} style={{ backgroundColor: 'whitesmoke' }}>

    {
      appDrawerBrand.isAvatar ?
        <React.Fragment>
          <Avatar src={appDrawerBrand.avatar || `/logos/go_boost_head.png`}/>
          <Typography variant="h6" color="inherit" className={classes.appLogoText}>
            { appDrawerBrand.title }
          </Typography>
        </React.Fragment>
      :
      <div>
        <img className={classes.appLogo} style={appDrawerBrand.logoStyles} src={ appDrawerBrand.logo }/>
      </div>
    }

      {/* <Typography variant="caption" className={ classes.email }>
        { props.email || '' }
      </Typography> */}

      <IconButton onClick={props.closeDrawer} style={{ position: 'absolute', right: 5 }}>
        <ChevronLeftIcon/>
      </IconButton>
    </div>
  );
}


const styles = theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appLogo: {
    maxWidth: 180,
    maxHeight: 62,
  },
  appLogoText: {
    flexGrow: 1,
    marginLeft: 10,
    fontWeight: 'normal',
    color: theme.palette.primary.main,
  },
  email: {
    position: 'absolute',
    top: 38,
    left: 58,
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.up('sm')]: {
      top: 45,
    },
  }
})

export default withStyles(styles)(withContext(ThemeContext, appDrawerBrand));