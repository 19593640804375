import React from 'react';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import withStyles from '@material-ui/core/styles/withStyles';

class PhoneIcon extends React.Component {

  render() {

    const { classes } = this.props;
  
    return (
      <PhoneIphone className = {classes.phoneIcon}/>
    )
  }
}

const styles = theme => ({
    phoneIcon: {
        color: '#37caad',
        float: 'left'
    }
})

PhoneIcon.defaultProps = { 

}

export default withStyles(styles, {withTheme: true})(PhoneIcon);