import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';

class ReviewedChip extends React.PureComponent {
	render() {

		const { classes } = this.props;

		return (
			<Chip
				color='primary'
				label={ this.props.label || 'Reviewed' }
				classes={{ colorPrimary: classes.chipClass }}
			/>
		);
	}
}

const styles = theme => ({
	chipClass: {
		backgroundColor: '#FEDCC1',
		borderRadius: 3,
		color: '#b6692b',
		fontSize: 14,
	}
});


export default withStyles(styles)(ReviewedChip);