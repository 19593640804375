import React from 'react';

import { SvgIcon } from '@material-ui/core';


const airConditioningIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M8 16a3 3 0 0 1-3 3m11-3a3 3 0 0 0 3 3m-7-3v4" stroke={props.fill}/>
      <rect width="18" height="8" x="3" y="5" rx="2" stroke={props.fill}/>
      <path d="M7 13v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


airConditioningIcon.defaultProps = {
  fill: '#5E5F64'
}


export default airConditioningIcon;