/*global window*/
/*global document*/
import React from 'react';

import { withContext } from 'kn-react';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';

import { Paper, withStyles, Button, Tooltip } from '@material-ui/core';
import { ControlPointDuplicate, Edit, InsertComment } from '@material-ui/icons';


const HIGHLIGHT_MENU = 'highlight-menu';
export const TRANSCRIPT_GRID_ID = 'transcript-grid';


class HighlightMenu extends React.Component {
  state = {
    showCopyTooltip: false,
    showSalientTooltip: false,
    showNoteTooltip: false,
  }


  componentDidMount = () => {
    this.addPointerUpEventListeners();
  }


  addPointerUpEventListeners = () => {
    if( typeof document === 'undefined' ){
      return;
    }


    const control = document.getElementById(HIGHLIGHT_MENU);

    document.onpointerup = ()=>{
      let control = document.getElementById(HIGHLIGHT_MENU);
      if (control !== null && !document.getSelection().toString()) {
        this.closeMenu();
      }
    }

    document.querySelector(`#${ TRANSCRIPT_GRID_ID }`).onpointerup = () => {
      const selection = document.getSelection();

      if ( selection.toString() ) {
        const rect = selection.getRangeAt(0).getBoundingClientRect();
        control.style.top = `calc(${rect.top}px - 48px)`;
        control.style.left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 40px)`;
        document.body.appendChild(control);
      } else {
        this.closeMenu();
      }
    }
  }


  closeMenu = () => {
    if( typeof document === 'undefined' ){
      return;
    }


    let control = document.getElementById(HIGHLIGHT_MENU);
    if (control !== null) {
      control.remove();
      document.getSelection().removeAllRanges();
    }

    this.setState({
      showCopyTooltip: false,
      showSalientTooltip: false,
      showNoteTooltip: false,
    })
  }


  copyToClipboard = () => navigator.clipboard.writeText(
    typeof document === 'undefined' ? '' : document.getSelection().toString()
  ).then(
    () => {
      this.props.showSnackbar(`Text successfully copied to your clipboard.`);
      this.closeMenu();
    }
  );


  getSelectionBeginningOffsetAndLength = () => {
    const selection = (
      typeof window !== 'undefined' ?
        window.getSelection
      :
        typeof document !== 'undefined' ?
          document.getSelection
        :
          ''
    );

    const text = selection().toString().replace(/\nSpeaker\s\d+/g, '');
    const anchorNode = selection().anchorNode;


    if( !( anchorNode || text) ){
      return;
    }


    const beginNodeDataset = anchorNode.parentNode.dataset;

    if( !beginNodeDataset ){
      return;
    }


    let beginOffset = +beginNodeDataset.beginOffset;
    if( !( +beginOffset >= 0 ) ){
      return this.props.showSnackbar('Please make a valid selection.');
    }


    const firstPhrase = text.split(' ')[ 0 ];
    const anchorNodeText = anchorNode.parentNode.innerText;


    const length = text.length;

    if( anchorNodeText.startsWith( firstPhrase ) ){
      return({
        beginOffset,
        length
      });
    }


    const wordStartIndex = anchorNodeText.indexOf( firstPhrase )


    return({
      'beginOffset': beginOffset+wordStartIndex,
      length
    });
  }



  onAddSalientPhrase = () => {
    const offsetLength = this.getSelectionBeginningOffsetAndLength();

    if( !offsetLength ){
      return;
    }

    const text = this.props.text.slice(offsetLength.beginOffset, offsetLength.beginOffset+offsetLength.length)
  }



  render(){
    const { classes } = this.props;


    // The default behavior of Tooltip does not work well with buttons
    return(
      <template>
        <Paper
          id={HIGHLIGHT_MENU}
          className={classes.paper}
        >
          <Tooltip
            title="Copy Text"
            placement='top'
            disableHoverListener
            open={this.state.showCopyTooltip}
            onMouseEnter={() => this.setState({ showCopyTooltip: true })}
            onMouseLeave={() => this.setState({ showCopyTooltip: false })}
          >
            <Button
              size='small'
              onClick={this.copyToClipboard}
            >
              <ControlPointDuplicate />
            </Button>
          </Tooltip>

          {/* <Tooltip
            title="Mark as Salient"
            placement='top'
            disableHoverListener
            open={this.state.showSalientTooltip}
            onMouseEnter={() => this.setState({ showSalientTooltip: true })}
            onMouseLeave={() => this.setState({ showSalientTooltip: false })}
          >
            <Button
              size='small'
              onClick={this.onAddSalientPhrase}
            >
              <Edit />
            </Button>
          </Tooltip>

          <Tooltip
            title="Insert Note"
            placement='top'
            disableHoverListener
            open={this.state.showNoteTooltip}
            onMouseEnter={() => this.setState({ showNoteTooltip: true })}
            onMouseLeave={() => this.setState({ showNoteTooltip: false })}
          >
            <Button
              size='small'
            >
              <InsertComment />
            </Button>
          </Tooltip> */}
        </Paper>
      </template>
    )
  }
}



const styles = theme => ({
  paper: {
    position: 'absolute',
    padding: 5
  }
});


HighlightMenu.defaultProps = {

}



export default withContext(
  SnackbarContext,
  withStyles(styles)(
    HighlightMenu
  )
);