import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

class PromiseButton extends React.Component {
  state = {
    loading: false,
  }



  componentDidMount = () => {
    if( this.props.shouldOnProcessOnEnter ){
      window.addEventListener('keypress', this.enterKeyPressShortcut);
    }

    if( this.props.shouldOnProcessOnSaveCommand ){
      window.addEventListener('keydown', this.saveCommandShortcut);
    }

    if( this.props.setOnProcessEventListener ){
      return this.props.setOnProcessEventListener(
        this.onProcess
      );
    }
  }



  componentWillUnmount = () => {
    this.unmounted = true;

    if( this.props.shouldOnProcessOnEnter ){
      window.removeEventListener('keypress', this.enterKeyPressShortcut);
    }

    if( this.props.shouldOnProcessOnSaveCommand ){
      window.removeEventListener('keydown', this.saveCommandShortcut);
    }

    if( this.props.removeOnProcessEventListener ){
      return this.props.removeOnProcessEventListener();
    }
  }



  enterKeyPressShortcut = e => {
    if ( e.key === 'Enter' ) {
      e.preventDefault();
      e.stopPropagation();

      return this.onProcess();
    }
  }



  saveCommandShortcut = e => {
    if ( e.keyCode == 'S'.charCodeAt(0) && ( e.ctrlKey || e.metaKey ) ) {
      e.preventDefault();
      e.stopPropagation();

      return this.onProcess();
    }
  }



  onProcess = e => {
    if( e ){
      e.preventDefault();
    }


    if( this.props.loading || this.state.loading ) {
      return;
    }


    this.setState({ loading: true });


    return this.props.onProcess()
      .finally(result => {
        if( !this.unmounted ){
          this.setState(
            { loading: false },
            () => {
              if( this.props.onProcessEnd ){
                return this.props.onProcessEnd();
              }
            }
          );
        }


        return result;
      });
  }



  render() {
    const { classes } = this.props;

    const loading = (
      this.props.loading ||
      this.state.loading
    );

    return (
      <span className={classes.wrapper}>
        <Button
          {...this.props.buttonProps}
          disabled={loading || this.props.buttonProps.disabled}
          onClick={this.props.onProcess ? this.onProcess : this.props.onClick}
          style={this.props.buttonStyles}
        >
          { this.props.children }
        </Button>

        {
          !loading ?
            null
          :
            <CircularProgress
              size={24}
              {...this.props.progressProps}
              className={classes.progress}
              style={this.props.progressStyles}
            />
        }
      </span>
    );
  }
}

PromiseButton.defaultProps = {
  buttonProps: {
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    type: 'submit',
  },
  progressProps: {
    color: 'primary',
  },
  buttonStyles: {},
  progressStyles: {},
  onClick: () => {},
  onProcess: Promise.resolve(),
  onProcessEnd: () => {},
  shouldOnProcessOnEnter: false,
  shouldOnProcessOnSaveCommand: false,
  setOnProcessEventListener: null,
  removeOnProcessEventListener: null,
  loading: false
};


const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});



export default withStyles(styles)(PromiseButton);