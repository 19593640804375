import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Videocam from '@material-ui/icons/Videocam';
import servicesColors from '../ServicesColors/servicesColors';

const virtualChip = props => (
  <Chip
    avatar={ <Avatar><Videocam className={props.classes.svgIcon}/></Avatar> }
    label="Virtual"
    classes={{ root: props.classes.root, avatar: props.classes.avatar}}
  />
)

const styles = theme => ({
  root: {
    color: servicesColors.virtual.dark,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${servicesColors.virtual.primary}`,
  },
  avatar: {
    backgroundColor: servicesColors.virtual.primary,
    color: servicesColors.virtual.contrastText,
  },
  svgIcon: {
    fontSize: 19,
  }
});

export default withStyles(styles)(virtualChip);