import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowSharp from '@material-ui/icons/PlayArrowSharp';
import PauseSharp from '@material-ui/icons/PauseSharp';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/lab/Slider';

import { log, format } from 'kn-react';

class CallPlayer extends React.Component {
	constructor(props) {
		super(props);
		this.audio = {};
		this.state = {
			playing: false,
			currentTime: 0,
			duration: 0
		};
	}

	onPlayPause = () => {
		if (!this.audio || !this.audio.pause || !this.audio.play) return;
		this.state.playing ? this.audio.pause() : this.audio.play();
		this.setState({ playing: !this.state.playing });
	}

	onTimeUpdate = e => {
		if (!this.audio) return;
		this.setState({ currentTime: this.audio.currentTime });
	}

	onChange = (event, value) => {
		if (!this.audio) return;
		this.updateTime(value);
	}

	onEnded = () => {
		this.setState({ playing: false });
	}

	updateTime = t => {
		this.audio.currentTime = t;
		this.setState({ currentTime: t });
	}

	render() {
		const { classes } = this.props;
		return (
			<Paper elevation={1} className={classes.paper}>
				<div style={{ display: 'flex' }}>
					<div className={classes.iconButtonWrapper} >
						<IconButton className={classes.iconButton} onClick={this.onPlayPause}>
							{this.state.playing ? <PauseSharp className={classes.iconStyle} /> : <PlayArrowSharp className={classes.iconStyle} />}
						</IconButton>
					</div>
					<span className={classes.currentTime}>
						{format.duration(this.state.currentTime)}
					</span>
					<span className={classes.slider}>
						<Slider
							min={0}
							max={this.audio.duration || 0}
							value={this.state.currentTime}
							onChange={this.onChange}
							step={1}
							style={{ width: '100%' }}
						/>
					</span>
					<span className={classes.duration}>
						{format.duration(this.state.duration)}
					</span>
				</div>
				{
					this.props.audioUrl ?
						<audio
							ref={ref => this.audio = ref}
							src={this.props.audioUrl}
							onTimeUpdate={this.onTimeUpdate}
							onEnded={this.onEnded}
							onCanPlay={e => this.setState({ duration: this.audio.duration })}
						/>
						:
						null
				}
			</Paper>
		);
	}
}

const styles = theme => ({
	iconButtonWrapper: {
		margin: 8
	},
	iconButton: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrast,
		'&:hover': {
			backgroundColor: theme.palette.primary.main
		}
	},
	durationStyle: {
		marginTop: 15
	},
	iconStyle: {
		color: theme.palette.primary.contrastText
	},
	paper: {
		position: 'relative',
		marginLeft: 10,
		marginRight: 10,
		padding: 5,
	},
	currentTime: {
		lineHeight: '60px',
		marginRight: 10,
		width: 42
	},
	duration: {
		lineHeight: '60px',
		right: 5,
		position: 'absolute',
		width: 42
	},
	slider: {
		paddingTop: 30,
		left: 112,
		right: 57,
		position: 'absolute'
	}
})

CallPlayer.defaultProps = {
	audioUrl: null,
	mainColor: null,
}

export default withStyles(styles, { withTheme: true })(CallPlayer);