import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import './ChatBox.css';
import goBoostHead from './go_boost_head.png';
import HelpOutline from '@material-ui/icons/HelpOutline'

class ChatBox extends React.PureComponent {
  arrowStyles = (width, height, color, orientation='left') => {
    const key = orientation == 'left' ? 'Right' : 'Left';
    return {
      width: 0,
      height: 0,
      borderTopWidth: height,
      borderTopColor: 'transparent',
      borderTopStyle: 'solid',
      borderBottomWidth: height,
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      [`border${ key }Width`]: width,
      [`border${ key }Color`]: color,
      [`border${ key }Style`]: 'solid',
    }
  }

  render() {
    const messageBoxStyles = {
      backgroundColor: 'whitesmoke',
      marginLeft: this.props.size + 10,
      padding: 10,
      borderRadius: 5,
    };


    const boxStyles = {
      ...messageBoxStyles,
      ...this.props.boxStyles,
    }

    const { classes } = this.props;
    return (

      <div className="ChatBox">

        <Zoom in style={{ transitionDelay: 250 }}>
          <div>
            <div style={{ position: 'absolute', left: this.props.size, top: 5 }}>
              <div className="arrow" style={this.arrowStyles(15, 10, boxStyles.backgroundColor)}></div>
            </div>
            <div className="messageBox" style={boxStyles}>
              {this.props.children}
            </div>
          </div>
        </Zoom>
        <Slide direction="right" in>
          <Avatar className={classes.avatarClass} style={{ height: this.props.size, width: this.props.size }}>
            <HelpOutline/>
          </Avatar>
        </Slide>
      </div>
    );
  }
}


ChatBox.defaultProps = {
  boxStyles: {},
  size: 40,
}

const styles = theme => ({
  avatarClass: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0
  }
})

export default withStyles(styles)(ChatBox);
