import React from 'react';
import { format } from 'kn-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RemoveIcon from '@material-ui/icons/Remove';
import VisibilityIcon from '@material-ui/icons/Visibility';

class PercentChangeMetric extends React.Component {

  render(){
    const {
      icon,
      iconColor,
      iconBackgroundColor,
      title,
      metric,
      percentChange,
      percentChangeColor
    } = this.props;

    const Icon = this.props.icon;
    let ChangeIcon;
    if(percentChange === 0){
      ChangeIcon = RemoveIcon;
    } else {
      ChangeIcon = percentChange > 0 ? ArrowUpwardIcon : ArrowDownwardIcon;
    }


    return (
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar style={{ width: 70, height: 70, backgroundColor: iconBackgroundColor, color: iconColor }}>
              <Icon style={{ fontSize: 32 }}/>
            </Avatar>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="caption">{ title }</Typography>
            <Typography variant="h6">{metric}</Typography>
            {
              (!percentChange) ? null :
              <div style={{ position: 'relative' }}>
                <Typography variant="caption" style={{ color: percentChangeColor }}>
                  <ChangeIcon style={{ fontSize: 18 }}/>
                  <span style={{ position: 'absolute', left: 22, lineHeight: '20px' }}>
                    { format.percent(percentChange) }
                  </span>
                </Typography>
              </div>
            }
          </ListItemText>
        </ListItem>
      </List>
    );
  }

}

PercentChangeMetric.defaultProps = {
  icon: VisibilityIcon,
  iconColor: '#37caad',
  iconBackgroundColor: 'rgba(55, 202, 173, 0.2)',
  iconSize: 70,
  title: 'Metric',
  metric: 100,
  percentChange: 0,
  percentChangeColor: '#4fae21',
}

export default PercentChangeMetric;
