// Pieces taken from Recharts example
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import NoDataWrapper from '../NoDataWrapper/NoDataWrapper';

const colors = [
  '#BBEDE2', //call
  '#C6D2FB' //form
]

class KnPieChart extends React.Component {

  dataCells = () => {
    return this.props.data.map((entry, index) => {
      let fill = colors[index % colors.length];
      if (entry.name.toLowerCase() === 'call') {
        fill = colors[0];
      } else if (entry.name.toLowerCase() === 'form') {
        fill= colors[1];
      }
      return (
        <Cell key={`cell-${index}`} fill={fill} />
      )
    })
  }

  render() {
    const { innerRadius } = this.props;

		const RADIAN = Math.PI / 180;
		const renderCustomizedLabel = ({
			cx, cy, midAngle, innerRadius, outerRadius, percent, index,
		}) => {
			const radius = 0.5 * (outerRadius - innerRadius) + innerRadius;
			const x = cx + radius * Math.cos(-midAngle * RADIAN);
			const y = cy + radius * Math.sin(-midAngle * RADIAN);

			return (
        <g>
          <text fontSize='2em' x={this.props.width/2} y={this.props.height/2} dy={-2} textAnchor="middle" fill='#000'>{this.props.total}</text>
          <text fontSize='1em' x={this.props.width/2} y={this.props.height/2} dy={14} textAnchor="middle" fill='#000'>{this.props.centerLabel}</text>
          <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </g>
			);
    };

    return (
      <NoDataWrapper data={this.props.data}>
        <PieChart width={this.props.width} height={this.props.height}>
          <Legend/>
          <Pie
            isAnimationActive={false}
            data={this.props.data}
            labelLine={this.props.labelLine}
            label={renderCustomizedLabel}
            dataKey="value"
            innerRadius={innerRadius}
          >

            {
              (!this.props.data) ? null : this.dataCells()
            }
          </Pie>
          <Tooltip/>
        </PieChart>
      </NoDataWrapper>
    );
  }
}

KnPieChart.defaultProps = {
  data: null,
  colors: [null],
  width: 400,
  height: 400,
  labelLine: false,
  innerRadius: 100,
  total: 0,
  centerLabel: null,
}

export default KnPieChart;