import React from 'react';

import { SvgIcon } from '@material-ui/core';


// https://icon-sets.iconify.design/tabler/page-44.html
const homeEmergencyIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="m21 12l-9-9l-9 9h2v7a2 2 0 0 0 2 2h8" stroke={props.fill}/>
      <path d="M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 1.857 1.257M19 16v3m0 3v.01" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


homeEmergencyIcon.defaultProps = {
  fill: '#5E5F64'
}


export default homeEmergencyIcon;