import React from 'react';

import { withContext } from 'kn-react';
import { ThemeContext } from '../Theme/ThemeProvider';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import HelpOutline from '@material-ui/icons/HelpOutline';


const knowledgeBaseButton = props => {
  let url = `${ props.theme.knowledgeBaseUrl || 'https://support.goboost.com/goboost' }${ props.path ? props.path : '' }`;

  let button = (
    <IconButton
      component={'a'}
      href={url}
      target='_blank'
      size='small'
    >
      <HelpOutline />
    </IconButton>
  );


  if( props.tooltipTitle ){
    button = (
      <Tooltip title={props.tooltipTitle}>
        { button }
      </Tooltip>
    );
  }


  return button;
}




const styles = theme => ({

});



knowledgeBaseButton.defaultProps = {
  path: '',
  tooltipTitle: ''
}



export default withContext(
  ThemeContext,
  withStyles(styles, { withTheme: true })(knowledgeBaseButton)
);