// import './install'; // <-- THIS LINE IS CURRENTLY CAUSING THEME ISSUES

import React from "react";
import Frame from 'react-frame-component'
import { create } from "jss";
import JssProvider from 'react-jss/lib/JssProvider'

import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName';
import StylesProvider from '@material-ui/styles/StylesProvider'
import withStyles from "@material-ui/core/styles/withStyles";
import jssPreset from "@material-ui/core/styles/jssPreset";
import NoSsr from "@material-ui/core/NoSsr";

// import { DEFAULT_CREATE_GENERATE_CLASS_NAME } from '../Theme/ThemeProvider';


const IFRAME_STYLES_DIV = 'go-boost-iframe-styles';

class StyledIFrame extends React.Component {
  state = {
    loaded: false
  };



  handleRef = ref => {
    this.contentDocument = ref ? ref.node.contentDocument : null;
    this.contentWindow = ref ? ref.node.contentWindow : null;
  };



  onContentDidMount = () => {
    this.setState({
      loaded: true,
      jss: create({
        plugins: [...jssPreset().plugins],
        insertionPoint: this.contentWindow[ IFRAME_STYLES_DIV ]
      }),
      sheetsManager: new Map(),
      container: this.contentDocument.body
    });
  };



  render() {
    const { children, classes } = this.props;

    // const generateClassName = this.props.generateClassName || DEFAULT_CREATE_GENERATE_CLASS_NAME;
    const generateClassName = this.props.generateClassName;

    // NoSsr fixes a strange concurrency issue with iframe and quick React mount/unmount
    return (
      <NoSsr>
        <Frame
          ref={this.handleRef}
          className={classes.root}
          contentDidMount={this.onContentDidMount}
        >
          <div id={IFRAME_STYLES_DIV} />

          {
            !this.state.loaded ?
              null
            :
              <JssProvider jss={this.state.jss} generateClassName={generateClassName}>
                <StylesProvider jss={this.state.jss}>
                  { children }
                </StylesProvider>
              </JssProvider>
          }
        </Frame>
      </NoSsr>
    );
  }
}



const styles = theme => ({
  root: {
    border: "none",
    height: '100%',
    width: '100%'
  }
});



StyledIFrame.defaultProps = {
  generateClassName: null,
}



export default withStyles(styles)(StyledIFrame);
