import React from 'react';
import { Link } from 'react-router-dom';


import { SliderPicker, MaterialPicker } from 'react-color';
import useImageColor from 'use-image-color'

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';



// const IMAGE = 'https://storage.googleapis.com/go-boost-partners-public/media_items/647-DEAN_FITE_LOGO_.jpg';
// const IMAGE = 'https://storage.googleapis.com/go-boost-partners-staging-public/media_items/17-steelers_logo.png';

// Make whitesmoke default color
const DEFAULT_HEX_COLOR = '#f5f5f5';

class PrimaryAndSecondaryQuestions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      color: '',
      colorsFromLogo: [],
      showPrimaryAdvanced: false,
      showSecondaryAdvanced: false,
      showPrimaryAdvanced: false,
      showSecondaryAdvanced: false
    }
  }


  onChange = color => {
    this.setState({ color: color.hex });
    this.props.onChange( color.hex );
  }


  getColors = colorsFromLogo => {
    if( colorsFromLogo && colorsFromLogo.length ){
      colorsFromLogo = [ ...new Set( colorsFromLogo ) ];

      const existingColorsSet = new Set( this.state.colorsFromLogo );
      const newColorsSet = new Set( colorsFromLogo );

      const hasSameColors = (
        existingColorsSet.size === newColorsSet.size &&
        [ ...newColorsSet ].every(c => existingColorsSet.has( c ))
      );

      if( !hasSameColors ){
        this.setState({ colorsFromLogo });
      }
    }
  }


  render() {
    const { classes } = this.props;

    const previewSearchParams = [];
    if( this.props.primaryColorSelectedColor ){
      previewSearchParams.push(
        `primary_color=${ encodeURIComponent( this.props.primaryColorSelectedColor ) }`
      );
    }
    if( this.props.secondaryColorSelectedColor ){
      previewSearchParams.push(
        `secondary_color=${ encodeURIComponent( this.props.secondaryColorSelectedColor ) }`
      );
    }

    let previewUrl = this.props.previewUrl;
    if( previewUrl && previewSearchParams.length ){
      previewUrl += '/?';
      previewUrl += previewSearchParams.join('&');
    }


    const renderQuestion = ({ label, selectedColor, onChange }) => (
      <React.Fragment>
        <h4
          className={classes.label}
          // variant={'h6'}
        >
          { label }
        </h4>

        <div className={classes.colorPickerWrapper}>
          <div className={classes.selectedColor} style={{ backgroundColor: selectedColor }}/>

          <div className={classes.colorPickerWrapper}>
            <div className={classes.colorPaletteColorsWrapper}>
              <div className={classes.logoColorsLabel}>
                <Typography>
                  Logo Colors
                </Typography>
              </div>

              {
                !this.state.colorsFromLogo.length ?
                  <div style={{ textAlign: 'left', width: '100%' }}>
                    <Link
                      to={this.props.uploadLogoUrl}
                      style={{ fontSize: 12 }}
                    >
                      Upload your logo to pick from your colors.
                    </Link>
                  </div>
                :
                  <div className={classes.logoColorsPalette}>
                    {
                      this.state.colorsFromLogo.map(
                        (c, i) => (
                          <button
                            className={classes.colorPaletteColor}
                            style={{
                              backgroundColor: c
                            }}
                            onClick={() => onChange(c)}
                            key={i}
                          />
                        )
                      )
                    }
                  </div>
              }

              <div className={classes.customColorsLabel}>
                <Typography>
                  Custom Colors
                </Typography>
              </div>

              <div className={classes.customColorsPalette}>
                <div className={classes.customColors}>
                  <div className={classes.sliderPickerWrapper}>
                    <div className={classes.customColorsAdvancedLabel}>
                      <Typography className={classes.hueAndSaturation}>
                        Hue &amp; Saturation
                      </Typography>
                    </div>

                    <SliderPicker
                      color={selectedColor || '#fafafa'}
                      onChange={color => onChange(color.hex)}
                    />
                  </div>

                  <div className={classes.materialPickerWrapper}>
                    <MaterialPicker
                      color={selectedColor || '#fafafa'}
                      onChange={color => onChange(color.hex)}
                      disableAlpha={true}
                      styles={{
                        'zDepth-1': {
                          bg: {
                            boxShadow: 'none !important',
                          },
                        },
                        bg: {
                          boxShadow: 'none !important',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );


    const title = (
      this.props.title ?
        this.props.title
      :
        `Company Color${this.props.shouldRenderSecondaryColor ? 's' : ''}`
    );

    const caption = (
      this.props.caption ?
        this.props.caption
      :
        `Pick your Primary${this.props.shouldRenderSecondaryColor ? ' & Secondary' : ''} Color${this.props.shouldRenderSecondaryColor ? 's' : ''}`
    );


    return (
      <FormControl component="fieldset" style={{ display: 'block' }}>
        <Typography variant="subtitle1">
          { title }
        </Typography>

        <Typography variant="caption">
          { caption }
        </Typography>

        <div className={classes.logoPreviewDiv}>
          <ColorPalette
            getColors={this.getColors}
            image={this.props.logoUrl}
          />
        </div>

        {
          previewUrl ?
            <React.Fragment>
              <iframe
                key={previewUrl}
                src={previewUrl}
                className={classes.iframe}
              ></iframe>

              <Typography
                variant="caption"
                className={classes.iframeHelper}
              >
                See how your site color{this.props.shouldRenderSecondaryColor ? 's' : null} look on your website.
              </Typography>
            </React.Fragment>
          :
            this.props.renderColorPreview ?
              this.props.renderColorPreview()
            :
              null
        }

        {
          renderQuestion({
            label: this.props.primaryColorLabel ? this.props.primaryColorLabel : 'Primary Color',
            selectedColor: this.props.primaryColorSelectedColor,
            onChange: this.props.onChangePrimaryColor
          })
        }

        {
          this.props.shouldRenderSecondaryColor ?
            renderQuestion({
              label: this.props.secondaryColorLabel ? this.props.secondaryColorLabel : 'Secondary Color',
              selectedColor: this.props.secondaryColorSelectedColor,
              onChange: this.props.onChangeSecondaryColor
            })
          :
            null
        }

        { this.props.children }
      </FormControl>
    )
  }
}

PrimaryAndSecondaryQuestions.defaultProps = {
  uploadLogoUrl: '',
  primaryColorSelectedColor: '',
  secondaryColorSelectedColor: '',
  previewUrl: '',
  shouldRenderSecondaryColor: true,
  logoUrl: '',
  renderColorPreview: null, //function(){}
  onChangePrimaryColor: () => {},
  onChangeSecondaryColor: () => {},
  title: null,
  caption: null,
  primaryColorLabel: null,
  secondaryColorLabel: null,
}


const styles = theme => ({
  logoPreviewDiv: {
    textAlign: 'center'
  },
  logoPreview: {
    maxWidth: 125,
    maxHeight: 125,
  },
  colorPickerWrapper: {
    width: '100%',
    display: 'flex'
  },
  selectedColor: {
    minWidth: 175,
    maxWidth: 175,
    minHeight: 175,
    maxHeight: 175,
    marginRight: 15,
    borderRadius: '50%',
    border: '1px solid lightgrey'
  },
  colorPaletteColorsWrapper: {
    textAlign: 'center',
    display: 'flex',
    width: '100%',
    // paddingLeft: '35%',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  colorPaletteColor: {
    minWidth: 25,
    maxWidth: 25,
    minHeight: 25,
    maxHeight: 25,
    marginBottom: 2,
    marginRight: 2,
    borderRadius: 2,
    border: '1px solid lightgrey'
  },
  iframe: {
    width: 'calc(100% + 30px)',
    height: 500,
    marginLeft: -15,
    border: 'none'
    // [theme.breakpoints.down('sm')]: {
    //   width: 'calc(100% + 30px)',
    //   marginLeft: -15
    // },
  },
  iframeHelper: {
    marginBottom: 15
  },
  colorPickerWrapper: {
    width: '100%',
    // display: 'flex',
    minHeight: 175,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
      marginTop: 15
    },
  },
  colorPickerAdvancedButtonDiv: {
    textAlign: 'right',
    width: '100%',
    position: 'relative'
  },
  sliderPickerWrapper: {
    width: '75%',
    [theme.breakpoints.up('md')]: {
      paddingTop: 14,
    },
  },
  materialPickerWrapper: {
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15
    },
  },
  colorPickerAdvancedButton: {
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  customColors: {
    display: 'flex'
  },
  logoColorsLabel: {
    width: '100%',
    textAlign: 'left',
    marginBottom: 5
  },
  logoColorsPalette: {
    width: '100%',
    marginBottom: 10,
    textAlign: 'left'
  },
  customColorsLabel: {
    width: '100%',
    textAlign: 'left',
    marginBottom: 5,
    marginTop: 10,
  },
  customColorsPalette: {
    width: '100%',
    marginBottom: 10
  },
  customColorsAdvancedLabel: {
    width: '100%',
    textAlign: 'left',
    marginBottom: 5
  },
  hueAndSaturation: {
    fontSize: 11,
    color: '#999999',
    fontFamily: 'Roboto'
  },
  label: {
    marginBottom: 10
  },
})



export default withStyles(styles)(
  PrimaryAndSecondaryQuestions
);



const ColorPalette = props => {
  if( !props.image ){
    return null;
  }

  const { colors } = useImageColor(
    props.image,
    {
      cors: true,
      colors: 17
    }
  );

  if( props.getColors && colors ){
    props.getColors( colors );
  }

  return null
}