import React from 'react';

import { SvgIcon } from '@material-ui/core';


const yesIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <path fill="none" stroke={props.fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 12l5 5L20 7"/>
  </SvgIcon>
);


yesIcon.defaultProps = {
  fill: '#5E5F64'
}


export default yesIcon;