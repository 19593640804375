import React from 'react';

import { SvgIcon } from '@material-ui/core';


// https://icon-sets.iconify.design/tabler/page-44.html
const buildingHomeIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2" stroke={props.fill}/>
      <path d="M19 13.488V12h2l-9-9l-9 9h2v7a2 2 0 0 0 2 2h4.525M15 19l2 2l4-4" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


buildingHomeIcon.defaultProps = {
  fill: '#5E5F64'
}


export default buildingHomeIcon;