import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import OverTimeChart from '../OverTimeChart/OverTimeChart';

class CostChart extends React.Component {
	render() {
		const { classes } = this.props;

		const lineStyles = (this.props.lineStyles) ? this.props.lineStyles : {
			Cost: {
				type:"monotone",
				dataKey:"Cost",
				stroke:this.props.mainColor,
				activeDot: { r: 8 }
			}
		};

		return (
			<div className={ classes.root }>
				<Grid container item xs={12} spacing={8}>
					<Grid item xs={6} style={{ textAlign: 'right' }}>
						<Typography variant="h6">Cost</Typography>
					</Grid>
					<Grid item xs={6}>
						<ListItemText
							classes={{ primary: classes.colorPrimary }}
							primary={this.props.displayAmount}
							secondary={'Amount Spent'}
							style={{ float:'right' }}
						>
						</ListItemText>
					</Grid>
				</Grid>
				<OverTimeChart
					width={this.props.chartWidth}
					height={this.props.height}
					data={this.props.data}
					lineStyles={lineStyles}
					yAxis={this.props.yAxis}
					tooltipProps={this.props.tooltipProps}
					chartMargin={this.props.chartMargin}
				/>
			</div>
		);
	}
}

CostChart.defaultProps = {
	chartWidth: '100%',
	height: 350,
	data: null,
	lineStyles: null,
	yAxis: [{
		name: '$',
		type:'number',
		tickFormatter: function(moneyString) {
			return (`$${moneyString}`)
		}
	}],
	tooltipProps: {
		formatter: function(moneyString) {
			return (`$${moneyString}`)
		}
	},
	chartMargin: {
		top: 25,
		right: 25,
		left: 0,
		bottom: 25
	},
	mainColor: null
}

const styles = theme => ({
	root: { padding: 8 },
	colorPrimary: {
		color: theme.palette.primary.main,
		textAlign: 'right',
	}
})


export default withStyles(styles)(CostChart);