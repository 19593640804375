import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Slider from '@material-ui/lab/Slider';


class SliderWithLabel extends React.PureComponent {

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.slider}>
        <div className={classes.sliderWrapper}>
            <Slider
              min={this.props.min}
              max={this.props.max}
              value={this.props.value}
              step={this.props.step}
              onChange={this.props.onChange}
            />
        </div>
        <div className={classes.sliderLabelWrapper}>
          { this.props.displayValue }
          <div className={ classes.sliderLabel }>
            {this.props.label}
          </div>
        </div>
      </div>
    );
  }
}


const styles = {
  slider: {
    position: 'relative',
    marginTop: 20,
    height: 65,
  },
  sliderWrapper: {
    position: 'absolute',
    right: 120,
    top: 0,
    left: 0,
    bottom: 0,
  },
  sliderLabelWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 100,
    fontWeight: 'bold'
  },
  sliderLabel: {
    color: '#777',
    fontSize: 13,
  },
};


export default withStyles(styles)(SliderWithLabel);