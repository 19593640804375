import React from 'react';
import classNames from 'classnames';

import { format } from 'kn-react';
import { withStyles, Paper, List, ListItem, ListItemText } from '@material-ui/core';





class MeetingStats extends React.Component {
  enabledDisabled = isEnabled => (
    <span
      style={{
        color: isEnabled ? 'green' : 'darkorange'
      }}
    >
      { isEnabled ? 'Enabled' : 'Disabled' }
    </span>
  )


  render(){
    const { classes } = this.props;


    const durationSeconds = (
      this.props.meeting ?
        (
          this.props.meeting.endedAt - this.props.meeting.createdAt
        ) / 1000
      :
        0
    );


    const meetingDuration = (
      !this.props.meeting ?
        'Loading...'
      :
        this.props.meeting.isActive ?
          'In progress'
        :
          durationSeconds ?
            ( durationSeconds / 60 ).toFixed(1)
          :
            0
    );

    return (
      <Paper className={classes.listPaper}>
        <List className={classes.list}>
          <ListItem disableGutters className={classNames(classes.listItem, classes.primaryListItem, classes.rightBorder)}>
            <ListItemText
              primary={this.props.meetingRoom ? this.props.meetingRoom.url : 'Loading...'}
              secondary={'Meeting URL'}
            />
          </ListItem>
          <ListItem disableGutters className={classNames(classes.listItem, classes.primaryListItem, classes.rightBorder)}>
            <ListItemText
              primary={this.props.meeting ? format.dateTime( this.props.meeting.createdAt ) : 'Loading...'}
              secondary={'Meeting Start Date & Time'}
            />
          </ListItem>
          <ListItem disableGutters className={classNames(classes.listItem, classes.primaryListItem, classes.rightBorder)}>
            <ListItemText
              primary={meetingDuration}
              secondary={'Meeting Duration (minutes)'}
            />
          </ListItem>
          <ListItem disableGutters className={classNames(classes.listItem, classes.primaryListItem)}>
            <ListItemText
              primary={this.props.meeting ? this.props.meeting.totalSocketConnections : 'Loading...'}
              secondary={'Meeting Participants'}
            />
          </ListItem>
        </List>

        <List className={classes.subList} dense>
          <ListItem disableGutters className={classNames(classes.listItem, classes.secondaryListItem, classes.rightBorder)}>
            <ListItemText
              primary={this.props.meeting ? this.enabledDisabled(this.props.meeting.shouldRecord) : 'Loading...'}
              secondary={'Video Recording Status'}
            />
          </ListItem>
          <ListItem disableGutters className={classNames(classes.listItem, classes.secondaryListItem, classes.rightBorder)}>
            <ListItemText
              primary={this.props.meeting ? this.enabledDisabled(this.props.meeting.isVirtualTranscriptIncluded) : 'Loading...'}
              secondary={'Transcript Status'}
            />
          </ListItem>
          <ListItem disableGutters className={classNames(classes.listItem, classes.secondaryListItem, classes.rightBorder)}>
            <ListItemText
              primary={this.props.meeting ? this.enabledDisabled(this.props.meeting.isVirtualTextAnalysisIncluded) : 'Loading...'}
              secondary={'Text Analysis Status'}
            />
          </ListItem>
          <ListItem disableGutters className={classNames(classes.listItem, classes.secondaryListItem)}>
            <ListItemText
              primary={this.props.meeting ? this.enabledDisabled(this.props.meeting.isVirtualVideoAnalysisIncluded) : 'Loading...'}
              secondary={'Video Analysis Status'}
            />
          </ListItem>
        </List>
      </Paper>
    )
  }
}


const styles = theme => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid whitesmoke',
    borderRadius: 15,
    backgroundColor: 'white',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexWrap: 'wrap',
    margin: '0 !important'
  },
  subList: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid whitesmoke',
    borderRadius: 15,
    backgroundColor: 'white',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    flexWrap: 'wrap',
    margin: '0 !important'
  },
  listItem: {
    textAlign: 'center'
  },
  listPaper: {
    borderRadius: 15,
    padding: 5,
    marginTop: 15,
    marginBottom: 25,
    backgroundColor: 'whitesmoke',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  rightBorder: {
    borderRight: '1px solid #e7e7e7'
  },
  primaryListItem: {
    maxWidth: '25%',
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      border: 'none'
    },
  },
  secondaryListItem: {
    maxWidth: '25%',
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      border: 'none'
    },
  }
})


MeetingStats.defaultProps = {
  meetingRoom: null,
  meeting: null
}


export default withStyles(styles)(
  MeetingStats
)