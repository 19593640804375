import React from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import ClippedDrawer from './ClippedDrawer/ClippedDrawer';
import AppDrawerLink from '../../AppDrawer/AppDrawerLink/AppDrawerLink';
import withStyles from '@material-ui/core/styles/withStyles';



const desktopSubNavigation = props => (
  <ClippedDrawer
    classes={{ drawerPaper: props.classes.drawerPaper }}
    drawerContent={
      <List>
        {
          (props.links || []).map((l,i) => {
            if(l.divider) {
              return <Divider key={i}/>;
            } else {
              return <AppDrawerLink key={i} {...{...l, secondaryAction: undefined}} />;
            }
          })
        }
      </List>
    }
  >
    {props.children}
  </ClippedDrawer>
)


const styles = theme =>  ({
  drawerPaper: {
    backgroundColor: '#FAFAFA',
    borderRightColor: '#FAFAFA',
  }
});

export default withStyles(styles)(desktopSubNavigation);