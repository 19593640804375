import React from 'react';
import FacebookAuthProvider from './FacebookAuthProvider';
import FacebookAuthPage from './FacebookAuthPage';


const facebookPageOauth = props => (
  <FacebookAuthProvider
    onCode={props.onCode}
    clientId={props.clientId}
    canSignOut={props.canSignOut}
    canSignIn={props.canSignIn}
    onAuthPageComplete={props.onAuthPageComplete}
    signOut={props.signOut}
    facebookRedirectUrl={props.facebookRedirectUrl}
  >
    <FacebookAuthPage
      facebookAccount={props.facebookAccount}
      facebookAccountPage={props.facebookAccountPage}
      getFacebookPagesCallback={props.getFacebookPagesCallback}
      facebookAccountPageLoading={props.facebookAccountPageLoading}
    />
  </FacebookAuthProvider>
);

facebookPageOauth.defaultProps = {
  onCode: function(){},
  clientId: '',
  facebookAccount: {},
  facebookAccountPage: {},
  signOut: function () {},
  canSignOut: true,
  canSignIn: true,
  authHeaders: {},
  getFacebookPages: function(){},
}

export default facebookPageOauth;