import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 40 44">
    <g fillRule="evenodd">
			<path fillRule="nonzero" d="M20.4 23.222c.133-.122.2-.244.2-.305 1.6-2.811 6.533-11.184 6.533-11.184.467-.733.134-1.65-.666-2.077L20.4 6.66c-.4-.183-.867-.244-1.267-.122-.4.122-.8.367-1 .733L11.6 18.456c-.133.183-.2.427-.2.672l-.2 7.089c0 .55.333 1.1.867 1.405.266.122.533.184.8.184.333 0 .666-.123.933-.306 0 0 4-2.628 6.333-4.094.134-.062.2-.123.267-.184zm-.133-13.139l3.133 1.528-4.933 8.495-3-1.467-.134-.061 4.934-8.495z"/>
			<path d="M39.8 1.894c0-.855-.733-1.527-1.667-1.527H1.867C.933.367.2 1.039.2 1.894v40.212c0 .61.4 1.16 1 1.405.2.061.4.122.667.122.466 0 .866-.183 1.2-.427l9.2-8.434h25.866c.934 0 1.667-.672 1.667-1.528 0-.855-.733-1.527-1.667-1.527h-26.6c-.466 0-.866.183-1.2.427l-6.8 6.295V3.422H36.4v21.94H21.867c-.934 0-1.667.671-1.667 1.527 0 .855.733 1.528 1.667 1.528h16.266c.934 0 1.667-.673 1.667-1.528v-.061V1.894z"/>
    </g>
  </SvgIcon>

);