import React from 'react';
import withStyle from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import GoogleTileIcon from '../Icons/GoogleTileIcon';

class GoogleSignInButton extends React.Component {
	
	render() {
		const { classes } = this.props;
		
		return (
			<Button className={classes.button}>
				<GoogleTileIcon width={'18dp'} height={'18dp'}/>
				{this.props.children || 'Sign In With Google'}
			</Button>
		)
	}
}

const styles = theme => ({
	button: {
		backgroundColor: '#4285F4',
		height: '40dp',
		paddingLeft: '8dp',
		paddingRight: '8dp'
	}
})


export default withStyle(styles)(GoogleSignInButton) 