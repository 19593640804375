import React from 'react';
import Redirect from 'react-router-dom/Redirect';
import { withContext } from 'kn-react';

import { AuthContext } from './AuthProvider';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';

class AuthForbiddenGuard extends React.Component {
  render(){
    if(!this.props.hasToken()){
      return this.props.children;
    } else {
      this.props.showSnackbar('You are already signed in.');
      return <Redirect to={this.props.redirectTo}/>;
    }
  }
}


AuthForbiddenGuard.defaultProps = {
  redirectTo: '/',
  hasToken: () => {}
};


export default withContext(AuthContext, SnackbarContext, AuthForbiddenGuard);
