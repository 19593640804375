/*global window*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';



class SiteDesignPicker extends React.Component {


  onClickDesign = t => {
    const { selectedSiteDesignId } = this.props;
    if (selectedSiteDesignId === t.id) {
      this.props.onChange('')
    } else {
      this.props.onChange(t.id)
    }
  }


  onClickPreview = (e, t) => {
    e.preventDefault();

    if( typeof window === 'undefined' ){
      return;
    }

    window.open(t.previewUrl, '_blank');
  }


  render() {
    const { classes, gridWidth, cellHeight, spacing, width } = this.props;

    const { colsXs, colsSm, colsMd, colsLg, colsXl } = this.props;
    const cols = {
      xs: colsXs,
      sm: colsSm,
      md: colsMd,
      lg: colsLg,
      xl: colsXl,
    }

    const { selectedSiteDesignId, siteDesigns } = this.props;

    return (
      <div className={classes.root}>
        <GridList style={{ width: gridWidth }} cellHeight={cellHeight} cols={cols[width]} spacing={spacing} className={classes.gridList}>
          {
            siteDesigns.map(t => {

              let className = [classes.gridListTile];
              if (selectedSiteDesignId === t.id) {
                className.push(classes.gridListTileActive);
              } else if (selectedSiteDesignId) {
                className.push(classes.gridListTileInactive);
              }
              className = className.join(' ');

              return (
                <GridListTile
                  key={t.thumbnailUrl}
                  onClick={() => this.onClickDesign(t)}
                  classes={{ root: className, tile: classes.gridListImageBorder }}
                  className={className}
                >
                  <img className={classes.gridListImage} src={t.thumbnailUrl} alt={t.name} />
                  <GridListTileBar
                    title={t.name}
                    actionIcon={
                      <IconButton className={classes.icon} onClick={e => this.onClickPreview(e, t)}>
                        <VisibilityIcon />
                      </IconButton>
                    }
                  />
                </GridListTile>
              )
            })
          }
        </GridList>
      </div>
    );
  }

}


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    padding: 8,
  },
  gridList: {
    height: 'auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  gridListTile: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 4,
    transition: 'opacity 0.25s ease-in-out'
  },
  gridListTileActive: {
    borderColor: theme.palette.primary.main,
  },
  gridListTileInactive: {
    opacity: 0.6,
  },
  gridListImageBorder: {
    border: '1px solid #efefef',
    borderRadius: 4
  },
});


SiteDesignPicker.defaultProps = {
  gridWidth: 650,
  colsXs: 1,
  colsSm: 2,
  colsMd: 3,
  colsLg: 3,
  colsXl: 4,
  cellHeight: 180,
  spacing: 8,
};


export default withStyles(styles)(
  withWidth()(SiteDesignPicker)
);


