import React from 'react';

import { BarChart as ReBarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import NoDataWrapper from '../NoDataWrapper/NoDataWrapper';

class BarChart extends React.PureComponent {
	render() {
		return (
			<NoDataWrapper data={this.props.data}>
				<ResponsiveContainer width={this.props.width} height={this.props.height}>
					<ReBarChart
						data={this.props.data}
						margin={{ ...this.props.chartMargin }}
						style={{ ...this.props.chartStyle }}
					>
						{
							(!this.props.gridProps) ? null : <CartesianGrid {...this.props.gridProps} />
						}

						{
							(!this.props.xAxisProps) ? null : <XAxis {...this.props.xAxisProps} />
						}

						{
							(!this.props.yAxis) ?
								null :
								<YAxis {...this.props.yAxis} />
							})
						}

						<Tooltip {...this.props.tooltipProps} />
						{
							(!this.props.barStyles) ? null : Object.keys(this.props.barStyles).map(style => {
								return (
									<Bar key={style} {...this.props.barStyles[style]} />
								)
							})
						}
						{
							(this.props.hideLegend) ? null : <Legend {...this.props.legendProps} />
						}
					</ReBarChart>
				</ResponsiveContainer>
			</NoDataWrapper>
		);
	}
}

BarChart.defaultProps = {
	width: '99%',
	height: 500,
	xAxisProps: {
		dataKey: 'name',
		interval: 'preserveStartEnd',
	},
	chartStyle: null,
	gridProps: {
		strokeDasharray: "0 0",
		stroke: 'whitesmoke',
		vertical: false
	},
	chartMargin: {
		top: 25,
		bottom: 25,
		left: 5,
		right: 25
	},
	yAxis: null,
	hideLegend: false,
	legendProps: null,
	tooltipProps: null,
	barStyles: null
}


export default BarChart;