import React from 'react';
import { withStyles } from '@material-ui/core';

const noDataWrapper = props => {
  const { classes } = props;

  const noData = !( props.data && props.data.length );

  return (
    <div className={classes.chartDiv} style={{ opacity: noData ? 0.5 : 1 }}>
      { props.children }

      {
        noData ?
          <p className={classes.noData}>No chart data available</p>
        :
          null
      }
    </div>
  )
}

noDataWrapper.defaultProps = {
  data: []
}


const styles = theme => ({
  chartDiv: {
    position: 'relative',
    textAlign: 'center'
  },
  noData: {
    position: 'absolute',
    top: '40%',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  }
})


export default withStyles(styles)(noDataWrapper)