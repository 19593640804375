import { JsonModel } from 'kn-react';

import ToggleMapModel from '../ToggleMap/ToggleMapModel';

export default class CompanyModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    let model = new CompanyModel();
    model = JsonModel.modelFromJSON(model, json);
    model.serviceArea = model.serviceArea ? ToggleMapModel.fromJSON(model.serviceArea) : new ToggleMapModel();
    return model;
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

}
