/*global document*/
import React from 'react';

// Put an empty div on the page.
// If the div is placed, it can be
// inferred that an ad blocker is not
// being used. Most ad blockers contain
// the class "adsbox" and "ad-1
// their blacklist.
const createAdsDiv = divClassName => {
  if( typeof document === 'undefined' ){
    return;
  }


  var adsDiv = document.createElement('div');
  adsDiv.innerHTML = '&nbsp;';
  adsDiv.className = divClassName || 'adsbox';
  document.body.appendChild(adsDiv);
}

const divNotPresent = divClassName => {
  if( typeof document === 'undefined' ){
    return;
  }


  const adsDiv = document.getElementsByClassName(divClassName);
  return adsDiv.length && adsDiv[0].offsetHeight === 0
}

export default props => {
  createAdsDiv('adsbox');
  createAdsDiv('ad-1');

  return divNotPresent('adsbox') || divNotPresent('ad-1')
}