import React from 'react';
import { format } from 'kn-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

class ChangeMetric extends React.Component {

  render(){
    const {
      icon,
      iconColor,
      iconBackgroundColor,
      title,
      metric,
      change,

    } = this.props;

    const Icon = this.props.icon;
    return (
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar style={{ width: 70, height: 70, backgroundColor: iconBackgroundColor, color: iconColor }}>
              <Icon style={{ fontSize: 32 }}/>
            </Avatar>
          </ListItemIcon>
          <ListItemText>
            <Typography variant="caption">{ title }</Typography>
            <Typography variant="h6">{metric}</Typography>
            {
              change ?
                <div style={{ position: 'relative' }}>
                  { change }
                </div>
              : null
            }
          </ListItemText>
        </ListItem>
      </List>
    );
  }

}

ChangeMetric.defaultProps = {
  icon: VisibilityIcon,
  iconColor: '#37caad',
  iconBackgroundColor: 'rgba(55, 202, 173, 0.2)',
  iconSize: 70,
  title: 'Metric',
  metric: 100,
  change: null,
}

export default ChangeMetric;
