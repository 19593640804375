import React from 'react';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';

class NewChip extends React.PureComponent {
	render() {

		const { classes } = this.props;

		return (
			<Chip
				color='primary'
				label={ this.props.label || 'New' }
				classes={{colorPrimary: classes.chipClass}}
			/>
		);
	}
}

const styles = theme => ({
	chipClass: {
		backgroundColor: '#CEF0BD',
		borderRadius: 3,
		color: '#347a11',
		fontSize: 14,
	}
});


export default withStyles(styles)(NewChip);