import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 32 21">
   <g fill="none" fillRule="evenodd">
      <g transform="translate(0 2)">
        <path fill="#F6F9FC" d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19z"/>
        <path fill="#F99F1B" d="M0 16v.63A2.4 2.4 0 0 0 2.42 19h24.16A2.4 2.4 0 0 0 29 16.62V16H0z"/>
        <path fill="#2D4990" d="M0 3v-.63A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38V3H0zm13.9 3.12l-1.48 6.77h-1.77l1.46-6.77h1.78zm7.47 4.38l.94-2.55.54 2.55h-1.48zm1.99 2.4H25l-1.44-6.78h-1.51a.8.8 0 0 0-.76.5l-2.67 6.27h1.87l.37-1h2.28l.22 1zm-4.64-2.22c0-1.78-2.51-1.88-2.5-2.68.01-.24.25-.5.76-.57a3.4 3.4 0 0 1 1.75.3l.31-1.43c-.42-.15-.97-.3-1.66-.3-1.76 0-3 .92-3 2.24-.01.97.88 1.52 1.55 1.84.7.33.93.55.93.84-.01.46-.56.66-1.07.66-.9.02-1.41-.23-1.82-.42l-.33 1.48c.42.19 1.19.35 1.98.36 1.87 0 3.09-.9 3.1-2.32zm-7.37-4.56L8.47 12.9H6.6L5.18 7.5c-.09-.33-.17-.45-.43-.6A7.53 7.53 0 0 0 3 6.33l.04-.2h3.03c.38 0 .73.26.82.7l.75 3.91 1.85-4.6h1.86z"/>
      </g>
    </g>
  </SvgIcon>
);