import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import ChatBox from '../../ChatBox/ChatBox';

class TextAreaQuestion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    };
  }

  componentDidMount = () => {
    this.setState({ value: this.props.responseData || '' });
  }

  onChange = e => {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value);
  }

  render() {
    return (
      <FormControl component="fieldset" style={{ marginBottom: 25 }}>
        <ChatBox>
          <Typography variant="subtitle1">{ this.props.question.prompt }</Typography>
          <Typography variant="caption">{ this.props.question.helperText }</Typography>
        </ChatBox>
        <FormGroup>
          <div style={{ paddingTop: 15 }}>
            <TextField
              multiline
              fullWidth
              className="form-control"
              value={ this.state.value }
              onChange={this.onChange}
              rows="10"
              placeholder={ this.props.question.placeholder || null }
              variant="outlined"
            />
          </div>
        </FormGroup>
        </FormControl>
     )
  }
}

export default TextAreaQuestion;