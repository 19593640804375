import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import './CheckboxQuestion.css';
import ChatBox from '../../ChatBox/ChatBox';


class CheckboxQuestion extends React.Component {
  state = {
    responseData: []
  }

  componentDidMount = () => {
    const responseData = this.props.responseData || [];
    const mergedResponse = this.props.question.data.options.map(q => {
      const r = responseData.find(r => r.text === q.text);
      q.value = r ? r.value : q.default;
      return q;
    });
    this.setState(
      { responseData: mergedResponse },
      // Set initial results in case the user uses the default
      () => this.props.onChange(mergedResponse)
    );
  }


  onChange = (e, o) => {
    o.value = !o.value;
    this.setState({ responseData: this.state.responseData }, () => this.props.onChange(this.state.responseData));
  }

  render() {
    const { classes, question } = this.props;
    const checkboxes = this.state.responseData.map( (r,i) => {
      return (
        <FormControlLabel
          key={i}
          control={
            <Checkbox
              checked={r.value}
              onChange={ e => this.onChange(e, r) }
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
            />
          }
          label={r.text}
        />
      );
    });


    return (
      <FormControl component="fieldset" style={{ marginBottom: 25 }}>
        <ChatBox>
          <Typography variant="subtitle1">{ question.prompt }</Typography>
          <Typography variant="caption">{ question.helperText }</Typography>
        </ChatBox>
        <FormGroup row>
          {checkboxes}
        </FormGroup>
      </FormControl>
    )
  }
}


const styles = theme => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  size: {
    width: 40,
    height: 40,
  },
  sizeIcon: {
    fontSize: 20,
  },
});


export default withStyles(styles)(CheckboxQuestion);