import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import FacebookAuth from '../FacebookAuth/FacebookAuth';
import FacebookIcon from '../Icons/FacebookIcon';
import CircularProgress from '@material-ui/core/CircularProgress'
import OpenInNew from '@material-ui/icons/OpenInNew';
import Clear from '@material-ui/icons/Clear';
import Refresh from '@material-ui/icons/Refresh';
import { log, withContext } from 'kn-react';
import FacebookAuthPageModal from './FacebookAuthPageModal';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '../Alert/Alert';
import ReviewsSignOutConfirmationDialog from '../ReviewsSignOutConfirmationDialog/ReviewsSignOutConfirmationDialog';

import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar, Button, Hidden } from '@material-ui/core';
import { FacebookAuthContext } from './FacebookAuthProvider';

const facebookAuthPage = props => {
  if( props.isProcessingFacebookAccount ){
    return <CircularProgress />;
  }


  const { classes, facebookAccount, facebookAccountPage } = props;

  const paddedExpiredDate = moment((!facebookAccount || !facebookAccount.id) ? null : facebookAccount.expiresAt).subtract(3, 'days')
  const accessTokenExpired = moment().isAfter(paddedExpiredDate)

  const loginRequired = !facebookAccount || !facebookAccount.id;
  const fullyLoggedIn = !loginRequired && facebookAccountPage.id;

  const signInError = (
    facebookAccountPage && facebookAccountPage.isError && facebookAccountPage.errorResponse ?
      JSON.parse( facebookAccountPage.errorResponse || '' )
    :
      null
  );

  const primaryListItems = (
    <React.Fragment>
      <ListItemAvatar>
        <Avatar>
          <FacebookIcon />
        </Avatar>
      </ListItemAvatar>

      {
        fullyLoggedIn ?

          <ListItemText
            secondary={
              `${facebookAccountPage.name}`
            }
            primary={facebookAccount.email || 'emailaddress@email.com'}
          />
        :
          <ListItemText
            secondary="No Facebook Page found."
            primary={facebookAccount.email || 'emailaddress@email.com'}
          />
      }
    </React.Fragment>
  );

  const secondaryListItemsDesktop = (
    <ListItemSecondaryAction style={{ paddingTop: 15 }}>

      {
        (props.canSignIn) ?
          <Tooltip title='Refresh'>
            <IconButton onClick={props.startFacebookOauth}>
              <Refresh/>
            </IconButton>
          </Tooltip>
        :
          null
      }

      {
        (props.canSignOut) ?
          <Tooltip title='Sign Out'>
            <IconButton onClick={props.openConfirmationDialog}>
              <Clear/>
            </IconButton>
          </Tooltip>
        :
          null
      }

    </ListItemSecondaryAction>
  );

  const secondaryListItemsMobile = (
    <Grid container spacing={16} item xs={12}>
      {
        (props.canSignIn) ?
          <Grid item xs={6}>
            <Button
              onClick={props.startFacebookOauth}
              variant='outlined'
              size='small'
            >
              <Refresh/>
              Refresh
            </Button>
          </Grid>
        :
          null
      }

      {
        (props.canSignOut) ?
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              onClick={props.openConfirmationDialog}
              variant='outlined'
              size='small'
            >
              <Clear/>
              Sign Out
            </Button>
          </Grid>
        :
          null
      }
    </Grid>
  );


  log('facebookAccount',facebookAccount)
  log('facebookAccountPage',facebookAccountPage)
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        {
          (loginRequired) ?
            <div style={{ textAlign: 'center' }}>
              <FacebookAuth
                canSignIn={props.canSignIn}
              />
            </div>
          :
            null
        }

        {
          (props.pageDialogOpen) ?
            <FacebookAuthPageModal
              facebookAccount={facebookAccount}
              dialogOpen={props.pageDialogOpen}
              onClose={props.closePageDialog}
              onSelectPage={props.onSelectPage}
              getFacebookPagesCallback={props.getFacebookPagesCallback}
            />
            :
            null
        }

        {
          (loginRequired || !facebookAccount) ?
            null
          :
            props.facebookAccountPageLoading ?
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            :
              <div>
                <List className={classes.authGrouping}>
                  <Hidden xsDown>
                    <ListItem style={{ paddingTop: 5 }}>
                      { primaryListItems }
                      { secondaryListItemsDesktop }
                    </ListItem>
                  </Hidden>

                  <Hidden smUp >
                    <div style={{ textAlign: 'center' }}>
                      <ListItem dense>
                        { primaryListItems }
                      </ListItem>
                      <ListItem>
                        { secondaryListItemsMobile }
                      </ListItem>
                    </div>
                  </Hidden>
                </List>


                <ReviewsSignOutConfirmationDialog
                  dialogOpen={ props.confirmationDialogOpen }
                  sourceName='Facebook'
                  onClose={ props.closeConfirmationDialog }
                  onSignOut={ props.onSignOut }
                />

                {
                  (facebookAccountPage && facebookAccountPage.id) ?
                    null
                  :
                    <Alert warning>
                      Please create a Facebook Page and <Link href="#" onClick={props.startFacebookOauth}>try again</Link>.
                    </Alert>
                }

                {/* {
                  accessTokenExpired ?
                    <Alert warning>
                      The credentials for this Facebook Page have expired. <Link href="#" onClick={() => props.startFacebookOauth(props.canSignIn)}>Please refresh this Page.</Link>
                    </Alert>
                  :
                    null
                } */}

              </div>
        }

        {
          (facebookAccountPage && facebookAccountPage.isError) ?
            <Alert warning>
              {
                signInError && signInError.error && signInError.error.error_user_msg ?
                  signInError.error.error_user_msg
                :
                  <React.Fragment>
                    Something went wrong. Please refresh your account and <Link href="#" onClick={props.startFacebookOauth}>try again</Link>.
                  </React.Fragment>
              }
            </Alert>
          :
            null
        }
      </Grid>
    </Grid>
  );
}

const styles = theme => ({
  authGrouping: {
    padding: 10,
    width: '100%'
  },
  button: {
    margin: 10
  },
  leftIcon: {
    marginRight: 10
  }
})

facebookAuthPage.defaultProps = {
  facebookAccount: {},
  facebookAccountPage: {},
  onSignOut: function () {},
  startFacebookOauth: function(){},
  openConfirmationDialog: function(){},
  closeConfirmationDialog: function(){},
  tryAgain: function(){},
  canSignOut: true,
  canSignIn: true,
  getFacebookPagesCallback: function(){},
  facebookAccountPageLoading: false
}

export default withContext(
  FacebookAuthContext,
  withStyles(styles)(
    facebookAuthPage
  )
);