import React from 'react';
import CallAnnotation from '../CallAnnotation/CallAnnotation';
import CallPlayer from '../CallPlayer/CallPlayer';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';


class CallCard extends React.PureComponent {

  render() {
    const { classes, call } = this.props;

    return (
      <div className={classes.mainPaperStyle}>
        {
          call.callStatus == 'answered' ?
            <CallPlayer
              audioUrl={this.props.audioUrl}
              mainColor={this.props.mainColor}
            />
          :
            null
        }

        <CallAnnotation
          mainColor={this.props.mainColor}
          call={call}
        />
      </div>
    );
  }
}

CallCard.defaultProps = {
  audioUrl: null,
  mainColor: null,
  call: {
    callerName: null,
    callerNumber: null,
    callStatus: null,
    duration: null,
  }
}

const styles = theme => ({
  mainPaperStyle: {
    backgroundColor: '#fafafa',
    padding: 24,
  }
})

export default withStyles(styles)(CallCard);