import React from 'react';
import ReactToPrint from 'react-to-print';

import { withContext, format, EntityAnalysisPhraseTrie, constructPostMeetingTranscript } from 'kn-react';
import { withStyles, Typography, IconButton, Tooltip, CircularProgress, Paper, Tabs, Tab, LinearProgress, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Print, Share } from '@material-ui/icons';


import TranscriptContent from '../TranscriptContent/TranscriptContent';
import VideoAnalysis from '../VideoAnalysis/VideoAnalysis';


const COMBINED_TRANSCRIPT_NAME = 'Combined Transcript';
const TRANSCRIPT_NAME = 'Text Transcript';
const TRANSCRIPT_WITH_SPEAKERS_NAME = 'Transcript w/ Speakers';
const TEXT_ANALYSIS_NAME = 'Text Analysis';
const VIDEO_ANALYSIS_NAME = 'Video Analysis';


const TABS = [
  COMBINED_TRANSCRIPT_NAME,
  TRANSCRIPT_NAME,
  TRANSCRIPT_WITH_SPEAKERS_NAME,
  TEXT_ANALYSIS_NAME,
  VIDEO_ANALYSIS_NAME,
];


class MeetingAnalysis extends React.Component {
  constructor(props){
    super(props);


    const entityAnalysisPhraseTrie = new EntityAnalysisPhraseTrie();

    this.printableComponent = React.createRef();


    this.state = {
      tab: 0,
      text: null,
      wordSpeakerTags: null,
      entityAnalysis: null,
      entityAnalysisPhraseTrie
    };
  }


  componentDidMount = () => {
    if( this.props.meeting ){
      return this.parseMeeting();
    }
  }



  componentDidUpdate = oldProps => {
    if( oldProps.meeting !== this.props.meeting ){
      return this.parseMeeting();
    }
  }



  parseMeeting = () => {
    const { meeting } = this.props;


    let text;
    let wordSpeakerTags;
    if(
      meeting &&
      meeting.transcriptApiResponse &&
      meeting.transcriptApiResponse.results
    ){
      text = constructPostMeetingTranscript(
        meeting
      );


      const transcriptsToEvaluate = meeting.transcriptApiResponse.results[
        Math.max(meeting.transcriptApiResponse.results.length - 1, 0)
      ];

      const wordsToEvaluate = (
        (
          transcriptsToEvaluate &&
          Array.isArray( transcriptsToEvaluate.alternatives ) &&
          transcriptsToEvaluate.alternatives[ 0 ] &&
          transcriptsToEvaluate.alternatives[ 0 ].words
        ) ?
          transcriptsToEvaluate.alternatives[ 0 ].words
        :
          null
      );

      wordSpeakerTags = (
        !wordsToEvaluate ?
          []
        :
          wordsToEvaluate.map(w => ({
            word: w.word,
            speakerTag: w.speakerTag
          }))
      );
    }


    let entityAnalysis;
    if(
      meeting &&
      meeting.transcriptEntityAnalysisApiResponse &&
      meeting.transcriptEntityAnalysisApiResponse.entities
    ){
      meeting.transcriptEntityAnalysisApiResponse.entities.forEach(a => {
        this.state.entityAnalysisPhraseTrie.insert(
          a
        );
      });
    }


    return this.setState({
      text,
      wordSpeakerTags,
      entityAnalysis
    });
  }



  enabledDisabled = isEnabled => (
    <span
      style={{
        color: isEnabled ? 'green' : 'darkorange'
      }}
    >
      { isEnabled ? 'Enabled' : 'Disabled' }
    </span>
  )


  render(){
    const { classes } = this.props;


    const transcriptContentProps = {}
    let isDataAvailable = Boolean(
      this.state.text &&
      this.state.text.trim()
    );

    switch( this.state.tab ){
      case TABS.indexOf( TRANSCRIPT_NAME ):
        transcriptContentProps.text = this.state.text;
        break;
      case TABS.indexOf( TRANSCRIPT_WITH_SPEAKERS_NAME ):
        transcriptContentProps.text = this.state.text;
        transcriptContentProps.wordSpeakerTags = this.state.wordSpeakerTags;
        break;
      case TABS.indexOf( TEXT_ANALYSIS_NAME ):
        transcriptContentProps.text = this.state.text;
        transcriptContentProps.entityAnalysisPhraseTrie = this.state.entityAnalysisPhraseTrie;
        break;
      default:
        transcriptContentProps.text = this.state.text;
        transcriptContentProps.wordSpeakerTags = this.state.wordSpeakerTags;
        transcriptContentProps.entityAnalysisPhraseTrie = this.state.entityAnalysisPhraseTrie;
    }


    if( this.state.tab === TABS.indexOf( VIDEO_ANALYSIS_NAME ) ){
      isDataAvailable = this.props.meeting && this.props.meeting.videoRecordingUrl;
    }


    return (
      <Paper
        className={classes.paper}
        id='content-to-print'
        ref={divRef => {
          this.printableComponent = divRef;
        }}
        style={{
          width: this.props.width || undefined
        }}
      >
        <Tabs
          value={this.state.tab}
          onChange={(e, tab) => this.setState({ tab})}
          className={classes.tabs}
        >
          {
            TABS.map(
              (t, i) => (
                <Tab key={i} label={t} />
              )
            )
          }
        </Tabs>

        <div className={classes.loadingWarning}>
          <div className={classes.loadingWarningText}>
            <Typography variant='caption'>
              Tabs may take a few seconds to process and appear.
            </Typography>
          </div>

          {
            this.props.cannotPrint ?
              null
            :
              <ReactToPrint
              trigger={() => (
                <IconButton>
                  <Print className={classes.print}/>
                </IconButton>
              )}
              content={() => this.printableComponent}
              bodyClass={classes.printWindow}
            />
          }
        </div>

        {
          (
            !this.props.meeting ||
            !isDataAvailable
          ) ?
            <div
              className={classes.noData}
            >
              <Typography
                variant={'caption'}
                className={classes.noDataText}
              >
                No data available
              </Typography>
            </div>
          :
            TABS[ this.state.tab ] === VIDEO_ANALYSIS_NAME ?
              <VideoAnalysis
                videoAnalysisApiResponse={this.props.meeting.videoAnalysisApiResponse}
                videoRecordingUrl={this.props.meeting.videoRecordingUrl}
                endedAt={this.props.meeting.endedAt}
                createdAt={this.props.meeting.createdAt}
              />
            :
              <TranscriptContent
                { ...transcriptContentProps }
              />
        }
      </Paper>
    )
  }
}


const styles = theme => ({
  paper: {
    width: '60%',
    height: '90%',
    margin: 'auto',
    padding: 20,
    overflowY: 'auto'
  },
  tabs: {
    marginBottom: 15
  },
  loadingWarning: {
    marginBottom: 15,
    // marginTop: -5,
    opacity: 0.5,
    display: 'flex'
  },
  printWindow: {
    backgroundColor: 'white',
    marginLeft: -250,
    marginRight: -250,
  },
  print: {
    fontSize: 24
  },
  loadingWarningText: {
    flex: 1
  },
  noData: {
    textAlign: 'center'
  },
  noDataText: {
    margin: 'auto',
    color: 'darkgrey'
  }
})


MeetingAnalysis.defaultProps = {
  meeting: null,
  width: null,
  cannotPrint: false
}


export default withStyles(styles)(
  MeetingAnalysis
)