import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


class Snackbar extends React.PureComponent {
  render() {
    return (
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.props.open}
        ClickAwayListenerProps={this.props.ClickAwayListenerProps}
        autoHideDuration={this.props.autoHideDuration}
        onClose={this.props.onClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{ this.props.message }</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.props.onClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
    />
     );
  }
}

Snackbar.defaultProps = {
  open: false,
  message: '',
  autoHideDuration: 6000,
  onClose: () => {}
};

export default Snackbar;

