import React from 'react';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import StarsRating from '../StarsRating/StarsRating';
import withStyles from '@material-ui/core/styles/withStyles';
import RateReview from '@material-ui/icons/RateReview';
import { Link } from 'react-router-dom';
import TextStatusIndicator from '../TextStatusIndicator/TextStatusIndicator';


const reviewListItem = props => {

  const { classes, review, maxChars } = props;

  const trimmedText = (maxChars && review.content && review.content.length > maxChars) ? review.content.slice(0, maxChars) : review.content;


  return (
    <React.Fragment>
      <ListItem
        button={props.to ? true : undefined}
        component={props.to ? Link : undefined}
        to={props.to}
        disableGutters={props.disableGutters || false}
      >
        <List>
          <ListItem disableGutters style={{ padding: 0 }}>
            <Avatar className={classes.avatar}>
              {props.icon || <RateReview />}
            </Avatar>
            <ListItemText disableTypography>
              {
                props.showCompanyName ?
                <Typography variant="subtitle2">{review.companyName}</Typography>
                :
                null
              }
              <Typography variant="body2">
                {review.author || 'Unknown Author'}
                <Typography variant="caption" color="textSecondary" style={{ display: 'inline', paddingLeft: 5 }}>
                  { moment(review.publishedAt).format('MM/DD/YYYY') }
                </Typography>
              </Typography>
              <div>
                {
                  review.isRespondedTo ?
                    <TextStatusIndicator typographyVariant="body2" text="Replied To" success />
                  : null
                }
              </div>
            </ListItemText>
          </ListItem>
          <ListItem disableGutters style={{ display: 'inherit', paddingBottom: 0 }}>
            <StarsRating average={review.rating} />
            <Typography className={classes.reviewText}>
              {trimmedText}{(review.content && trimmedText.length < review.content.length) ? <b className={classes.linkText}>...</b> : null}
            </Typography>
          </ListItem>
        </List>


      </ListItem>

      { props.divider ? <Divider/> : null }

    </React.Fragment>
  );
}

const styles = theme => ({
  reviewText: {
    color: '#3f4c4f',
    paddingTop: 5
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  linkText: {
    color: theme.palette.primary.main,
    display: 'inline'
  }
})


reviewListItem.defaultProps = {
  review: {
    author: '',
    content: '',
    rating: 0,
  },
  icon: null,
  maxChars: undefined,
  showCompanyName: false,
  divider: true,
}

export default withStyles(styles)(reviewListItem);