import React from 'react';

export default props => (
  <svg className={props.className || ''} xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" width={props.width || 1200} viewBox="0 0 1200 450" xml-space="preserve">
		<path fill="none" d="M338,246.912v22.985c0,32.091,17.01,54.305,59.357,54.305c9.867,0,21.643-1.054,39.643-5.068v-31.998
			c-20,3.511-26.232,4.285-36.077,4.285c-17.675,0-22.923-3.899-22.923-18.878v-28.331c0-14.962,5.248-18.862,22.923-18.862
			c9.845,0,16.077,0.774,36.077,4.271v-31.999c-18-4.016-29.776-5.075-39.643-5.075C355.01,192.547,338,214.797,338,246.912z"/>
		<polygon fill="none" points="1028.168,194 992,251.547 992,135.303 953,141.856 953,322 992,322 992,262.253 1028.168,322 
			1070.916,322 1031.451,256.347 1070.916,194 "/>
		<path fill="none" d="M220.222,194h-29.813l0.05-16.14c0-8.558,0.817-12.86,13.125-12.86H220v-33h-26.292
			C162.101,132,151,147.929,151,174.698V194h-20v33h20v95h39v-95h26.729L220.222,194z"/>
		<path fill="#4967A9" d="M1093.425,300h-0.062c-6.639,0-12.046,5.389-12.046,12.135c0,6.848,5.407,12.188,12.046,12.188
			c6.7,0,11.989-5.337,11.989-12.185C1105.353,305.392,1100.063,300,1093.425,300z M1093.425,322.431
			c-5.399,0-9.783-4.467-9.783-10.239c0-5.67,4.384-10.191,9.722-10.191h0.062c5.397,0,9.722,4.521,9.722,10.237
			C1103.146,317.964,1098.822,322.484,1093.425,322.431z"/>
		<path fill="none" d="M1096,312.558v-0.107c1-0.442,2.428-1.518,2.428-3.297c0-1.3-0.522-2.212-1.337-2.808
			c-0.813-0.597-2.181-1.021-4.243-1.021c-1.619,0-2.848,0.154-3.848,0.316V319h2v-6h1.596c1.743,0,2.603,0.734,2.928,2.466
			c0.266,1.773,0.597,3.534,0.922,3.534h2.269c-0.216,0-0.333-1.147-0.66-3.26C1097.791,313.745,1097,312.774,1096,312.558z"/>
		<path fill="none" d="M1092.666,312H1091v-4.897c0-0.054,0.914-0.108,1.619-0.108c2.426,0,3.314,1.339,3.314,2.531
			C1095.934,311.195,1094.439,312,1092.666,312z"/>
		<path fill="#4967A9" d="M560,270v-26.385c0-28.338-12.1-51.068-55.493-51.068c-43.384,0-55.507,23.001-55.507,51.068v26.282
			c0,32.091,16.611,54.305,58.958,54.305c19.326,0,29.042-1.054,49.042-5.068v-31.998c-20,3.511-30.096,4.285-45.991,4.285
			c-17.676,0-23.009-3.899-23.009-18.878V270H560z M488,238.518c0-9.083,3.107-16.417,16.508-16.417
			c13.377,0,16.492,7.334,16.492,16.417V244h-33V238.518z"/>
		<path fill="#4967A9" d="M270.02,194h-36.855l-3.3,33h33.726C284.21,227,286,231.137,286,244.521V250h-27.514
			c-26.785,0-41.757,9.445-41.757,35.648c0,22.221,12.835,38.328,36.947,38.328c8.831,0,22.223-1.161,37.243-9.454l1.417,8.478H325
			v-78.216C325,208.662,315.279,194,270.02,194z M286,290.833c-6,2.692-10.759,5.307-18.815,5.307c-4.226,0-7.17-1.259-8.935-3.642
			c-1.403-1.824-2.088-4.336-2.088-7.495c0-7.234,3.657-9.003,11.991-9.003H286V290.833z"/>
		<path fill="#4967A9" d="M1098.054,315.74c-0.263-1.995-1.054-2.966-2.054-3.183v-0.107c1-0.442,2.436-1.518,2.436-3.297
			c0-1.3-0.619-2.212-1.434-2.808c-0.813-0.597-2.144-1.021-4.206-1.021c-1.619,0-2.796,0.154-3.796,0.316V319h2v-6h1.596
			c1.743,0,2.603,0.734,2.928,2.466c0.266,1.773,0.597,3.534,0.922,3.534h2.269C1098.498,319,1098.381,317.853,1098.054,315.74z
			M1092.666,312H1091v-4.897c0-0.054,0.914-0.108,1.619-0.108c2.426,0,3.314,1.339,3.314,2.531
			C1095.934,311.195,1094.439,312,1092.666,312z"/>
		<path fill="#4967A9" d="M190,322h-39v-95h-20v-33h20v-19.302C151,147.929,162.101,132,193.708,132H220v33h-16.416
			c-12.309,0-13.125,4.303-13.125,12.86l-0.05,16.14h29.813l-3.493,33H190V322z"/>
		<path fill="#4967A9" d="M437,229.621c-20-3.497-26.232-4.271-36.077-4.271c-17.675,0-22.923,3.9-22.923,18.862v28.331
			c0,14.979,5.248,18.878,22.923,18.878c9.845,0,16.077-0.774,36.077-4.285v31.998c-18,4.015-29.776,5.068-39.643,5.068
			c-42.347,0-59.357-22.214-59.357-54.305v-22.985c0-32.115,17.01-54.365,59.357-54.365c9.867,0,21.643,1.06,39.643,5.075V229.621z"/>
		<polygon fill="#4967A9" points="1070.916,322 1028.168,322 992,262.253 992,322 953,322 953,141.856 992,135.303 992,251.547 
			1028.168,194 1070.916,194 1031.451,256.347 "/>
		<path fill="#4967A9" d="M642.793,192.547c-10.037,0-20.793,1.602-29.793,4.758v-62.002l-39,6.554v177.277
			c21,3.297,32.864,5.007,52.291,5.007c53.834,0,62.709-22.624,62.709-53.64v-31.983C689,210.187,682.236,192.547,642.793,192.547z
			M649,270.771c0,12.31-2.563,21.138-21.873,21.138c-5.352,0-8.127,0-14.127-0.55v-60.641c6-2.677,11.596-5.307,19.643-5.307
			c13.416,0,16.357,7.304,16.357,16.386V270.771z"/>
		<path fill="#4967A9" d="M883.625,192.632c-46.359,0-57.625,25.957-57.625,52.718v25.832c0,26.754,11.266,52.703,57.625,52.703
			c46.313,0,57.375-25.949,57.375-52.703V245.35C941,218.589,929.938,192.632,883.625,192.632z M902,274.989
			c0,9.113-4.657,16.432-18.036,16.432c-13.393,0-17.964-7.318-17.964-16.432v-33.191c0-9.082,4.571-16.386,17.964-16.386
			c13.379,0,18.036,7.304,18.036,16.386V274.989z"/>
		<path fill="#4967A9" d="M757.617,192.632c-46.359,0-57.617,25.957-57.617,52.718v25.832c0,26.754,11.258,52.703,57.617,52.703
			c46.326,0,57.383-25.949,57.383-52.703V245.35C815,218.589,803.943,192.632,757.617,192.632z M776,274.989
			c0,9.113-5.123,16.432-18.501,16.432c-13.415,0-18.499-7.318-18.499-16.432v-33.191c0-9.082,5.084-16.386,18.499-16.386
			c13.378,0,18.501,7.304,18.501,16.386V274.989z"/>
	</svg>
);