import React from 'react';

import { ChromePicker } from 'react-color';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';



// Make whitesmoke default color
const DEFAULT_HEX_COLOR = '#f5f5f5';

class ColorQuestion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      color: ''
    }
  }

  componentDidMount = () => {
    return  this.onChange({
      hex: (
        this.props.responseData ||
        this.props.question.data.default ||
        DEFAULT_HEX_COLOR
      )
    });
  }



  onChange = color => {
    this.setState({ color: color.hex });
    this.props.onChange( color.hex );
  }

  render() {
    const { question } = this.props;

    return (
      <FormControl component="fieldset">
        <Typography variant="subtitle1">{ question.prompt }</Typography>
        <Typography variant="caption">{ question.helperText }</Typography>

        <FormGroup>
          <div style={{ paddingTop: 15 }}>
            <ChromePicker
              color={this.state.color}
              onChange={this.onChange}
              disableAlpha={true}
            />
          </div>
        </FormGroup>
        </FormControl>
     )
  }
}

export default ColorQuestion;