import { JsonModel } from 'kn-react';

export default class UserRoleModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    const model = new UserRoleModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }


  roleHasAbility = ability => {
    return this.roleAbilities.split(',').find(a => a === ability) ? true : false;
  }

  roleHasAtLeastOneAbility = (...abilities) => {
    const roleAbilities = this.roleAbilities.split(',');

    return Boolean(
      abilities.map(a => roleAbilities.indexOf(a) > -1)
      .filter(a => a).length
    );
  }


  isCompanyRole = () => {
    return this.roleType === 'Company';
  }


  isOrganizationRole = () => {
    return this.roleType === 'Organization';
  }

  isApplicationEditorRole = () => {
    return this.roleName === 'Application Editor';
  }

}
