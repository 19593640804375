import React from 'react';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import FacebookIcon from '../Icons/FacebookIcon';
import { withContext } from 'kn-react';
import { FacebookAuthContext } from '../FacebookAuthPage/FacebookAuthProvider';

const facebookAuth = props => (
  props.isProcessingFacebookAccount ?
    <CircularProgress />
  :
    <Button
      variant="contained"
      size="small"
      className={props.classes.button}
      disabled={!props.canSignIn}
      onClick={e => {
        e.preventDefault();

        if( !props.canSignIn ){
          return;
        }

        props.startFacebookOauth();
      }}
    >
      <FacebookIcon className={classnames(props.classes.leftIcon, props.classes.iconSmall)} />
      Log in with Facebook
    </Button>
);

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#3C5A96',
    color: 'white',
    '&:hover': { backgroundColor: '#1B2A46' },
    '&:focus': { backgroundColor: '#1B2A46' },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

facebookAuth.defaultProps = {
  startFacebookOauth: function(){},
  canSignIn: true,
  isProcessingAccount: false
}

export default withContext(
  FacebookAuthContext,
  withStyles(styles)(
    facebookAuth
  )
);