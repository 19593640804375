import React from 'react';
import { format, JsConvertCase } from 'kn-react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';


class CallAnnotation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render() {
		const { classes, call } = this.props;

		return (
			<Grid container>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<List>
						<ListItem>
							<ListItemText primaryTypographyProps={{ color: 'primary' }} primary='Call Info:'/>
						</ListItem>
					</List>
				</Grid>

				<Grid item xs={12} sm={6}  style={{ textAlign: 'center' }}>
					<List>
						<ListSubheader className={classes.subheaderStyle}>
							Name
						</ListSubheader>
						<ListItem className={classes.listItemStyle}>
							<ListItemText primary={call.callerName}/>
						</ListItem>

						<ListSubheader className={classes.subheaderStyle}>
							Phone Number
						</ListSubheader>
						<ListItem className={classes.listItemStyle}>
							<ListItemText primary={
								call.callerNumber && call.callerNumber.length === 10 ?
									format.phone(
										(call.callerNumber || '')
									)
								:
									call.callerNumber || ''
							}/>
						</ListItem>

						{
							!call.trackingNumber ?
								null
							:
								(
									<React.Fragment>
										<ListSubheader className={classes.subheaderStyle}>
											Tracking Phone Number
										</ListSubheader>
										<ListItem className={classes.listItemStyle}>
											<ListItemText primary={
												call.trackingNumber && call.trackingNumber.length === 12 ?
													format.phone(
														call.trackingNumber.startsWith('+1') ?
															call.trackingNumber.slice(2)
														:
															call.trackingNumber
													)
												:
													call.trackingNumber && call.trackingNumber.length === 10 ?
														format.phone(
															call.trackingNumber
														)
													:
														call.trackingNumber || ''
											}/>

										</ListItem>
									</React.Fragment>
								)
						}
					</List>
				</Grid>

				<Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
					<List>
						<ListSubheader className={classes.subheaderStyle}>
							Call Status
						</ListSubheader>
						<ListItem className={classes.listItemStyle}>
							<ListItemText primary={ JsConvertCase.toHeaderCase(call.callStatus) }/>
						</ListItem>

						<ListSubheader className={classes.subheaderStyle}>
							Call Duration
						</ListSubheader>
						<ListItem className={classes.listItemStyle}>
							<ListItemText primary={format.duration(call.duration)}/>
						</ListItem>

						{
							!call.trackingNumber || !call.receivingNumber ?
								null
							:
								(
									<React.Fragment>
										<ListSubheader className={classes.subheaderStyle}>
											Forwarded To Number
										</ListSubheader>
										<ListItem className={classes.listItemStyle}>
											<ListItemText primary={
												call.receivingNumber && call.receivingNumber.length === 10 ?
													format.phone(
														call.receivingNumber
													)
												:
													call.receivingNumber || ''
											}/>
										</ListItem>
									</React.Fragment>
								)
						}
					</List>
				</Grid>

				{/* {
					!call.trackingNumber ?
						null
					:
						(
							<Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
								<List>
									<div>
										<ListSubheader className={classes.subheaderStyle}>
											Tracking Phone Number
										</ListSubheader>
										<ListItem className={classes.listItemStyle}>
											<ListItemText primary={
												call.trackingNumber && call.trackingNumber.length === 10 ?
													format.phone(
														(call.trackingNumber || '').slice(2)
													)
												:
													call.trackingNumber || ''
											}/>
										</ListItem>
									</div>

									{
										!call.receivingNumber ?
											null
										:
											(
												<div>
													<ListSubheader className={classes.subheaderStyle}>
														Forwarded To Number
													</ListSubheader>
													<ListItem className={classes.listItemStyle}>
														<ListItemText primary={
															call.receivingNumber && call.receivingNumber.length === 10 ?
																format.phone(
																	(call.receivingNumber || '').slice(2)
																)
															:
																call.receivingNumber || ''
														}/>
													</ListItem>
												</div>
											)
									}
								</List>
							</Grid>
						)
				} */}

				{/* <Grid container item xs={12} sm={4} style={{ textAlign: 'center' }}>
					<List>
						<div>
							<ListItem>
								<ListItemText primaryTypographyProps={{ color: 'primary' }} primary='How We Marked This Call:'/>
							</ListItem>
						</div>
						<Grid item xs={12}>
							<TextField
								className={classes.textFieldStyle}
								label='Select Category'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								className={classes.textFieldStyle}
								label='Action Type'
							/>
						</Grid>
					</List>
				</Grid> */}



			</Grid>
		);
	}
}



CallAnnotation.defaultProps = {
	mainColor: null,
	call: {
		callerName: null,
		callerNumber: null,
		callStatus: null,
		duration: null,
	}
}

const styles = theme => ({
	subheaderStyle: {
		float: 'left'
	},
	listItemStyle: {
		paddingTop: 0
	},
	textFieldStyle: {
		float: 'left'
	}
})

export default withStyles(styles)(CallAnnotation);