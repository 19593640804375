import React from 'react';
import classnames from 'classnames';

import { withContext } from 'kn-react';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';

import { withStyles, Tooltip, Grid, Typography } from '@material-ui/core';


import HighlightMenu, { TRANSCRIPT_GRID_ID } from '../HighlightMenu/HighlightMenu';



class TranscriptContent extends React.Component {
  state = {
    annotatedTranscript: ''
  }


  componentDidMount = () => {
    const annotatedTranscript = this.annotatedTranscript(
      this.props.text
    );


    return this.setState({
      annotatedTranscript
    });
  }


  componentDidUpdate = oldProps => {
    const haveDetailsChanged = (
      ( oldProps.text !== this.props.text ) ||
      ( oldProps.entityAnalysisPhraseTrie !== this.props.entityAnalysisPhraseTrie ) ||
      ( oldProps.wordSpeakerTags !== this.props.wordSpeakerTags )
    );


    if( haveDetailsChanged ){
      const annotatedTranscript = this.annotatedTranscript(
        this.props.text
      );

      return this.setState({
        annotatedTranscript
      });
    }
  }


  annotatedTranscript = text => {
    const words = text.split(' ').filter(w => w);

    const annotatedTranscript = [];

    let i = 0;
    let speakerTag;
    let speakerAnnotations = [];
    let beginOffset = 0;
    let entityStartIndex = null;
    for(i = 0; i < words.length; i++){
      let word = words[ i ];
      let currentSpeakerTag;

      if(
        this.props.wordSpeakerTags &&
        this.props.wordSpeakerTags.length &&
        i < this.props.wordSpeakerTags.length &&
        this.props.wordSpeakerTags[ i ].word === word
      ){
        currentSpeakerTag = this.props.wordSpeakerTags[ i ].speakerTag;
      }


      if(
        currentSpeakerTag != speakerTag &&
        speakerAnnotations.length
      ){
        annotatedTranscript.push(
          <div
            key={`speaker-text-${ i }`}
            className={this.props.classes.speakerGrid}
          >
            <div
              className={classnames(this.props.classes.speakerTag)}
            >
              <Typography
                variant='caption'
              >
                Speaker { speakerTag }
              </Typography>
            </div>

            <div
              className={classnames(this.props.classes.speakerTagAnnotations)}
            >
              <Typography>
                { speakerAnnotations }
              </Typography>
            </div>
          </div>
        );

        speakerAnnotations = [];
      }


      speakerTag = currentSpeakerTag;


      const dirtyWord = (
        entityStartIndex === null ?
          word
        :
          words.slice(entityStartIndex, i+1).join(' ')
      );

      const searchWord = cleanEntityName( dirtyWord );


      let entity;
      if( this.props.entityAnalysisPhraseTrie ){
        entity = this.props.entityAnalysisPhraseTrie.search( searchWord );
      }

      let isPartialEntity = Boolean( entity );
      if( !entity && this.props.entityAnalysisPhraseTrie ){
        isPartialEntity = this.props.entityAnalysisPhraseTrie.isPartialEntity( searchWord );
      }


      if( ( entity || isPartialEntity ) && entityStartIndex === null ){
        entityStartIndex = i;
      }


      if(
        ( entity || isPartialEntity ) &&
        ( i !== ( words.length - 1 ) )
      ){
        continue
      } else if( entityStartIndex !== null ){
        const phraseNotInserted = cleanEntityName(
          words.slice(entityStartIndex, i+1).join(' ')
        );

        entity = this.props.entityAnalysisPhraseTrie.search( phraseNotInserted );


        // When words are combined, there are chances that the addition of the last word does not create an entity
        let backSteps = 1;
        if( !entity ){
          while( true ){
            const phraseNotInsertedSplit = phraseNotInserted.split(' ');

            if( backSteps >= phraseNotInsertedSplit.length ){
              break;
            }


            const entityPhrase = (
              phraseNotInsertedSplit.slice(
                0,
                phraseNotInsertedSplit.length - backSteps
              ).join(' ')
            );

            const entityCleanedPhrase = cleanEntityName(
              entityPhrase
            );

            entity = this.props.entityAnalysisPhraseTrie.search( entityCleanedPhrase );


            if( !entity ){
              backSteps += 1;
              continue;
            }


            speakerAnnotations.push(
              this.constructNode(entityCleanedPhrase, entity, i-1)
            );

            break;
          }
        } else {
          speakerAnnotations.push(
            this.constructNode(phraseNotInserted, entity, i)
          );
        }


        entityStartIndex = null;


        if( backSteps === searchWord.split(' ').length ){
          i -= ( backSteps - 1 );
        } else {
          i -= backSteps;
        }


        // Only restart current loop if an entity was found and added
        if( entity ){
          continue;
        } else {
          word = phraseNotInserted.split(' ')[ 0 ];
        }
      }


      speakerAnnotations.push(
        this.constructBasicNode(word, i)
      );

      beginOffset += ( word.length + 1 );
    }


    if( entityStartIndex !== null ){
      const lastPhrase = cleanEntityName(
        words.slice(entityStartIndex, i+1).join(' ')
      );

      const entity = this.props.entityAnalysisPhraseTrie.search( lastPhrase );


      if( entity ){
        speakerAnnotations.push(
          this.constructNode(lastPhrase, entity, beginOffset)
        );

        entityStartIndex = null;
      } else {
        speakerAnnotations.push(
          this.constructBasicNode(lastPhrase, i)
        );
      }
    }


    if( speakerTag ){
      annotatedTranscript.push(
        <div
          key={`speaker-text-${ i }`}
          className={this.props.classes.speakerGrid}
        >
          <div
            className={classnames(this.props.classes.speakerTag)}
          >
            <Typography
              variant='caption'
            >
              Speaker { speakerTag }
            </Typography>
          </div>

          <div
            className={classnames(this.props.classes.speakerTagAnnotations)}
          >
            <Typography>
              { speakerAnnotations }
            </Typography>
          </div>
        </div>
      );
    } else {
      annotatedTranscript.push(
        <div style={{ position: 'relative' }} key={`speaker-text-${ words.length }`}>
          <Typography>
            { speakerAnnotations }
          </Typography>
        </div>
      );
    }


    return(
      <React.Fragment>
        { annotatedTranscript }
      </React.Fragment>
    );
  }



  copyToClipboard = text => navigator.clipboard.writeText(
    text
  ).then(
    () => this.props.showSnackbar(`"${ text }" was successfully copied to your clipboard.`)
  )



  constructBasicNode = (word, key) => (
    <span
      className={this.props.classes.word}
      data-begin-offset={key}
      key={key}
    >
      { word + ' ' }
    </span>
  )



  constructNode = (entityName, entity, beginOffset) => {
    const { classes } = this.props;

    let cssClass;
    let label;
    switch( entity.type ){
      case 'PERSON':
        cssClass = classes.person;
        label = 'Person';
        break;
      case 'LOCATION':
        cssClass = classes.location;
        label = 'Location';
        break;
      case 'ORGANIZATION':
        cssClass = classes.organization;
        label = 'Organization';
        break;
      case 'CONSUMER_GOOD':
        cssClass = classes.consumerGood;
        label = 'Consumer Good';
        break;
      case 'PHONE_NUMBER':
        cssClass = classes.phoneNumber;
        label = 'Phone Number';
        break;
      case 'ADDRESS':
        cssClass = classes.address;
        label = 'Address';
        break;
      case 'DATE':
        cssClass = classes.date;
        label = 'Date';
        break;
      case 'NUMBER':
        cssClass = classes.number;
        label = 'Number';
        break;
      case 'PRICE':
        cssClass = classes.price;
        label = 'Price';
        break;
      case 'EVENT':
        cssClass = classes.event;
        label = 'Event';
        break;
      default:
        cssClass = classes.other;
        label = 'Other';
    }


    return (
      <React.Fragment>
        <Tooltip
          title={`${ label } - Click to copy`}
        >
          <span
            className={classnames(cssClass, classes.entity, classes.word)}
            tabIndex={0}
            onClick={() => this.copyToClipboard(entityName)}
            data-begin-offset={beginOffset}
          >
            { entityName.trim() }
          </span>
        </Tooltip>

        { this.constructBasicNode(' ', beginOffset+entityName.trim().length) }
      </React.Fragment>
    )
  }



  render(){
    const { classes } = this.props;


    return(
      <div
        id={TRANSCRIPT_GRID_ID}
        className={classes.container}
      >
        { this.state.annotatedTranscript }

        <HighlightMenu text={this.props.text}/>
      </div>
    )
  }
}


TranscriptContent.defaultProps = {
  text: '',
  entityAnalysisPhraseTrie: null,
  wordSpeakerTags: [], // { word: '', speakerTag: '' }
}


const styles = theme => ({
  person: {
    backgroundColor: 'yellow'
  },
  location: {
    backgroundColor: 'lightblue'
  },
  organization: {
    backgroundColor: 'lightsalmon'
  },
  other: {
    backgroundColor: 'lightgrey'
  },
  event: {
    backgroundColor: 'lightblue'
  },
  consumerGood: {
    backgroundColor: 'lightgreen'
  },
  phoneNumber: {
    backgroundColor: 'deepskyblue'
  },
  address: {
    backgroundColor: 'lightskyblue'
  },
  date: {
    backgroundColor: 'lightsteelblue'
  },
  number: {
    backgroundColor: 'khaki'
  },
  price: {
    backgroundColor: 'lightpink'
  },
  entity: {
    cursor: 'pointer',
    padding: 2,
    borderRadius: 3
  },
  word: {
    marginRight: 3,
  },
  speakerTag: {
    width: '20%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  speakerTagAnnotations: {
    width: '80%'
  },
  speakerGrid: {
    breakInside: 'avoid',
    display: 'flex',
    position: 'relative',
    width: '100%',
    marginTop: 25,
  },
  container: {
    // breakInside: 'avoid'
  }
})


export default withContext(
  SnackbarContext,
  withStyles(styles)(
    TranscriptContent
  )
)



const cleanEntityName = name => (
  name.replace(/[^\w\d]$/, '').trim()
)