import React from 'react';

import { format } from 'kn-react';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';

import TableToolbar from '../TableToolbar/TableToolbar';
import SmallTablePagination from '../SmallTablePagination/SmallTablePagination';
import ClosedCircleIcon from '../Icons/ClosedCircleIcon';
import OpenCircleIcon from '../Icons/OpenCircleIcon';




class ExternalAssetConnectionTable extends React.Component {
  render() {
    const { classes, externalAssetConnections } = this.props;


    return (
      <div className={classes.root}>
        <TableToolbar
          title={`${ this.props.total } ${ this.props.title || `External Connection${ this.props.total === 1 ? 's' : '' }` }`}
          caption={this.props.caption}
          actions={this.props.secondaryActions}
        />

        {
          this.props.isLoading ?
            <LinearProgress />
          :
            null
        }

        <List className={classes.list}>
          {
            (
              !this.props.isLoading &&
              ( !externalAssetConnections || !externalAssetConnections.length )
            ) ?
              <div
                className={classes.noDataWrapper}
              >
                <Typography variant="caption" className={classes.noData}>
                  No { this.props.title || 'External Connections' }
                </Typography>
              </div>
            :
              externalAssetConnections.map(
                (e, i) => {
                  const source = (
                    (
                      e.source === 'Opportunity' &&
                      this.props.opportunitySourceName
                    ) ?
                      this.props.opportunitySourceName
                    :
                      e.source
                  );

                  const text = (
                    `${ e.externalSourceType }${ e.secondaryExternalSourceId ? ` (${ e.secondaryExternalSourceId })` : '' } | ${ e.externalSource }`
                  );


                  const isConnected = Boolean( e.externalSourceId );

                  const connectedIcon = (
                    isConnected ?
                      <ClosedCircleIcon
                        className={classes.connectedIcon}
                        fill={isConnected ? 'green' : undefined}
                      />
                    :
                      <OpenCircleIcon
                        className={classes.connectedIcon}
                        fill={isConnected ? 'green' : undefined}
                      />
                  );

                  const connectedStatus = (
                    <Typography
                      variant={'caption'}
                      className={classes.caption}
                    >
                      { connectedIcon }

                      {
                        e.externalSourceId ?
                          'Connected'
                        :
                          'Not connected'
                      }
                    </Typography>
                  );


                  const onClick = (
                    this.props.fetchIsListItemButton( e ) ?
                      () => this.props.onClickListItem(
                        e
                      )
                    :
                      undefined
                  );

                  return (
                    <ListItem
                      key={i}
                      className={classes.listItem}
                      button={this.props.fetchIsListItemButton( e )}
                      onClick={onClick}
                    >
                      <div className={classes.leftNoteContent}>
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{
                            className: classes.primaryText
                          }}
                          secondary={
                            <React.Fragment>
                              {
                                (
                                  !this.props.showSource &&
                                  !this.props.showCreatedAt
                                ) ?
                                  null
                                :
                                  <Typography
                                    variant={'caption'}
                                    className={classes.caption}
                                  >
                                    {
                                      this.props.showSource ?
                                        source
                                      :
                                        null
                                    }
                                    {
                                      (
                                        !e.createdAt ||
                                        !this.props.showCreatedAt
                                      ) ?
                                        null
                                      :
                                        `${ this.props.showSource ? ' | ' : '' }Connected: ${ format.dateTime( e.createdAt ) }`
                                    }
                                  </Typography>
                              }
                            </React.Fragment>
                          }
                        />
                      </div>

                      <div className={classes.rightNoteContent}>
                        { this.props.constructAdditionalSecondaryChildren( e ) }

                        { connectedStatus }
                      </div>
                    </ListItem>
                  )
                }
              )
          }
        </List>

        <Divider />

        <SmallTablePagination
          count={this.props.total || 0}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.page}
          onChangeRowsPerPage={e => this.props.changePagination({ rowsPerPage: e.target.value })}
          onChangePage={(e, p) => this.props.changePagination({ page: p })}
          rowsPerPageOptions={this.props.rowsPerPageOptions}
        />
      </div>
    );
  }
}


ExternalAssetConnectionTable.defaultProps = {
  total: 0,
  externalAssetConnections: [],
  isLoading: false,
  secondaryActions: null,
  rowsPerPage: 10,
  page: 0,
  changePagination: () => {},
  rowsPerPageOptions: [5, 10, 25, 50],
  showSource: false,
  showCreatedAt: false,
  opportunitySourceName: null,
  caption: null,
  constructAdditionalSecondaryChildren: () => {},
  fetchIsListItemButton: () => false,
  title: 'External Collections',
  onClickListItem: () => {}
}


const styles = theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  noData: {
    // margin: 'auto',
    opacity: 0.5
	},
  noDataWrapper: {
    textAlign: 'center',
    paddingTop: 10,
    color: '#777',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
	},
  listItem: {
    border: '1px solid lightgrey',
    borderRadius: 15,
    padding: 20,
    marginBottom: 15,
    backgroundColor: '#fbfbfb',
    // breakInside: 'avoid',
    flexWrap: 'wrap',
  },
  list: {
    padding: '20px !important',
    flex: 1,
    margin: '0 !important'
  },
  noteContent: {
    // breakInside: 'avoid',
  },
  noteDateTime: {
    marginTop: 10
  },
  primaryText: {
    wordBreak: 'break-word'
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.54)',
    wordBreak: 'break-word'
  },
  connectedIcon: {
    fontSize: 10,
    marginRight: 5
  },
  leftNoteContent: {
    flex: 1,
    minWidth: '60%'
  },
  rightNoteContent: {

  }
});

export default withStyles(styles)(
  ExternalAssetConnectionTable
);