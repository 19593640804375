import React from 'react';
import { format } from 'kn-react';
import OverTimeChart from '../OverTimeChart/OverTimeChart';
import BarChart from '../BarChart/BarChart';
import CostChart from '../CostChart/CostChart';
import PercentChangeMetric from '../PercentChangeMetric/PercentChangeMetric';
import ChangeMetric from '../ChangeMetric/ChangeMetric';
import KnPieChart from '../KnPieChart/KnPieChart';
import OpportunityTable from '../OpportunityTable/OpportunityTable'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeviceTypeTable from '../DeviceTypeTable/DeviceTypeTable';
import ClickThroughRateIcon from '../Icons/ClickThroughRateIcon';


class AdsDashboard extends React.Component {

  render() {
    var { views, viewsPercentChange,
            clicks, clicksPercentChange,
            ctr, ctrPercentChange,
            opportunities, opportunitiesPercentChange,
            viewClickData, viewClickLineStyle, viewClickYAxis,
            opportunityData, opportunityDataColors,calls,formSubmissions,
            ctrData,ctrDataStyles,ctrDataYAxis,ctrDataTooltipProps,
            costData, displayAmount, theme, latestOpportunities,
            devicesData} = this.props;

    opportunityData = (opportunityData) ? opportunityData : [
      { name: 'Call', value: calls },
      { name: 'Form', value: formSubmissions }
    ];

    viewClickLineStyle = (viewClickLineStyle) ? viewClickLineStyle : {
      Views: {
        type:"monotone",
        dataKey:"Views",
        stroke:theme.palette.primary.main,
        activeDot: { r: 8 },
        yAxisId:"left"
      },
      Clicks: {
        type: 'monotone',
        dataKey: 'Clicks',
        stroke:theme.palette.secondary.main,
        activeDot: { r:8 },
        yAxisId:"right"
      },
    };

    opportunityDataColors = (opportunityDataColors) ? opportunityDataColors : [
      theme.palette.primary.main,
      theme.palette.secondary.main
    ];

    ctrDataStyles = (ctrDataStyles) ? ctrDataStyles : {
      'Clicks': {
        dataKey:"Clicks",
        fill:theme.palette.primary.main,
        yAxisId: 'left'
      }
    };

    return (
      <Grid container spacing={32}>
        <Grid item xs={12}>
          <Typography variant="h6">Ads Dashboard</Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: 15 }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>

                <ChangeMetric
                  title="Views"
                  metric={ format.commas(views) }
                  icon={VisibilityIcon}
                  iconColor="#37caad"
                  iconBackgroundColor="rgba(55, 202, 173, 0.2)"
                  percentChange={viewsPercentChange}
                  percentChangeColor="#4fae21"
                  change= {
                    <span style={{ fontSize: 12, color: '#777' }}>
                      {`+100 vs 60 days ago`}
                    </span>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <PercentChangeMetric
                  title="Clicks"
                  metric={ format.commas(clicks) }
                  icon={VisibilityIcon}
                  iconColor="#5a7af9"
                  iconBackgroundColor="rgba(90, 122, 249, 0.2)"
                  percentChange={clicksPercentChange}
                  percentChangeColor="#ff5959"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <PercentChangeMetric
                  title="CTR"
                  metric={ format.percent(ctr) }
                  icon={ClickThroughRateIcon}
                  iconColor="#ff9a48"
                  iconBackgroundColor="rgba(255, 154, 72, 0.2)"
                  percentChange={ctrPercentChange}
                  percentChangeColor="#ff5959"
                />
              </Grid>


              <Grid item xs={12} sm={6} md={3}>
                <PercentChangeMetric
                  title="Opportunities"
                  metric={ format.commas(opportunities) }
                  icon={VisibilityIcon}
                  iconColor="#9e70ff"
                  iconBackgroundColor="rgba(158, 112, 255, 0.2)"
                  percentChange={opportunitiesPercentChange}
                  percentChangeColor="#4fae21"
                />
              </Grid>

            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} >
          <Paper style={{ padding: 15 }}>
            <Typography variant="h6">View / Clicks over time</Typography>
            <OverTimeChart
              width={'100%'}
              height={350}
              data={viewClickData}
              lineStyles={viewClickLineStyle}
              yAxis={viewClickYAxis}
            />
          </Paper>
        </Grid>

        <Grid item xs={4} >
          <Paper style={{ padding: 15, minHeight: 379 }}>
            <Typography variant="h6">Opportunity Type</Typography>
              <KnPieChart
                data={opportunityData}
                colors={opportunityDataColors}
                width={300}
                height={300}
                innerRadius={75}
              />
          </Paper>
        </Grid>


        <Grid item xs={8} >
          <Paper style={{ padding: 15, minHeight: 379 }}>
            <Typography variant="h6">Latest Opportunities</Typography>
            <OpportunityTable
              rowsPerPage={5}
              opportunities={latestOpportunities}
              tablePagination={false}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} >
          <Paper style={{ padding: 15, height: 379 }}>
            <Typography variant="h6">Click Through Rate</Typography>
              <BarChart
                data={ctrData}
                barStyles={ctrDataStyles}
                yAxis={ctrDataYAxis}
                tooltipProps={ctrDataTooltipProps}
                height={375}
              />
          </Paper>
        </Grid>

        <Grid item xs={6} >
          <Paper style={{ padding: 15, height: 379 }}>
            <Typography variant="h6">Device Type</Typography>
            <DeviceTypeTable
              desktopVisitors={devicesData.desktop.visitors}
              mobileVisitors={devicesData.mobile.visitors}
              tabletVisitors={devicesData.tablet.visitors}
              otherVisitors={devicesData.other.visitors}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} >
          <Paper style={{ padding: 15, height: 379 }}>
            <Typography variant="h6">Boosted Services</Typography>
          </Paper>
        </Grid>


        <Grid item xs={6} >
          <Paper style={{ padding: 15, height: 379 }}>
            <CostChart
              displayAmount={displayAmount}
              data={costData}
              mainColor={this.props.theme.palette.primary.main}
            />
          </Paper>
        </Grid>

      </Grid>
    )
  }
}

const styles = theme => ({

})

AdsDashboard.defaultProps = {
  views:null,
  viewsPercentChange:null,
  clicks:null,
  clicksPercentChange:null,
  ctr:null,
  ctrPercentChange:null,
  opportunities:null,
  opportunityDataColors: null,
  opportunitiesPercentChange:null,
  viewClickData: null,
  viewClickLineStyle: null,
  viewClickYAxis: [
    {
      yAxisId:"left"
    },
    {
      yAxisId:"right",
      orientation:"right"
    }
  ],
  opportunityData: null,
  calls: null,
  formSubmissions: null,
  opportunityDataColors: null,
  ctrData: null,
  ctrDataStyles: null,
  ctrDataYAxis: [
    {
      yAxisId:"left",
      tickFormatter: function(percent) {
        return (`${percent}%`)
      }
    }
  ],
  ctrDataTooltipProps: {
    formatter: function(percent) {
      return (`${percent}%`)
    }
  },
  displayAmount: null,
  costData: null,
  latestOpportunities: null,
  devicesData: {
    desktop: {},
    mobile: {},
    tablet: {},
    other: {}
  }
}

export default withStyles(styles, {withTheme: true})(AdsDashboard);