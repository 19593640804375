/*global window*/
import React from 'react';
import { log, withContext, randomString, stringifyParamsObject } from 'kn-react';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';
import isDevelopment from '../UtilityFunctions/isDevelopment';
import isDemo from '../UtilityFunctions/isDemo';
import isStaging from '../UtilityFunctions/isStaging';


const DEFAULT_STATE = {
  oauthState: '',
  myBusinessDialogOpen: false,
  locationsDialogOpen: false,
  confirmationDialogOpen: false,
  isProcessingGoogleAccount: false
};


export const GoogleAuthContext = React.createContext(DEFAULT_STATE);


class GoogleAuthProvider extends React.Component {
  state = DEFAULT_STATE;

  // Event Listeners
  componentDidMount = () => {
    if( typeof window === 'undefined' ){
      return;
    }


    this.setState(
      {
        oauthState: `${window.location.origin}-${randomString()}`
      },
      () => window.addEventListener('message', this.onMessage)
    );
  }

  componentWillUnmount = () => {
    if( typeof window === 'undefined' ){
      return;
    }

    window.addEventListener('message', this.onMessage);
  }


  onMessage = messageEvent => {
    const { oauthState, googleCode } = messageEvent.data;
    if (oauthState !== this.state.oauthState) {
      return;
    }

    log('messageEvent', messageEvent);

    const callback = () => this.setState({ myBusinessDialogOpen: true, isProcessingGoogleAccount: false });
    const errorCallback = () => this.setState({ isProcessingGoogleAccount: false });

    this.setState(
      {
        isProcessingGoogleAccount: true
      },
      () => this.props.onCode(googleCode, callback, errorCallback)
    );
  }


  // Google oauth login popup
  startGoogleOauth = () => {

    if (
      typeof window === 'undefined' ||
      !this.props.canSignIn
    ) {
      return;
    }

    let uriBase;

    if (isDevelopment()) {
      uriBase = window.location.origin;
    } else if (isDemo()) {
      uriBase = 'https://demo.goboost.io';
    }  else if (isStaging()) {
      uriBase = 'https://staging.goboost.io';
    } else {
      uriBase = 'https://bots.goboost.io';
    }

    const googleOauthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

    const params = {
      client_id: this.props.clientId,
      redirect_uri: `${ uriBase }${ this.props.googleRedirectUrl }`,
      scope: 'profile email https://www.googleapis.com/auth/plus.business.manage',
      access_type: 'offline',
      response_type: 'code',
      prompt: 'consent',
      state: this.state.oauthState
    };

    const popup = window.open(
      googleOauthUrl + '?' + stringifyParamsObject(params),
      'Login to Google',
      'height=600,width=400'
    );

    if (window.focus) {
      popup.focus();
    }
  }


  onSelectAccount = (account) => {
    const callback = () => {
      this.setState({ myBusinessDialogOpen: false, locationsDialogOpen: true })
    };

    this.props.onSelectAccount(account,callback);
  }


  onSelectLocation = (location) => {
    this.props.onSelectLocation(location);
    this.setState({ locationsDialogOpen: false });
  }


  tryAgain = e => {
    e.preventDefault();
    if (this.props.canSignIn){
      this.setState({ myBusinessDialogOpen: true });
    }
  }


  closeLocationsDialog = () => {
    this.setState({ locationsDialogOpen: false });
  }

  closeMyBusinessDialog = () => {
    this.setState({ myBusinessDialogOpen: false });
  }


  openConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: true });
  }


  closeConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: false });
  }


  onSignOut = () => {
    if ( !this.props.canSignOut ) {
      return;
    }

    this.setState(
      {
        confirmationDialogOpen: false,
        isProcessingGoogleAccount: true
      },
      this.props.signOut
    );
  }


  tryAgain = e => {
    e.preventDefault();
    if (this.props.canSignIn) {
      this.setState({ locationsDialogOpen: true });
    }
  }


  render() {
    return (
      <GoogleAuthContext.Provider
        value={{
          myBusinessDialogOpen: this.state.myBusinessDialogOpen,
          locationsDialogOpen: this.state.locationsDialogOpen,
          confirmationDialogOpen: this.state.confirmationDialogOpen,
          startGoogleOauth: this.startGoogleOauth,
          onSelectAccount: this.onSelectAccount,
          onSelectLocation: this.onSelectLocation,
          tryAgain: this.tryAgain,
          closeLocationsDialog: this.closeLocationsDialog,
          closeMyBusinessDialog: this.closeMyBusinessDialog,
          openConfirmationDialog: this.openConfirmationDialog,
          closeConfirmationDialog: this.closeConfirmationDialog,
          onSignOut: this.onSignOut,
          canSignIn: this.props.canSignIn,
          canSignOut: this.props.canSignOut,
          isProcessingGoogleAccount: this.state.isProcessingGoogleAccount,
        }}
      >
        {this.props.children}
      </GoogleAuthContext.Provider>
    );
  }
}

GoogleAuthProvider.defaultProps = {
  onCode: function(){},
  canSignIn: false,
  clientId: '',
  googleRedirectUrl: '/auth/facebook/callback',
  onSelectAccount: function() {},
  onSelectLocation: function() {},
  signOut: function(){},
  canSignOut: false,
}


export default withContext(SnackbarContext, GoogleAuthProvider);