import React from 'react';
import classnames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiAppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';

import drawerWidth from './drawerWidth';

class AppBar extends React.Component {

  state = {
    childAnchorEls: {},
    mobileAnchorEl: null,
  }


  handleMobileMenuOpen = e => {
    this.setState({ mobileAnchorEl: e.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileAnchorEl: null });
  };

  handleChildMenuOpen = (e, menuItemText) => {
    const childAnchorEls = { ...this.state.childAnchorEls, [menuItemText]:  e.currentTarget };
    this.setState({ childAnchorEls });
  };

  handleChildMenuClose = menuItemText => {
    const childAnchorEls = { ...this.state.childAnchorEls, [menuItemText]: null };
    this.setState({ childAnchorEls });
    this.handleMobileMenuClose(menuItemText);
  };

  mobileMenuItem = m => (
    <MenuItem
      key={m.primaryText}
      onClick={this.menuItemClick(m)}
    >
      { this.desktopMenuItem(m, false, false) }
      <ListItemText primary={ m.primaryText } secondary={m.secondaryText} />
    </MenuItem>
  )


  desktopMenuItem = (m, bindClick = true, tooltip = true) => {
    const desktopMenuItem = (
      <IconButton
        key={m.primaryText}
        color={m.color || 'inherit'}
        component={m.component || 'button'}
        to={m.to || undefined}
        onClick={ bindClick && typeof m.onClick === 'function' ? this.menuItemClick(m) : undefined }
      >
        {
          m.badgeContent ?
            <Badge badgeContent={ m.badgeContent} color="secondary">
              { m.icon }
            </Badge>
          :
            m.icon
        }
      </IconButton>
    )
    return tooltip && m.tooltipTitle ?
      <Tooltip key={m.primaryText} title={m.tooltipTitle}>{desktopMenuItem}</Tooltip>
    :
      desktopMenuItem;
  }


  menuItemClick = m => {
    if(m.links) {
      return e => this.handleChildMenuOpen(e, m.primaryText);
    } else {
      return () => {
        m.onClick();
        this.handleMobileMenuClose();
      };
    }
  }


  renderChildMenus = () => {
    const { childAnchorEls } = this.state;

    const menus = this.props.menuItems.filter(m => m.links);
    return menus.map(menu => {
      const isMenuOpen = Boolean(childAnchorEls[menu.primaryText]);

      return (
        <Menu
          key={menu.primaryText}
          anchorEl={childAnchorEls[menu.primaryText]}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={() => this.handleChildMenuClose(menu.primaryText)}
        >
          {
            menu.links.map(c => (
              <MenuItem
                key={c.primaryText}
                onClick={e => {
                  this.handleChildMenuClose(menu.primaryText);
                  c.onClick();
                }}>
                {c.primaryText}
              </MenuItem>
            ))
          }
        </Menu>
      );
    })
  }


  render(){
    const { classes, collapseMobile } = this.props;
    const isMobileMenuOpen = Boolean(this.state.mobileAnchorEl);

    const mobileMenu = collapseMobile ?
      (
        <Menu
          anchorEl={this.state.mobileAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
        >
          { this.props.menuItems.map(m => this.mobileMenuItem(m)) }
        </Menu>
      ): null;

    const childMenus = this.renderChildMenus();

    return (
      <div className={classes.root}>
        <MuiAppBar
          position="fixed"
          className={classnames(classes.appBar, this.props.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.props.open}>

            <Tooltip title="Navigation Menu" id='nav-menu'>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.props.openDrawer}
                className={classnames(classes.menuButton, this.props.open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>

            <Typography variant="h6" color="inherit" noWrap>
              { this.props.title }
            </Typography>
            {
              this.props.subtitle ?
                (
                  <Typography variant="caption" color="textSecondary">
                    {
                      this.props.subtitle
                    }
                  </Typography>
                )
              :
                null
            }


            <div className={classes.grow} />
            <div className={collapseMobile ? classes.sectionDesktop : classes.sectionNoCollapseMobile}>
              { this.props.menuItems.map(m => this.desktopMenuItem(m)) }
            </div>

            {
              collapseMobile ?
                <div className={classes.sectionMobile}>
                  <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                    <MoreIcon />
                  </IconButton>
                </div>
              : null
            }

          </Toolbar>
        </MuiAppBar>

        { childMenus }
        { mobileMenu }
      </div>
    );
  }
}

AppBar.defaultProps = {
  menuItems: [],
  collapseMobile: false,
};


const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 5,
    marginRight: 5,
  },
  hide: {
    display: 'none',
  },
  sectionNoCollapseMobile: {
    display: 'flex',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});


export default withStyles(styles)(AppBar);