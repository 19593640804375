import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import CallChip from '../KnChips/CallChip';
import NewChip from '../KnChips/NewChip';
import FormChip from '../KnChips/FormChip';
import ReviewedChip from '../KnChips/ReviewedChip';
import CallCard from '../CallCard/CallCard';
import { format } from 'kn-react';
import FormCard from '../FormCard/FormCard';

class OrganizationOpportunityRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false
		};
	}

	toggleExpand = () => {
		this.setState({ expanded: !this.state.expanded });
	}

	isCall = opportunity => {
		if (opportunity.opportunityType && opportunity.opportunityType.toLowerCase() === 'call') {
			return true
		}
		return false
	}

	render() {

		var { classes, opportunity, name } = this.props;

		if (opportunity.opportunityType) {
			name = (this.isCall(opportunity)) ? opportunity.callerName : opportunity.name;
		}

		let date;
		if (opportunity.createdAt) {
			date = (typeof opportunity.createdAt == 'object') ? format.date(opportunity.createdAt) : opportunity.createdAt
		} else {
			return null
		}

		return (
			<TableBody>
					<TableRow>
						<TableCell>
								{opportunity.companyName}
						</TableCell>
					<TableCell>
						{name}
					</TableCell>
					<TableCell>
						{date}
					</TableCell>
					{/* <TableCell>
						{
							(opportunity.reviewedByUserId) ? <ReviewedChip /> : <NewChip />
						}
					</TableCell> */}
					<TableCell>
						{
							(this.isCall(opportunity)) ? <CallChip /> : <FormChip />
						}
					</TableCell>
					<TableCell>
						{
							<IconButton className={classes.iconButton} onClick={this.toggleExpand}>
								{
									(!this.state.expanded) ? <AddCircleOutline /> : <RemoveCircleOutline />
								}
							</IconButton>
						}
					</TableCell>
				</TableRow>
				{
					!this.isCall(opportunity) || !this.state.expanded ? null :
						<TableRow>
							<TableCell className={classes.opportunityCell} colSpan={6}>
								<CallCard
									audioUrl={opportunity.callRecordingUrl}
									mainColor={this.props.theme.palette.primary.main}
									call={opportunity}
									align='center'
								/>
							</TableCell>
						</TableRow>
				}

				{
					this.isCall(opportunity) || !this.state.expanded ? null :
						<TableRow>
							<TableCell className={classes.opportunityCell} colSpan={6}>
								<FormCard
									mainColor={this.props.theme.palette.primary.main}
									opportunity={opportunity}
									align='center'
								/>
							</TableCell>
						</TableRow>
				}
			</TableBody>
		);
	}
}

const styles = theme => ({
	iconButton: {
		color: theme.palette.primary.main
	},
	opportunityCell: {
		padding: 0,
		paddingRight: '0px !important',
	},
})

OrganizationOpportunityRow.defaultProps = {
	opportunity: {
		createdAt: null,
		reviewedByUserId: null,
		opportunityType: null,
		callRecordingUrl: null,
		callerName: null,
		callerNumber: null,
		callStatus: null,
		duration: null,
        name: null,
        companyName: null
	},
    name: null,
}

export default withStyles(styles, { withTheme: true })(OrganizationOpportunityRow);