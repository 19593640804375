import React from 'react';

import { SvgIcon } from '@material-ui/core';


const noIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <path fill="none" stroke={props.fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 6L6 18M6 6l12 12"/>
  </SvgIcon>
);


noIcon.defaultProps = {
  fill: '#5E5F64'
}


export default noIcon;