import React from 'react';

import { SvgIcon } from '@material-ui/core';


const contractIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M14 3v4a1 1 0 0 0 1 1h4" stroke={props.fill}/>
      <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM9 9h1m-1 4h6m-6 4h6" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


contractIcon.defaultProps = {
  fill: '#5E5F64'
}


export default contractIcon;