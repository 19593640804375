import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format, JsConvertCase } from 'kn-react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import withStyles from '@material-ui/core/styles/withStyles';

import PromiseButton from '../PromiseButton/PromiseButton';


const formCard = props => {
  const { classes, opportunity } = props;
  const fields = [
    { label: 'Email', key: 'email'},
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Message', key: 'message'}
  ];


  const existingFields = [
    'name',
    'phone_number',
    'email',
    'message'
  ];

  let additionalFields = [];
  if(
    opportunity.formData &&
    opportunity.formData.fields
  ){
    additionalFields = opportunity.formData.fields.filter(
      f => !existingFields.includes( f.name )
    ).map(
      f => ({
        label: f.name,
        value: f.value
      })
    );
  }


  return (
    <div className={classes.mainPaperStyle}>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <List>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ color: 'primary' }} primary='Form Info:' />
                </ListItem>
              </Grid>

              {
                fields.map((f, i) => {
                  return (
                    <Grid item xs={12} md={4} key={i}>
                      <ListSubheader className={classes.subheaderStyle}>
                        { f.label }
                      </ListSubheader>
                      <ListItem className={classes.listItemStyle}>
                        <ListItemText primary={ opportunity[f.key] || 'Not provided' } />
                      </ListItem>
                    </Grid>
                  );
                })
              }

              {
                additionalFields && additionalFields.length ?
                  <React.Fragment>
                    <Grid item xs={12}>
                      <ListItem>
                        <ListItemText primaryTypographyProps={{ color: 'primary' }} primary='Additional Info:' />
                      </ListItem>
                    </Grid>

                    {
                      additionalFields.map(
                        (f, i) => (
                          <Grid item xs={12} md={4} key={i}>
                            <ListSubheader className={classes.subheaderStyle}>
                              { f.label }
                            </ListSubheader>
                            <ListItem className={classes.listItemStyle}>
                              <ListItemText primary={ f.value || 'Not provided' } />
                            </ListItem>
                          </Grid>
                        )
                      )
                    }
                  </React.Fragment>
                :
                  null
              }
            </Grid>
          </List>
        </Grid>

        {
          !props.showMarkAsSpam ?
            null
          :
            <Grid
              item
              xs={12}
              className={classes.button}
            >
              <PromiseButton
                onProcess={props.onProcessSpam}
                buttonProps={{
                  variant: 'outlined',
                  size: 'small',
                  color: 'secondary'
                }}
              >
                Mark as spam
              </PromiseButton>
            </Grid>
        }

        {
          !(
            ( props.showViewOpportunity && props.viewOpportunityRoute ) ||
            ( props.showViewOpportunity && props.onClickViewOpportunity )
          ) ?
            null
          :
            <Grid
              item
              xs={12}
              className={classes.button}
            >
              <Button
                component={props.onClickViewOpportunity ? Button : RouterLink}
                to={props.onClickViewOpportunity ? undefined : props.viewOpportunityRoute( opportunity )}
                onClick={props.onClickViewOpportunity ? () => props.onClickViewOpportunity( opportunity ) : undefined}
                variant={'contained'}
                color={'primary'}
              >
                View
              </Button>
            </Grid>
        }
      </Grid>
    </div>
  );
}


formCard.defaultProps = {
  onProcessSpam: () => Promise.resolve(),
  showMarkAsSpam: false,
  showViewOpportunity: false,
  viewOpportunityRoute: () => '#',
  onClickViewOpportunity: () => '#',
  additionalFields: null
}


const styles = theme => ({
  subheaderStyle: {
    float: 'left'
  },
  listItemStyle: {
    paddingTop: 0
  },
  textFieldStyle: {
    float: 'left'
  },
  mainPaperStyle: {
    backgroundColor: '#fafafa',
    padding: 24,
  },
  button: {
    paddingLeft: 16,
    paddingTop: 15
  },
});

export default withStyles(styles)(formCard);