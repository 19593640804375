import React from 'react';
import Link from 'react-router-dom/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import isLinkActive from '../../isLinkActive';


const appDrawerLink = props => {
    const {
      primaryText,
      onClick,
      path,
      classes,
      exactPathMatch,
      icon,
      secondaryText,
      secondaryAction,
      dense,
    } = props;


    const isActive = isLinkActive(props);

    let listItemProps = {
      dense,
    };
    if (onClick) {
      listItemProps = {
        ...listItemProps,
        button: true,
        onClick,
      };
    } else if(path) {
      listItemProps = {
        ...listItemProps,
        button: true,
        component: Link,
        to: path,
      }
    }


    return (
      <ListItem
        {...listItemProps}
      >
        {
          icon ?
            <ListItemIcon classes={ isActive ? { root: classes.activeListItemIcon } : null }>
              { icon }
            </ListItemIcon>
          : null
        }

        <ListItemText
          classes={ isActive ? { primary: classes.activeListItemText, root:  classes.rootListItemText } : { root: classes.rootListItemText } }
          primary={primaryText}
          secondary={secondaryText}
        />
        {
          secondaryAction ?
            <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
          : null
        }
      </ListItem>
  );

}


const styles = theme => ({
  activeListItemIcon: {
    color: theme.palette.primary.main,
  },
  rootListItemText: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  activeListItemText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
});


export default withStyles(styles)(appDrawerLink);
