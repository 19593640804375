import React from 'react';

import { SvgIcon } from '@material-ui/core';


// https://icon-sets.iconify.design/tabler/page-44.html
const homeEnergyIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="m19 10l-7-7l-9 9h2v7a2 2 0 0 0 2 2h7.5" stroke={props.fill}/>
      <path d="M9 21v-6a2 2 0 0 1 2-2h2c.661 0 1.248.32 1.612.815M19 14l-2 4h4l-2 4" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


homeEnergyIcon.defaultProps = {
  fill: '#5E5F64'
}


export default homeEnergyIcon;