import React from 'react';

import { SvgIcon } from '@material-ui/core';


const airConditioningIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <path fill="none" stroke={props.fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m11.414 10l-7.383 7.418a2.091 2.091 0 0 0 0 2.967a2.11 2.11 0 0 0 2.976 0L14.414 13m3.707 2.293l2.586-2.586a1 1 0 0 0 0-1.414l-7.586-7.586a1 1 0 0 0-1.414 0L9.121 6.293a1 1 0 0 0 0 1.414l7.586 7.586a1 1 0 0 0 1.414 0z"/>
  </SvgIcon>
);


airConditioningIcon.defaultProps = {
  fill: '#5E5F64'
}


export default airConditioningIcon;