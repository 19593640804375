import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';



class CompanyBoostedService extends React.Component {

  state = {

  }

  render(){
    return (
      <List>
         <ListItem
            key={`company_boosted_service_${c.id}`}
          >
            <ListItemAvatar>
              <BoostedServiceIcon
                channels={channels}
                boostedService={b}
              />
            </ListItemAvatar>

            <ListItemText
              primary={b.name}
              secondary={secondaryInfo}
            />

            <ListItemSecondaryAction>

              <Toolbar>
                {
                  b.channelId == facebookChannel.id || b.surveyId ?
                    <Tooltip title={<div>Edit Boosted Service Settings</div>} enterDelay={500}>
                      <IconButton onClick={() => props.onClickEditSurvey(b)}>
                        <Settings />
                      </IconButton>
                    </Tooltip>
                    : null
                }

                <Tooltip title={<div>Edit your Boost Zone</div>} enterDelay={500}>
                  <IconButton onClick={() => props.onClickEditBoostZone(b)}>
                    <Map />
                  </IconButton>
                </Tooltip>


                <Tooltip title={<div>Edit your Boost Strategy</div>} enterDelay={500}>
                  <IconButton onClick={() => props.onClickEditBoostStrategy(b)}>
                    <AttachMoney />
                  </IconButton>
                </Tooltip>


                {
                  props.onToggle ?
                    <Tooltip title="Turn your ads on or off" enterDelay={500}>
                      <Switch
                        onChange={e => props.onToggle(e, c)}
                        checked={c.isActivated}
                        color="primary"
                      />
                    </Tooltip>
                    : null
                }
              </Toolbar>
            </ListItemSecondaryAction>
          </ListItem>
      </List>
    );
  }

}

export default CompanyBoostedService;