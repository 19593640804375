import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { format } from 'kn-react';


class ReviewRequestListItem extends React.PureComponent {
  render() {
    //Clipping message if longer than the passed maxChars
    const formattedMessage = (
      (this.props.maxChars && this.props.request.message && this.props.maxChars < this.props.request.message.length) ?
        this.props.request.message.substring(0,this.props.maxChars)+'...'
      :
        this.props.request.message
    );

    return (
      <React.Fragment>
        <ListItem
          button={ true }
          component={ Link }
          to={`/reviews/review_requests/`.concat(this.props.request.id)}
        >
            <ListItemText
              primary= {
                <ListItemText
                  primary= {
                    <ListItemText
                    primary= {(`
                      ${ this.props.request.fullName || '' }
                      ${ (this.props.request.fullName && this.props.request.email) ? '|' : '' }
                      ${ this.props.request.email || '' }
                    `)}
                    secondary={(`
                      ${ format.dateTime((this.props.request.createdAt) ? new Date(this.props.request.createdAt) : new Date()) }
                      |
                      ${ (this.props.request.isClicked) ? "Clicked" : "Not Clicked"}
                    `)}
                    primaryTypographyProps = {{
                      variant:'body2'
                    }}
                    secondaryTypographyProps = {{
                      variant:'caption'
                    }}
                    />
                  }
                  secondary= {`Requested by: ${ this.props.request.requestorFirstName || '' } ${ this.props.request.requestorLastName ||  '' }`}
                  primaryTypographyProps = {{
                    variant:'body2'
                  }}
                  secondaryTypographyProps = {{
                    variant:'caption'
                  }}
                />
              }
              secondary = {formattedMessage}
              secondaryTypographyProps = {{
                color:'textPrimary',
                variant:'body1'
              }}
            />
        </ListItem>
      </React.Fragment>
    );
  }
}

ReviewRequestListItem.defaultProps = {
  request: {
  },
  maxChars: undefined,
}

const styles = theme => ({
  iconButtonRoot: {
    padding: 2,
    color: 'lightgray'
  },

})

export default withStyles(styles)(ReviewRequestListItem);