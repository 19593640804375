import React from 'react';
import axios from 'axios';
import { log, withContext } from 'kn-react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarContext } from '../Snackbar/SnackbarProvider'

class GoogleAuthLocationsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      locations: [],
      hasNoLocations: false
    }
  }

  componentDidMount() {
    this.getGoogleLocations();
  }

  getGoogleLocations() {
    const errorCallback = () => {
      this.setState({ loading: false, hasNoLocations: true}, this.props.onClose);
    }

    return this.props.getGoogleLocationsCallback(
      errorCallback
    )
    .then(locations => {

      if (!locations || !locations.length) {
        return this.setState(
          {
            loading: false,
            hasNoLocations: true
          }
        );
      }

      if (locations.length === 1) {
        this.onSelectLocation(locations[0]);
      } else {
        this.setState({
          locations: locations,
          loading: false
        });
      }
    });
  }

  onSelectLocation = (location) => {
    this.props.onSelectLocation(location);
    this.props.onClose();
  }


  hasNoLocations() {
    return (
      <div className={this.props.classes.noLocations}>
        <h2>We couldn't find any Google My Business Locations</h2>

        <Link className="btn btn-lg btn-primary" href="https://www.google.com/business/" target="_blank" rel="noopener noreferrer">
          Click here to get started
        </Link>
      </div>
    );
  }


  render(){
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.dialogOpen}
        onClose={e => this.props.onClose()}
        disableBackdropClick={!this.state.hasNoLocations}
        disableEscapeKeyDown={!this.state.hasNoLocations}
      >
        <DialogContent className={classes.dialog}>
          {
            this.state.loading ?
              <CircularProgress />
            :
              this.state.hasNoLocations ?
                this.hasNoLocations()
              :
                <React.Fragment>
                  <h3>Please Select The Location You Want Us To Monitor</h3>

                  <List>
                    {
                      this.state.locations.map(location => (
                        <ListItem
                          button
                          key={location.name}
                          onClick={() => this.onSelectLocation(location)}
                        >
                          <ListItemText
                            primary={location.title}
                            secondary={
                              location.storefrontAddress && location.storefrontAddress.addressLines && location.storefrontAddress.addressLines.length ? location.storefrontAddress.addressLines[0] : null
                            }
                          />
                        </ListItem>
                      ))
                    }
                  </List>
                </React.Fragment>
          }
        </DialogContent>
      </Dialog>
    );
  }
}

GoogleAuthLocationsModal.defaultProps = {
  onClose: function() { },
  getGoogleLocationsCallback: function() {},
  onSelectLocation: function() { },
  dialogOpen: false,
}

const styles = theme => ({
  dialog: {
    textAlign: 'center'
  },
  noLocations: {
    textAlign: 'center'
  }
})

export default withContext(SnackbarContext, withStyles(styles)(GoogleAuthLocationsModal))