import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';

class ViewSourceChip extends React.PureComponent {
	render() {

		const { classes } = this.props;

		return (
			<Chip
				color='primary'
				label={ this.props.label || 'View Source' }
				classes={{ colorPrimary: classes.chipClass }}
			/>
		);
	}
}

const styles = theme => ({
	chipClass: {
		borderRadius: 3,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
		backgroundColor: '#5a7af9',
		color: '#fff'
	}
});


export default withStyles(styles, { withTheme: true })(ViewSourceChip);