import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
//fillRule="evenodd"
//  width={props.width || 1024}
export default props => (
  <SvgIcon className={props.className}
  xmlns="http://www.w3.org/2000/svg"
  x="0px" y="0px"
  viewBox="128 140 1028 1028"

  >
    <g fillRule="evenodd">
      <path d="M707,1024V628H840.5l20-155H707V374.28c0-44.77,12.43-75.28,76.63-75.28L865,299V160.74c-14.16-1.88-62.73-6.09-119.24-6.09-118,0-198.76,72-198.76,204.28V473H414V628H547v396Z"/>
    </g>
  </SvgIcon>
);
