import React from 'react';
import { log } from 'kn-react';

import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import TableToolbar from '../TableToolbar/TableToolbar';
import OpportunityRow from '../OpportunityRow/OpportunityRow';
import SmallTablePagination from '../SmallTablePagination/SmallTablePagination';

import { minPadding } from './opportunityTableConstants'


class OpportunitiesTable extends React.Component {

	render() {
		const { total, opportunities, loading, classes } = this.props;

		return (
			<React.Fragment>
				<TableToolbar
					title={`${total} ${ this.props.opportunityTableTitle }`}
					actions={this.props.actions}
				/>

				{loading ? <LinearProgress /> : null}

				{
					(!opportunities.length) ?
						<Typography variant="caption" className={classes.noData}>No { this.props.opportunityTableTitle }.</Typography>
					:
						<div className={classes.tableWrapper}>
							<Table className={classes.table} style={{ opacity: loading ? 0.5 : 1 }}>
								<TableHead>
									<TableRow>
										{
											(this.props.showCompanyName) ?
												<TableCell
													className={classes.cell}
													style={{
														paddingLeft: minPadding
													}}
												>
													Company
												</TableCell>
											:
												null
										}

										<TableCell
											className={classes.cell}
											style={{
												paddingLeft: this.props.showCompanyName ? undefined : minPadding
											}}
										>
											Person's Name
										</TableCell>

										<TableCell className={classes.cell}>
											Date
										</TableCell>

										<TableCell className={classes.cell}>
											Type
										</TableCell>

										<TableCell />
									</TableRow>
								</TableHead>

								{
									opportunities.map(o => {
										return (
											<OpportunityRow
												key={o.id}
												opportunity={o}
												showCompanyName={this.props.showCompanyName}
												onProcessSpam={() => this.props.onProcessSpam( o.id )}
												showMarkAsSpam={this.props.showMarkAsSpam}
												showViewOpportunity={this.props.showViewOpportunity}
												viewOpportunityRoute={this.props.viewOpportunityRoute}
												onClickViewOpportunity={this.props.onClickViewOpportunity}
											/>
										)
									})
								}

							</Table>
						</div>
				}

				<SmallTablePagination
					count={total || 0}
					rowsPerPage={this.props.rowsPerPage}
					page={this.props.page}
					onChangeRowsPerPage={e => this.props.changeRowsPerPage(e.target.value)}
					onChangePage={(e, p) => this.props.changePage(p)}
					rowsPerPageOptions={this.props.rowsPerPageOptions}
				>
					{
						this.props.isSpamFiltered ?
							<TableCell className={classes.spamWarningWrapper}>
								<Typography variant='caption' className={classes.spamWarning}>
									*Spam { this.props.opportunityTableTitle.toLowerCase() } are filtered out by default.
								</Typography>
							</TableCell>
						:
							null
					}
				</SmallTablePagination>

			</React.Fragment>
		);
	}

}


const styles = theme => ({
	noData: {
		textAlign: 'center',
		padding: 25,
		color: '#777',
	},
	tableWrapper: {
		overflowX: 'auto'
	},
	table: {
		minWidth: 700
	},
	spamWarning: {
		color: 'darkgrey'
	},
	spamWarningWrapper: {
		paddingLeft: 24,
		paddingTop: 16
	}
})


OpportunitiesTable.defaultProps = {
	total: 0,
	opportunities: [],
	loading: true,
	rowsPerPage: 50,
	page: 0,
	rowsPerPageOptions: [50],
	changePage: p => {
		log('page', p)
	},
	changeRowsPerPage: rowsPerPage => {
		log('rows per page', rowsPerPage)
	},
	showCompanyName: false,
	actions: null,
	onProcessSpam: () => Promise.resolve(),
	showMarkAsSpam: false,
	showViewOpportunity: false,
  viewOpportunityRoute: () => '#',
	onClickViewOpportunity: null,
	opportunityTableTitle: 'Opportunities'
}


export default withStyles(styles)(OpportunitiesTable);