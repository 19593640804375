import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 70 70">
    <g fillRule="evenodd">
      <path fillRule="nonzero" d="M18.75 13.374h-3c-2.992 0-5.506 2.017-6.389 4.783C4.056 19.081 0 23.809 0 29.5s4.056 10.42 9.361 11.343c.883 2.766 3.397 4.783 6.389 4.783h3c2.72 0 5.487.133 8.25.374V13c-2.763.24-5.53.374-8.25.374zM9 36.017c-2.62-.951-4.5-3.514-4.5-6.517 0-3.004 1.88-5.566 4.5-6.517v13.034z" />
      <path d="M30.904 61.834L29.23 51.64a96.621 96.621 0 0 0-10.648-.616h-3.116c-.157 0-.31-.018-.466-.024l2.178 13.07c.572 3.436 3.479 5.93 6.911 5.93 4.27 0 7.517-3.896 6.815-8.166zM65.5 0C63.015 0 61 2.066 61 4.615v50.77C61 57.934 63.015 60 65.5 60s4.5-2.066 4.5-4.615V4.615C70 2.066 67.985 0 65.5 0zM32 12.4v34.2c11.158 1.435 16.829 3.63 25 6.4V6c-8.112 2.75-13.796 4.96-25 6.4z" />
    </g>
  </SvgIcon>
);