import React from 'react';
import Ability from '../Ability/Ability';

const DEFAULT_STATE = {
  subscribedPlans: [],

  isAdsAvailable: false,
  isReviewsAvailable: false,
  isSitesAvailable: false,
  isSocialAvailable: false,
  isVirtualAvailable: false,

  subscribedAdsPlan: null,
  subscribedReviewsPlan: null,
  subscribedSitesPlan: null,
  subscribedSocialPlan: null,
  subscribedVirtualPlan: null,
  subscribedAnnualDomainPlan: null,

  isSubscribedToAds: false,
  isSubscribedToReviews: false,
  isSubscribedToSites: false,
  isSubscribedToSocial: false,
  isSubscribedToVirtual: false,
  hasAnnualDomainSubscription: false,

  activeAdsSubscription: null,
  activeReviewsSubscription: null,
  activeSitesSubscription: null,
  activeSocialSubscription: null,
  activeVirtualSubscription: null,

  availableServices: [],

  upgradableAdsPlans: [],
  upgradableReviewsPlans: [],
  upgradableSitesPlans: [],
  upgradableSocialPlans: [],
  upgradableVirtualPlans: [],
};


export const PlanContext = React.createContext(DEFAULT_STATE);


class PlanProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.computePlanState();
  }



  componentDidUpdate = oldProps => {
    if (
      this.props.plans !== oldProps.plans ||
      this.props.subscriptions !== oldProps.subscriptions ||
      this.props.annualDomainPlan !== oldProps.annualDomainPlan
    ){
      this.computePlanState();
    }
  }



  computePlanState = () => {
    const { plans, subscriptions, annualDomainPlan } = this.props;

    // Organizations don't have subscriptions, so default to an empty array
    const subscriptionsToEvaluate = subscriptions || [];

    const annualDomainSubscription = subscriptionsToEvaluate.find(s => (
      annualDomainPlan &&
      s.planId === annualDomainPlan.id
    ));

    const subscribedPlans = (subscriptions || []).map(s => plans.find(p => p.id === s.planId)).filter(s => s);

    const subscribedAdsPlan = subscribedPlans.find(p => p.isAdsIncluded);
    const subscribedReviewsPlan = subscribedPlans.find(p => p.isReviewsIncluded);
    const subscribedSitesPlan = subscribedPlans.find(p => p.isSitesIncluded);
    const subscribedSocialPlan = subscribedPlans.find(p => p.isSocialIncluded);
    const subscribedVirtualPlan = subscribedPlans.find(p => p.isVirtualIncluded);
    const subscribedAnnualDomainPlan = (
      annualDomainSubscription && annualDomainPlan ?
        annualDomainPlan
      :
        null
    );

    const isSubscribedToAds = !!subscribedAdsPlan;
    const isSubscribedToReviews = !!subscribedReviewsPlan;
    const isSubscribedToSites = !!subscribedSitesPlan;
    const isSubscribedToSocial = !!subscribedSocialPlan;
    const isSubscribedToVirtual = !!subscribedVirtualPlan;
    const hasAnnualDomainSubscription = !!subscribedAnnualDomainPlan;

    const activeAdsSubscription = subscriptionsToEvaluate.find(s => subscribedAdsPlan && subscribedAdsPlan.id === s.planId);
    const activeReviewsSubscription = subscriptionsToEvaluate.find(s => subscribedReviewsPlan && subscribedReviewsPlan.id === s.planId);
    const activeSitesSubscription = subscriptionsToEvaluate.find(s => subscribedSitesPlan && subscribedSitesPlan.id === s.planId);
    const activeSocialSubscription = subscriptionsToEvaluate.find(s => subscribedSocialPlan && subscribedSocialPlan.id === s.planId);
    const activeVirtualSubscription = subscriptionsToEvaluate.find(s => subscribedVirtualPlan && subscribedVirtualPlan.id === s.planId);

    this.setState({
      subscribedPlans,

      subscribedAdsPlan,
      subscribedReviewsPlan,
      subscribedSitesPlan,
      subscribedSocialPlan,
      subscribedVirtualPlan,
      subscribedAnnualDomainPlan,

      isSubscribedToAds,
      isSubscribedToReviews,
      isSubscribedToSites,
      isSubscribedToSocial,
      isSubscribedToVirtual,
      hasAnnualDomainSubscription,

      activeAdsSubscription,
      activeReviewsSubscription,
      activeSitesSubscription,
      activeSocialSubscription,
      activeVirtualSubscription,

      isAdsAvailable: this.getIsAdsAvailable(),
      isReviewsAvailable: this.getIsReviewsAvailable(),
      isSitesAvailable: this.getIsSitesAvailable(),
      isSocialAvailable: this.getIsSocialAvailable(),
      isVirtualAvailable: this.getIsVirtualAvailable(),

      availableServices: this.getAvailableServices(),

      upgradableAdsPlans: this.upgradableProductPlans('isAdsIncluded', subscribedAdsPlan),
      upgradableReviewsPlans: this.upgradableProductPlans('isReviewsIncluded', subscribedReviewsPlan),
      upgradableSitesPlans: this.upgradableProductPlans('isSitesIncluded', subscribedSitesPlan),
      upgradableSocialPlans: this.upgradableProductPlans('isSocialIncluded', subscribedSocialPlan),
      upgradableVirtualPlans: this.upgradableProductPlans('isVirtualIncluded', subscribedVirtualPlan),
    });
  }


  getIsAdsAvailable = () => {
    const { currentUserRole } = this.props;


    if(currentUserRole.isOrganizationRole()) {
      const hasPlan = this.props.familyPlans.filter(p => p.isAdsIncluded).length > 0;
      return hasPlan;
    } else {
      const hasPlan = this.props.plans.filter(p => p.isAdsIncluded).length > 0;
      return hasPlan && currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_COMPANY_ADS_PROFILE, Ability.EDIT_COMPANY_ADS_PROFILE);
    }
  }


  getIsReviewsAvailable = () => {
    const { currentUserRole } = this.props;

    if(currentUserRole.isOrganizationRole()) {
      const hasPlan = this.props.familyPlans.filter(p => p.isReviewsIncluded).length > 0;
      return hasPlan;
    } else {
      const hasPlan = this.props.plans.filter(p => p.isReviewsIncluded).length > 0;
      return hasPlan && currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_COMPANY_REVIEWS, Ability.EDIT_COMPANY_REVIEWS);
    }
  }


  getIsSitesAvailable = () => {
    const { currentUserRole } = this.props;

    if(currentUserRole.isOrganizationRole()) {
      const hasPlan = this.props.familyPlans.filter(p => p.isSitesIncluded).length > 0;
      return hasPlan;
    } else {
      const hasPlan = this.props.plans.filter(p => p.isSitesIncluded).length > 0;
      return hasPlan && currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_COMPANY_SITES_PROFILE, Ability.EDIT_COMPANY_SITES_PROFILE);
    }
  }


  getIsSocialAvailable = () => {
    const { currentUserRole } = this.props;

    if(currentUserRole.isOrganizationRole()) {
      const hasPlan = this.props.familyPlans.filter(p => p.isSocialIncluded).length > 0;
      return hasPlan;
    } else {
      const hasPlan = this.props.plans.filter(p => p.isSocialIncluded).length > 0;
      return hasPlan && currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_SOCIAL_COMPANY_SOCIAL_PROFILE, Ability.EDIT_SOCIAL_COMPANY_SOCIAL_PROFILE);
    }
  }


  getIsVirtualAvailable = () => {
    const { currentUserRole } = this.props;

    if(currentUserRole.isOrganizationRole()) {
      const hasPlan = this.props.familyPlans.filter(p => p.isVirtualIncluded).length > 0;
      return hasPlan;
    } else {
      const hasPlan = this.props.plans.filter(p => p.isVirtualIncluded).length > 0;
      return hasPlan && currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_VIRTUAL_COMPANY_VIRTUAL_PROFILE, Ability.EDIT_VIRTUAL_COMPANY_VIRTUAL_PROFILE);
    }
  }


  getAvailableServices = () => {
    const services = {
      isAdsIncluded: 'Ads',
      isSitesIncluded: 'Sites',
      isReviewsIncluded: 'Reviews',
      isSocialIncluded: 'Social',
      isVirtualAvailable: 'Virtual',
    };

    const availableServices = {};

    this.props.plans.forEach(p => {
      Object.keys(services).forEach(k => {
        if (p[k]) {
          availableServices[services[k]] = 1;
        }
      });
    })


    return Object.keys(availableServices);
  }



  upgradableProductPlans = (isProductIncludedKey, subscribedProductPlan) => {
    return this.props.plans.filter(p => (
      p[isProductIncludedKey]
      // &&
      // (subscribedProductPlan ? p.planAmountCents >= subscribedProductPlan.planAmountCents : true)
    ));
  }


  isSubscribedToPlanId = planId => {
    const { subscriptions } = this.props;
    return subscriptions.filter(s => s.planId == planId).length > 0;
  }



  render() {
    return (
      <PlanContext.Provider
        value={{
          ...this.state,
          computePlanState: this.computePlanState,
          isSubscribedToPlanId: this.isSubscribedToPlanId,
        }}

      >
        {this.props.children}
      </PlanContext.Provider>
    );
  }
}


export default PlanProvider;
