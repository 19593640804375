import React, { Component } from 'react'
import axios from 'axios';
import { log, withContext } from 'kn-react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarContext } from '../Snackbar/SnackbarProvider'



class GoogleAuthMyBusinessAccountsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      accounts: []
    }
  }

  componentDidMount() {
    this.getGoogleMyBusinessAccounts();
  }

  getGoogleMyBusinessAccounts() {
    const errorCallback = () => {
      this.setState({ loading: false, hasNoLocations: true}, this.props.onClose);
    }

    return this.props.getGoogleMyBusinessAccountsCallback(
      errorCallback
    )
    .then(accounts => {
      if (accounts.length === 1) {
        this.props.onSelectAccount(accounts[0]);
        this.props.onClose();
      } else {
        this.setState({
          accounts: accounts,
          loading: false
        });
      };
    });
  }

  onSelectAccount = (page) => {
    this.props.onSelectAccount(page);
    this.props.onClose();
  }


  render() {
    const { classes, dialogOpen } = this.props;

    return (
      <Dialog
        open={dialogOpen}
        onClose={e => this.props.onClose()}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogContent className={classes.dialog}>
          {
            this.state.loading ?
              <CircularProgress />
            :
              <React.Fragment>
                <h3>Please Select The Google My Business Account You Want Us To Monitor</h3>

                <List>
                  {
                    this.state.accounts.map(account => (
                      <ListItem
                        button
                        key={account.name}
                        onClick={() => this.onSelectAccount(account)}>
                        {account.accountName}
                      </ListItem>
                    ))
                  }
                </List>
              </React.Fragment>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

GoogleAuthMyBusinessAccountsModal.defaultProps = {
  onClose: function () {},
  getGoogleMyBusinessAccountsCallback: function() {},
  onSelectAccount: function () {},
  dialogOpen: false,
}

const styles = theme => ({
  dialog: {
    textAlign: 'center'
  }
})

export default withContext(
  SnackbarContext,
  withStyles(styles)(GoogleAuthMyBusinessAccountsModal)
);