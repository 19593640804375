import { JsonModel } from 'kn-react';

export default class OpportunityModel {

  static fromJSON(json){
    const model = new OpportunityModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON(){
    return JsonModel.modelToJSON(this);
  }

}


OpportunityModel.opportunityTypeDisplayName = {
  form_entry: 'Form',
  call: 'Call',
  call_click: 'Call Click',
}


OpportunityModel.NONCONTACTED_CONVERSION_STATUS = '';
OpportunityModel.CONTACTED_CONVERSION_STATUS = 'contacted';
OpportunityModel.CONVERTED_CONVERSION_STATUS = 'converted';
OpportunityModel.REJECTED_CONVERSION_STATUS = 'rejected';