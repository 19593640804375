import React from 'react';
import GoogleAuthProvider from './GoogleAuthProvider';
import GoogleAuthLocation from './GoogleAuthLocation';


const googlePageOauth = props => (
  <GoogleAuthProvider
    onCode={props.onCode}
    canSignIn={props.canSignIn}
    clientId={props.clientId}
    googleRedirectUrl={props.googleRedirectUrl}
    onSelectAccount={props.onSelectAccount}
    onSelectLocation={props.onSelectLocation}
    signOut={props.signOut}
    canSignOut={props.canSignOut}
  >
    <GoogleAuthLocation
      googleAccount={props.googleAccount}
      googleAccountLocation={props.googleAccountLocation}
      getGoogleMyBusinessAccountsCallback={props.getGoogleMyBusinessAccountsCallback}
      getGoogleLocationsCallback={props.getGoogleLocationsCallback}
      googleAccountLocationLoading={props.googleAccountLocationLoading}
    />
  </GoogleAuthProvider>
);

googlePageOauth.defaultProps = {
  onCode: function(){},
  canSignIn: true,
  clientId: '',
  googleRedirectUrl: '/auth/google/callback',
  onSelectAccount: function() {},
  onSelectLocation: function() {},
  signOut: function() {},
  canSignOut: true,
  googleAccount: {},
  googleAccountLocation: {},
  getGoogleMyBusinessAccountsCallback: function() {},
  getGoogleLocationsCallback: function() {},
}

export default googlePageOauth;