import React from 'react';

import Axios from 'axios';
import { withContext, log } from 'kn-react';

import { LinearProgress } from '@material-ui/core';

import { UserRoleContext } from '../UserRoles/UserRoleProvider'
import OrganizationModel from './OrganizationModel';
import PlanProvider from '../Plans/PlanProvider';
import PlanModel from '../Plans/PlanModel';



const DEFAULT_STATE = {
  organizationRoleContextLoading: true,
  organization: {},
  plans: [],
  familyPlans: []
};


export const OrganizationRoleContext = React.createContext(DEFAULT_STATE);

// When you switch roles, we need to load the properties of the role you just signed in as...

class OrganizationRoleProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }

  componentWillReceiveProps = nextProps => {
    if(nextProps.currentUserRole.id !== this.props.currentUserRole.id){
      this.setState({ organizationRoleContextLoading: true }, this.fetchData);
    }
  }

  fetchData = () => {
    this.fetchOrganization()
      .then(this.fetchOrganizationPlans)
      .finally(() => this.setState({ organizationRoleContextLoading: false }));
  }


  fetchOrganization = () => {
    const { currentUserRole } = this.props;

    let url = `/api/core/organizations/${ currentUserRole.roleTypeId }`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.get(
      url,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchOrganization', response)
      const organization = OrganizationModel.fromJSON(response.data.organization);
      this.setState({ organization });
    });
  }


  fetchOrganizationPlans = () => {
    return this.fetchPlans(
      false
    ).then(plans => {
      log('fetchOrganizationPlans plans',plans)
      this.setState({ plans })
    }).then(()=> this.fetchPlans(
      true
    )).then(familyPlans => {
      log('fetchOrganizationPlans familyPlans',familyPlans)
      this.setState({ familyPlans })
    });
    // const { currentUserRole } = this.props;
    // return Axios.get(
    //   `/api/core/organizations/${ currentUserRole.roleTypeId }/plans`,
    //   {
    //     headers: this.props.getUserRoleAuthHeaders(),
    //     params: {
    //       include_descendants: true
    //     }
    //   }
    // )
    // .then(response => {
    //   const plans = response.data.plans.map(p => PlanModel.fromJSON(p));
    //   log('fetchOrganizationPlans', plans);
    //   this.setState({ plans });
    // });
  }


  fetchPlans = (include_descendants) => {
    const { currentUserRole } = this.props;

    let url = `/api/core/organizations/${ currentUserRole.roleTypeId }/plans`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.get(
      url,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          include_descendants: (include_descendants) ? true : undefined
        }
      }
    ).then(response => {
      return response.data.plans.map(p => PlanModel.fromJSON(p))
    });
  }


  render(){
    const { organizationRoleContextLoading } = this.state;

    if( organizationRoleContextLoading && this.props.isLinearProgressDisabled ){
      return <React.Fragment></React.Fragment>;
    } else if( organizationRoleContextLoading ) {
      return <LinearProgress/>;
    }

    return (
      <OrganizationRoleContext.Provider
        value={{
          organization: this.state.organization,
          trueUserRole: this.props.trueUserRole,
          currentUserRole: this.props.currentUserRole,
          getUserRoleAuthHeaders: this.props.getUserRoleAuthHeaders,
          plans: this.state.plans,
          familyPlans: this.state.familyPlans
        }}
      >
        <PlanProvider
          plans={this.state.plans}
          familyPlans={this.state.familyPlans}
          currentUserRole={this.props.currentUserRole}
        >
          { this.props.children }
        </PlanProvider>
      </OrganizationRoleContext.Provider>
    );
  }
}



OrganizationRoleContext.defaultProps = {
  baseUrl: null,
  isLinearProgressDisabled: false
}



export default withContext(UserRoleContext, OrganizationRoleProvider);