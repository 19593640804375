import React from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';


import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';

import ChatBox from '../../ChatBox/ChatBox';


import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './SingleDateQuestion.css';

class SingleDateQuestion extends React.Component {

  constructor(props) {
    super(props);

    const date = this.formatStringToMoment(props.responseData);

    this.state = {
      date,
      focused: false
     };
  }


  formatStringToMoment = string => (
    moment(string || new Date(), 'MM/DD/YYYY')
  )


  componentDidUpdate = oldProps => {
    if( oldProps !== this.props ){
      return this.setState({
        date: this.formatStringToMoment(this.props.responseData)
      });
    }
  }


  render() {
    return (
      <FormControl component="fieldset" style={{ marginBottom: 25 }}>
        <ChatBox>
          <Typography variant="subtitle1">{ this.props.question.prompt }</Typography>
          <Typography variant="caption">{ this.props.question.helperText }</Typography>
        </ChatBox>
        <FormGroup>
          <div style={{ marginBottom: 15, marginTop: 15 }}>
            <SingleDatePicker
              date={this.state.date}
              daySize={35}
              isOutsideRange={() => false}
              focused={this.state.focused}
              id='single-date-picker'
              noBorder={true}
              numberOfMonths={1}
              onDateChange={date => this.props.onChange(date.format('MM/DD/YYYY'))}
              onFocusChange={ ({ focused }) => this.setState({ focused }) }
              placeholder="MM/DD/YYYY"
            />
          </div>
        </FormGroup>
      </FormControl>
     )
  }
}

export default SingleDateQuestion;