import { JsonModel } from 'kn-react';

export default class GoogleAccountLocationModel {

  static fromJSON(json) {
    let model = new GoogleAccountLocationModel();
    model = JsonModel.modelFromJSON(model, json);
    return model;
  }

  toJSON() {
    return JsonModel.modelToJSON(this);
  }

}
