import React from 'react';

import { SvgIcon } from '@material-ui/core';


const thermometerIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <path fill="none" stroke={props.fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 13.5a4 4 0 1 0 4 0V5a2 2 0 0 0-4 0v8.5M10 9h4"/>
  </SvgIcon>
);


thermometerIcon.defaultProps = {
  fill: '#5E5F64'
}


export default thermometerIcon;