import React from 'react';
import DesktopWindows from '@material-ui/icons/DesktopWindows';
import withStyles from '@material-ui/core/styles/withStyles';

class DesktopIcon extends React.Component {

  render() {

    const { classes } = this.props;

    return (
      <DesktopWindows className={classes.desktopIcon}/>
    )
  }
}

const styles = theme => ({
  desktopIcon: {
    color: '#9e70ff',
    float: 'left'
  }
})

DesktopIcon.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(DesktopIcon);