import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MegaphoneIcon from '../Icons/MegaphoneIcon';
import servicesColors from '../ServicesColors/servicesColors';

const adsChip = props => (
  <Chip
    avatar={ <Avatar><MegaphoneIcon className={props.classes.svgIcon}/></Avatar> }
    label="Ads"
    classes={{ root: props.classes.root, avatar: props.classes.avatar}}
  />
)

const styles = theme => ({
  root: {
    color: servicesColors.ads.primary,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${servicesColors.ads.primary}`,
  },
  avatar: {
    backgroundColor: servicesColors.ads.primary,
    color: servicesColors.ads.contrastText,
  },
  svgIcon: {
    fontSize: 19,
  }
});

export default withStyles(styles)(adsChip);