import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} viewBox="0 0 24 17">
    <g fillRule="evenodd">
      <path d="M10.4735 6.18315C10.4735 5.91258 10.5894 5.68069 10.7441 5.48749C10.9373 5.29428 11.1692 5.21692 11.4398 5.21692C11.9809 5.21692 12.406 5.64202 12.406 6.18317V7.41981L10.4735 7.41999L10.4735 6.18315Z"/>
      <path d="M19.169 14.647C18.512 14.647 17.8937 14.2993 17.5073 13.7582H20.7922C20.4445 14.2991 19.826 14.647 19.169 14.647Z"/>
      <path d="M19.9807 13.3716C20.1352 13.1784 20.2126 12.9465 20.2126 12.7146C20.2126 12.444 20.1352 12.2508 19.9807 12.0576H23.8068C23.9226 12.0576 24 12.135 24 12.2508V13.1397C24 13.2555 23.9226 13.3329 23.8068 13.3329C21.4492 13.3716 19.9033 13.3716 19.9807 13.3716V13.3716Z"/>
      <path d="M14.5313 13.3716C14.4155 13.3716 14.3381 13.2942 14.3381 13.1784V12.2895C14.3381 12.1737 14.4155 12.0963 14.5313 12.0963H18.3574C18.2029 12.2895 18.1255 12.5214 18.1255 12.7533C18.1255 13.0239 18.2029 13.2171 18.3574 13.4103C17.8163 13.3716 14.4927 13.3716 14.5313 13.3716V13.3716Z"/>
      <path d="M19.1687 13.3716C18.7823 13.3716 18.5117 13.0624 18.5117 12.7146C18.5117 12.3669 18.8209 12.0576 19.1687 12.0576C19.5164 12.0576 19.8257 12.3669 19.8257 12.7146C19.8257 13.0625 19.5166 13.3716 19.1687 13.3716Z"/>
      <path d="M16.0386 9.46812H8.85019L8.85001 1.15906H15.961L17.7388 8.42444C17.1976 8.5403 16.4635 9.08143 16.0384 9.46784L16.0386 9.46812ZM15.1884 2.1253H9.81658V7.8064H14.3767V6.22183L13.5651 5.41031V6.29917C13.5651 6.41502 13.4878 6.49238 13.3719 6.49238C13.2561 6.49238 13.1787 6.41502 13.1787 6.29917V3.98046C13.1787 3.8646 13.2561 3.78725 13.3719 3.78725C13.4878 3.78725 13.5651 3.8646 13.5651 3.98046V4.86932L14.7246 6.02877C14.7633 6.06745 14.7633 6.10613 14.7633 6.14463V7.80634H16.5797L15.1884 2.1253Z"/>
      <path d="M19.1689 10.2799C18.2413 10.2799 17.3911 10.821 17.0047 11.6712H15.1496C15.6521 9.73892 17.3525 8.57947 19.1302 8.57947C21.0239 8.57947 22.6471 9.85478 23.1108 11.6712H21.2557C20.9467 10.8208 20.0965 10.2799 19.1689 10.2799Z"/>
      <path d="M19.1689 10.6666C19.8645 10.6666 20.5215 11.053 20.908 11.6714H17.4298C17.8164 11.053 18.4734 10.6666 19.1689 10.6666H19.1689Z"/>
      <path d="M2.74405 11.439V8.30862C2.74405 7.65163 3.20783 7.03331 3.86483 6.84011L8.50244 5.52612V14.8784H5.41069C5.71993 13.2167 4.52182 11.555 2.74405 11.4389V11.439Z"/>
      <path d="M7.84558 0.386607C7.84558 0.154717 8.03879 0.000183312 8.23201 0.000183312L16.5411 0C16.773 0 16.9275 0.193212 16.9275 0.386423C16.9275 0.579635 16.7343 0.772847 16.5411 0.772847H8.23201C8.00012 0.77303 7.84558 0.618496 7.84558 0.386606V0.386607Z"/>
      <path d="M15.1886 13.7582H17.0822C17.5073 14.531 18.2803 15.0335 19.169 15.0335C20.0579 15.0335 20.8694 14.531 21.2559 13.7582H23.1497C22.6859 15.5746 21.0242 16.8499 19.169 16.8499C17.3528 16.8499 15.6522 15.6518 15.1886 13.7582H15.1886Z"/>
      <path d="M2.55087 13.5649C2.9373 13.5649 3.2852 13.8742 3.32373 14.2993V14.338C3.32373 14.7631 2.97599 15.1108 2.55087 15.1108C2.12576 15.1108 1.77802 14.7631 1.77802 14.338V14.2606C1.8167 13.874 2.12576 13.5649 2.55087 13.5649V13.5649Z"/>
      <path d="M0.50275 14.1445H0.03897V14.1058C0.0776486 13.5647 0.309537 13.1011 0.618623 12.7145L0.927868 13.0237C0.966547 13.0624 1.00522 13.1011 1.04372 13.1011C1.0824 13.1011 1.19826 13.0624 1.23694 13.0237C1.31429 12.9464 1.31429 12.8305 1.23694 12.7531L0.889192 12.4439C1.27562 12.0961 1.77805 11.9028 2.31904 11.8642V12.2893C2.31904 12.4052 2.39639 12.4826 2.51225 12.4826C2.62811 12.4826 2.70546 12.4052 2.70546 12.2893V11.8256C3.24659 11.8642 3.78757 12.0961 4.13531 12.4052L3.82606 12.7145C3.74871 12.7918 3.74871 12.9077 3.82606 12.985C3.86474 13.0237 3.90342 13.0624 3.9806 13.0624C4.05795 13.0624 4.09645 13.0237 4.09645 12.985L4.4057 12.6758C4.71494 13.0622 4.94682 13.526 4.98535 14.0671V14.1058H4.52157C4.40572 14.1058 4.32836 14.1831 4.32836 14.299C4.32836 14.4148 4.40572 14.4922 4.52157 14.4922H4.98535C4.98535 14.6081 4.98535 14.5696 4.908 14.9947C4.908 15.0333 4.86932 15.0333 4.86932 15.072C4.75346 15.3813 4.59875 15.6903 4.36686 15.9222L4.05761 15.613C3.98026 15.5356 3.8644 15.5356 3.78705 15.613C3.70969 15.6903 3.70969 15.8062 3.78705 15.8835L4.09629 16.1928C3.70987 16.5405 3.20743 16.7339 2.66644 16.7724V16.3087C2.66644 16.1928 2.58909 16.1154 2.47323 16.1154C2.35738 16.1154 2.28002 16.1928 2.28002 16.3087V16.7724C1.7389 16.7338 1.23663 16.5019 0.850175 16.1928L1.15942 15.8835C1.23678 15.8062 1.23678 15.6903 1.15942 15.613C1.08206 15.5356 0.966207 15.5356 0.888851 15.613L0.579606 15.9222C0.231861 15.5358 0.0384808 15.0334 -4.72069e-05 14.4924H0.463733C0.579588 14.4924 0.656944 14.415 0.656944 14.2992C0.69599 14.2218 0.618631 14.1444 0.502776 14.1444L0.50275 14.1445ZM2.55092 15.4971C3.20791 15.4971 3.71037 14.956 3.71037 14.3377V14.2603C3.63301 13.642 3.16924 13.1782 2.55092 13.1782C1.89393 13.1782 1.39147 13.6807 1.39147 14.3376C1.39165 14.9946 1.89393 15.4971 2.55092 15.4971V15.4971Z"/>
      <path d="M11.8259 9.85474H8.85023V11.1687H11.8259V9.85474Z"/>
      <path d="M14.7633 11.6714H14.5315C14.2222 11.6714 13.9518 11.9419 13.9518 12.251V13.1399C13.9518 13.4491 14.2224 13.7195 14.5315 13.7195H14.7633C14.8407 14.0673 14.9952 14.5311 15.2271 14.879H12.2514V9.81641H15.6909C15.2658 10.3575 14.9181 10.9759 14.7633 11.6715L14.7633 11.6714Z"/>
      <path d="M11.8259 13.6034H8.85023V14.9174H11.8259V13.6034Z"/>
      <path d="M11.8259 11.5554H8.85023V13.2171H11.8259V11.5554Z"/>
    </g>
  </SvgIcon>
);