import React from 'react';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';

class CallChip extends React.PureComponent {
	render() {

		const { classes } = this.props;

		return (
			<Chip
				color='primary'
				label={ this.props.label || 'Call' }
				classes={{ colorPrimary: classes.chipClass }}
			/>
		);
	}
}

const styles = theme => ({
	chipClass: {
		backgroundColor: '#BBEDE2',
		borderRadius: 3,
		color: '#29907b',
		fontSize: 14,
	}
});

export default withStyles(styles)(CallChip);