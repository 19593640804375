import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import FacebookIcon from '../Icons/FacebookIcon';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SimpleModal from './SimpleModal';


class AssetStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  render() {
    const { assets, classes, theme, open, onClose } = this.props;
    const { activeStep } = this.state;
    const maxSteps = assets.length;

    return (
      <SimpleModal open={open} onClose={onClose}>
        <div className={classes.root}>
          <Paper square elevation={0} className={classes.header}>
            <Avatar className={classes.avatar}>
              <FacebookIcon/>
            </Avatar>
            <Typography variant="h6" className={classes.headerText}>{assets[activeStep].name}</Typography>
          </Paper>
          <Paper className={classes.paper}>
            <img
              className={classes.img}
              src={assets[activeStep].assetData}
              alt={assets[activeStep].name}
            />
          </Paper>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            classes={{ dot: classes.dot, dotActive: classes.dotActive }}
            nextButton={
              <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                <KeyboardArrowRight/>
              </Button>
            }
            backButton={
              <Button  size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft/>
                Back
              </Button>
            }
          />
        </div>
      </SimpleModal>
    );
  }
}



AssetStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


const styles = theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    // backgroundColor: 'transparent',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  headerText: {
    // color: theme.palette.primary.main,
    paddingLeft: theme.spacing.unit,
  },
  mobileStepper: {
    // backgroundColor: 'transparent',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  paper: {
    overflow: 'hidden',
    borderRadius: 0,
  },
  img: {
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  // dot: {
  //   backgroundColor: 'rgba(255, 255, 255, 0.26)'
  // },
  // dotActive: {
  //   backgroundColor: theme.palette.secondary.light
  // },
  avatar: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  }
});


export default withStyles(styles, { withTheme: true })(AssetStepper);
