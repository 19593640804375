import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import ChatBox from '../../ChatBox/ChatBox';

class InputQuestion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    }
  }

  componentDidMount = () => {
    this.setState({
      value: this.props.responseData || this.props.question.data.default || ''
    });
  }


  onChange = e => {
    const { value } = e.target;
    this.setState({ value })
    this.props.onChange(value);
  }

  render() {
    const { question } = this.props;
    const isCurrency = question.data.format_type === 'currency';

    return (
      <FormControl component="fieldset" >
        <ChatBox>
          <Typography variant="subtitle1">{ question.prompt }</Typography>
          <Typography variant="caption">{ question.helperText }</Typography>
        </ChatBox>

        <FormGroup>
          <TextField
            type={ question.data.input_type || 'text' }
            value={ this.state.value }
            onChange={ this.onChange }
            placeholder={ question.data.placeholder || null }
            margin="normal"
            InputProps={{
              startAdornment: isCurrency ? <InputAdornment position="start">$</InputAdornment> : undefined,
            }}
          />
        </FormGroup>
        </FormControl>
     )
  }
}

export default InputQuestion;

// This input type incorporates a variable.
// {"input_type": "number", "format_type": "currency", "placeholder": "75.00"}