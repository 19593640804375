import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import LocationListItem from '../LocationListItem/LocationListItem';
import { log } from 'kn-react';



class LocationCard extends React.PureComponent {

  componentDidCatch = (error, info) => {
    log('error', error);
    log('info', info);
  }


  render() {
    const { title, locations } = this.props;

    return (
      <Card style={{ marginBottom: 25 }}>
        <CardContent>
          <Typography variant="subtitle1">
            {title}
          </Typography>
          {
            locations.length ?
              null
            :
              <Typography variant="caption">
                { this.props.noLocationsText }
              </Typography>
          }
        </CardContent>

        {
          locations.length ?
            <div>
              <List dense style={{ maxHeight: 300, overflowY: 'auto' }}>
                {
                  locations.map( l => {
                    const name = l.adwordsCanonicalName.split(',').slice(0,2).join(', ')
                    return (
                      <LocationListItem
                        name={name}
                        key={l.adwordsCanonicalName}
                        onRemove={ () => this.props.onRemove(l.censusGeoTypeId) }
                      />
                    );
                  })
                }
              </List>
            </div>
          :
           null
        }

      </Card>
    );
  }
}

const add = (a,b) => {
  return a + b;
}

LocationCard.defaultProps = {
  onRemove: () => {},
  noLocationsText: 'No Locations Selected'
}

export default LocationCard;