import React from 'react';

import { format } from 'kn-react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';

import TableToolbar from '../TableToolbar/TableToolbar';
import SmallTablePagination from '../SmallTablePagination/SmallTablePagination';



class NoteTable extends React.Component {
  render() {
    const { classes, total, notes } = this.props;

    return (
      <div className={classes.root}>
        <TableToolbar
          title={`${ total } Note${ total === 1 ? '' : 's' }`}
          actions={this.props.secondaryActions}
        />

        {
          this.props.isLoading ?
            <LinearProgress />
          :
            null
        }

        <List className={classes.list}>
          {
            (
              !this.props.isLoading &&
              ( !notes || !notes.length )
            ) ?
              <div
                className={classes.noDataWrapper}
              >
                <Typography variant="caption" className={classes.noData}>
                  No Notes
                </Typography>
              </div>
            :
              notes.map(
                (n, i) => (
                <ListItem
                  key={i}
                  className={classes.listItem}
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        {
                          !this.props.canInsertDetails ?
                            null
                          :
                            <div className={this.props.classes.addNote}>
                              <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => this.props.onClickInsert( n.content )}
                              >
                                { this.props.addNoteButtonLabel || 'Add to Note' }
                              </Button>
                            </div>
                        }

                        { n.content }
                      </React.Fragment>
                    }
                    primaryTypographyProps={{
                      className: classes.noteContent
                    }}
                    secondary={format.dateTime( n.createdAt )}
                    secondaryTypographyProps={{
                      className: classes.noteDateTime
                    }}
                  />
                </ListItem>
                )
              )
          }
        </List>

        <Divider />

        <SmallTablePagination
          count={total || 0}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.page}
          onChangeRowsPerPage={e => this.props.changePagination({ rowsPerPage: e.target.value })}
          onChangePage={(e, p) => this.props.changePagination({ page: p })}
          rowsPerPageOptions={this.props.rowsPerPageOptions}
        />
      </div>
    );
  }
}


NoteTable.defaultProps = {
  total: 0,
  notes: [],
  isLoading: false,
  secondaryActions: null,
  rowsPerPage: 10,
  page: 0,
  changePagination: () => {},
  rowsPerPageOptions: [5, 10, 25, 50],
  canInsertDetails: false,
  onClickInsert: () => {},
  addNoteButtonLabel: null,
}


const styles = theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  noData: {
    // margin: 'auto',
    opacity: 0.5
	},
  noDataWrapper: {
    textAlign: 'center',
    paddingTop: 10,
    color: '#777',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
	},
  listItem: {
    border: '1px solid lightgrey',
    borderRadius: 20,
    padding: 20,
    marginBottom: 15,
    breakInside: 'avoid',
  },
  list: {
    padding: '20px !important',
    margin: '0 !important',
    flex: 1
  },
  noteContent: {
    // breakInside: 'avoid',
  },
  noteDateTime: {
    marginTop: 10
  },
  addNote: {
    width: '100%',
    marginBottom: 10
  },
});

export default withStyles(styles)(
  NoteTable
);