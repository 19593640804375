import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { format } from 'kn-react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import TableToolbar from '../TableToolbar/TableToolbar';
import SmallTablePagination from '../SmallTablePagination/SmallTablePagination';




class MeetingsTable extends React.Component {


  render() {
    const { classes, total, meetings, isLoading } = this.props;

    return (
      <Paper className={classes.paper}>
        <TableToolbar
          title={`${ total } Meeting${ total === 1 ? '' : 's' }`}
        />

        {
          isLoading ?
            <LinearProgress />
          :
            null
        }

        {
          (
            !isLoading &&
            ( !meetings || !meetings.length )
          ) ?
            <Typography variant="caption" className={classes.noData}>No Meetings.</Typography>
          :
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Date
                  </TableCell>
                  <TableCell>
                    Meeting Room
                  </TableCell>
                  <TableCell>
                    Duration
                  </TableCell>
                  <TableCell>
                    Participants
                  </TableCell>
                  <TableCell>
                    Recorded?
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  meetings.map((m, i) => {
                    const meetingRoom = this.props.meetingRooms.find(
                      mr => mr.id === m.meetingRoomId
                    );

                    const durationMilliseconds = (
                      m.endedAt - m.createdAt
                    );

                    const durationMinutes = (
                      ( durationMilliseconds / 1000 ) / 60
                    ).toFixed(1) || 0;


                    const meetingLink = `/virtual/meetings/${ m.id }`;

                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={i}
                        style={{
                          opacity: this.props.isLoading ? 0.5 : 1
                        }}
                      >
                        <TableCell>
                          <Tooltip
                            title={m.shouldRecord ? '' : 'Meeting was not recorded. No details available.'}
                          >
                            <Link
                              component={this.props.onClickMeeting ? 'button' : RouterLink}
                              to={this.props.onClickMeeting ? undefined : meetingLink }
                              onClick={this.props.onClickMeeting ? () => this.props.onClickMeeting( m ) : undefined}
                            >
                              { format.dateTime( m.createdAt ) }
                            </Link>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          { meetingRoom.url }
                        </TableCell>
                        <TableCell>
                          {
                            m.isActive ?
                              'In progress'
                            :
                              `${ durationMinutes } minutes`
                          }
                        </TableCell>
                        <TableCell>
                          { m.totalSocketConnections }
                        </TableCell>
                        <TableCell>
                          { m.shouldRecord ? 'Yes' : 'No' }
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
        }

        <SmallTablePagination
          count={total || 0}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.page}
          onChangeRowsPerPage={this.props.onChangeRowsPerPage}
          onChangePage={this.props.onChangePage}
          rowsPerPageOptions={this.props.rowsPerPageOptions}
        />
      </Paper>
    );
  }
}


MeetingsTable.defaultProps = {
  total: 0,
  page: 0,
  meetings: [],
  meetingRooms: [],
  isLoading: false,
  rowsPerPage: 5,
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
  rowsPerPageOptions: [5, 10, 25, 50],
  onClickMeeting: null,
}


const styles = theme => ({
  paper: {
    padding: 16,
  },
  tableRow: {
    textDecoration: 'none',
  },
  noData: {
		textAlign: 'center',
		padding: 25,
		color: '#777',
	},
});

export default withStyles(styles)(
  MeetingsTable
);