import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { log } from 'kn-react';
import TimePicker from './TimePicker';
import './BusinessHours.css';

const DEFAULT_HOURS = { open: '9:00 AM', close: '5:00 PM'};


const DEFAULT_BUSINESS_HOURS = {
  Monday: { isClosed: false, hours: [{...DEFAULT_HOURS}] },
  Tuesday: { isClosed: false, hours: [{...DEFAULT_HOURS}] },
  Wednesday: { isClosed: false, hours: [{...DEFAULT_HOURS}] },
  Thursday: { isClosed: false, hours: [{...DEFAULT_HOURS}] },
  Friday: { isClosed: false, hours: [{...DEFAULT_HOURS}] },
  Saturday: { isClosed: true, hours: [{...DEFAULT_HOURS}] },
  Sunday: { isClosed: true, hours: [{...DEFAULT_HOURS}] }
};

// Export DEFAULT_BUSINESS_HOURS so that it can be used in the default
// state of the component for initializing business hours.
export { DEFAULT_HOURS, DEFAULT_BUSINESS_HOURS };

class BusinessHours extends React.Component {

  state = {
    ...DEFAULT_BUSINESS_HOURS,
    ...(this.props.businessHours || {})
  }


  onChange = () => {
    log('onChange', this.state);
    this.props.onChange(this.state);
  }

  onChangeIsClosed = day => {
    let dayData = this.state[day];
    dayData = { ...dayData, isClosed: !dayData.isClosed };
    log('dayData', day, dayData);
    this.setState({ [day]: dayData }, this.onChange);
  }


  onClickAdd = day => {
    let dayData = this.state[day];
    const lastHours = dayData.hours[dayData.hours.length - 1];

    dayData = { ...dayData, hours: [...dayData.hours, { open: lastHours.close, close: lastHours.close }] };
    log('dayData', day, dayData);
    this.setState({ [day]: dayData }, this.onChange);
  }

  onClickRemove = (day, index) => {
    let dayData = this.state[day];
    dayData = { ...dayData, hours: dayData.hours.filter((h,i) => i !== index) };
    log('dayData', day, dayData);
    this.setState({ [day]: dayData }, this.onChange);
  }


  onChangeTime = (newTime, day, index, openOrClose) => {
    let dayData = this.state[day];
    dayData = { ...dayData };
    dayData.hours[index][openOrClose] = newTime;
    this.setState({ [day]: dayData }, this.onChange);
  }


  dailyHoursRow = day => {
    const dayData = this.state[day];
    const { classes } = this.props;

    return (
      <Grid container key={day} style={{ alignItems: 'top' }}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6">{ day }</Typography>
          <FormControlLabel
            control={
              <Checkbox
                id={`${day.toLowerCase()}-closed`}
                checked={dayData.isClosed}
                onChange={() => this.onChangeIsClosed(day)}
              />
            }
            classes={{ label: classes.closedLabel }}
            label="Closed"
            margin="dense"
          />
        </Grid>
        <Grid container item xs={12} sm={9}>
          {
            dayData.hours.map((h,i) => (
              <React.Fragment key={i}>
                <Grid item xs={8} sm={5}>
                  <TimePicker
                    time={h.open}
                    disabled={dayData.isClosed}
                    onChange={t => this.onChangeTime(t, day, i, 'open')}
                    idPrefix={`${ day.toLowerCase() }${ i ? `-${i}` : '' }-open`}
                    namePrefix={`${ day.toLowerCase() }${ i ? `-${i}` : '' }-open`}
                  />
                </Grid>
                <Grid item xs={8} sm={5}>
                  <TimePicker
                    time={h.close}
                    disabled={dayData.isClosed}
                    onChange={t => this.onChangeTime(t, day, i, 'close')}
                    idPrefix={`${ day.toLowerCase() }${ i ? `-${i}` : '' }-close`}
                    namePrefix={`${ day.toLowerCase() }${ i ? `-${i}` : '' }-close`}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <IconButton onClick={() => i === 0 ? this.onClickAdd(day) : this.onClickRemove(day, i)} disabled={dayData.isClosed}>
                    { i === 0 ? <Add/> : <Remove/>  }
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))
          }
        </Grid>
      </Grid>
    );
  }


  render(){
    const days = Object.keys(this.state);

    return (
      <List>
        <ListItem>
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item xs={3}>
              <Typography variant="caption" color="primary">Day</Typography>
            </Grid>
            <Grid container item xs={8}>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="primary">Open Time</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="primary">Close Time</Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}/>
          </Grid>
        </ListItem>
        <Divider/>

        {
          days.map((d, i) => (
            <React.Fragment key={i}>
              <ListItem>
                { this.dailyHoursRow(d) }
              </ListItem>
              { i !== days.length - 1 ?  <Divider/> : null }
            </React.Fragment>
          ))
        }

      </List>
    );
  }

}


const styles = theme =>({
  closedLabel: {
    color: '#777'
  }
});



BusinessHours.defaultProps = {
  defaultHours: DEFAULT_HOURS,
  onChange: () =>{}
};


export default withStyles(styles)(BusinessHours);