import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

const userRoleList = props => (
  <List>
    {
      props.userRoles.sort(
        (a, b) => (
          ( a.roleName || '' ).localeCompare( b.roleName || '' ) &&
          ( a.roleTypeName || '' ).localeCompare( b.roleTypeName || '' )
        )
      ).map(r => (
        <ListItem button key={r.id} onClick={() => props.setUserRole(r)}>
          <ListItemText primary={r.roleTypeName} secondary={r.roleName} />
        </ListItem>
      ))
    }
  </List>
);

export default userRoleList;
