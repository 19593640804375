/*global window*/

export default (hostname) => {
  if(
    !hostname &&
    typeof window !== 'undefined'
  ){
    hostname = window.location.hostname;
  } else if( !hostname ){
    hostname = '';
  }

  return hostname.endsWith('localhost')
}