import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import Alert from '../Alert/Alert';
import PromiseButton from '../PromiseButton/PromiseButton';
import CheckboxQuestion from './CheckboxQuestion/CheckboxQuestion';
import DropdownQuestion from './DropdownQuestion/DropdownQuestion';
import HelperHtml from './HelperHtml/HelperHtml';
import InputQuestion from './InputQuestion/InputQuestion';
import SingleDateQuestion from './SingleDateQuestion/SingleDateQuestion';
import TextAreaQuestion from './TextAreaQuestion/TextAreaQuestion';
import ColorQuestion from './ColorQuestion/ColorQuestion';
import PrimaryAndSecondaryColorQuestion from './PrimaryAndSecondaryColorQuestion/PrimaryAndSecondaryColorQuestion';

class SurveysQuestion extends React.Component {;
  state = {
    isAdvancedExpanded: false
  }

  onQuestionChange = (question, responseData) => {
    const newResponseDataByQuestionId = {...this.props.responseDataByQuestionId};
    newResponseDataByQuestionId[question.id] = responseData;
    this.props.onResponseDataByQuestionIdChange(newResponseDataByQuestionId);
  }


  renderQuestion = (question, index) => {
    const { responseDataByQuestionId } = this.props;
    const responseData = responseDataByQuestionId[question.id];
    // const responseData = response ? response.data : undefined;

    const question_key = 'q_' + index;
    const questionTypes = {
      checkbox: <CheckboxQuestion
        key={question_key}
        question={question}
        responseData={responseData}
        onChange={response => this.onQuestionChange(question, response)}
      />,
      input: <InputQuestion
        key={question_key}
        question={question}
        responseData={responseData}
        onChange={response => this.onQuestionChange(question, response)}
      />,
      dropdown: <DropdownQuestion
        key={question_key}
        question={question}
        responseData={responseData}
        onChange={response => this.onQuestionChange(question, response)}
      />,
      helper_html: <HelperHtml
        key={question_key}
        html={question.html}
      />,
      single_date: <SingleDateQuestion
        key={question_key}
        question={question}
        responseData={responseData}
        onChange={response => this.onQuestionChange(question, response)}
      />,
      text_area: <TextAreaQuestion
        key={question_key}
        question={question}
        responseData={responseData}
        onChange={response => this.onQuestionChange(question, response)}
      />,
      color: <ColorQuestion
        key={question_key}
        question={question}
        responseData={responseData}
        onChange={response => this.onQuestionChange(question, response)}
      />,
    }
    return questionTypes[question.questionType];
  }


  renderSurveyQuestions = (filterFunction, preGridContent) => (
    <Grid container xs={12}>
      { preGridContent }

      <FormGroup
        row
        className={this.props.classes.formGroup}
      >
        {
          this.props.questions.filter(
            q => !q.isPrimaryColor && !q.isSecondaryColor
          ).filter(
            q => filterFunction ? filterFunction( q ) : true
          ).map((q, i) =>(
            <div
              className={this.props.classes.questionFormGroupItem}
            >
              { this.renderQuestion(q, i) }
            </div>
          ))
        }
      </FormGroup>
    </Grid>
  )


  render(){
    const { questions, classes } = this.props;

    const primaryOrSecondaryColorQuestions = questions.filter(
      q => q.isPrimaryColor || q.isSecondaryColor
    );

    const hasAdvancedQuestions = Boolean(
      this.props.questions.filter(
        q => !q.isPrimaryColor && !q.isSecondaryColor
      ).filter(
        q => q.isAdvanced
      ).length
    );

    const hasNonAdvancedQuestions = Boolean(
      this.props.questions.filter(
        q => !q.isPrimaryColor && !q.isSecondaryColor
      ).filter(
        q => !q.isAdvanced
      ).length
    );

    const advancedAlert = (
      <Alert
        warning
      >
        You do not need to complete these questions now.
        They make take several minutes so you can complete them later.
      </Alert>
    );


    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.primaryQuestionsPaper}>
            {
              primaryOrSecondaryColorQuestions.length ?
                <PrimaryAndSecondaryColorQuestion
                  questions={primaryOrSecondaryColorQuestions}
                  responseDataByQuestionId={this.props.responseDataByQuestionId}
                  onChange={this.onQuestionChange}
                  previewUrl={this.props.previewUrl}
                />
              :
                null
            }

            {
              ( hasNonAdvancedQuestions || primaryOrSecondaryColorQuestions.length ) ?
                this.renderSurveyQuestions(
                  q => !q.isAdvanced
                )
              :
                hasAdvancedQuestions ?
                  this.renderSurveyQuestions(
                    q => q.isAdvanced,
                    advancedAlert
                  )
                :
                  primaryOrSecondaryColorQuestions.length ?
                    null
                  :
                    <Alert warning>
                      This survey has no questions. Click next.
                    </Alert>
            }
          </Paper>

          {
            hasAdvancedQuestions && ( hasNonAdvancedQuestions || primaryOrSecondaryColorQuestions.length ) ?
              <Grid item xs={12} style={{ textAlign: 'left', marginBottom: 15, marginTop: 15 }}>
                <Link
                  component='button'
                  onClick={() => this.setState({ isAdvancedExpanded: !this.state.isAdvancedExpanded })}
                  style={{
                    color: 'grey'
                  }}
                >
                  Additional Options
                </Link>
              </Grid>
            :
              null
          }

          {
            this.state.isAdvancedExpanded ?
              <Paper className={classes.advancedPaper}>
                {
                  this.renderSurveyQuestions(
                    q => q.isAdvanced,
                    advancedAlert
                  )
                }
              </Paper>
            :
              null
          }

          <Grid container className={classes.buttonGrid}>
            <Grid item xs={6}>
              <div style={{ padding: 10 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={this.props.onBack}
                >
                  Back
                </Button>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div style={{ textAlign: 'right' }}>
                <PromiseButton
                  buttonProps={{ fullWidth: false, variant: 'contained', color: 'primary' }}
                  onProcess={this.props.onSubmit}
                >
                  Next
                </PromiseButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

}

const styles = theme => ({
  paperRoot: {
    marginTop: 0,
    paddingTop: 30,
    paddingBottom: 0,
    paddingRight: 40,
    paddingLeft: 40,
  },
  buttonGrid: {
    marginTop: 20,
  },
  questionFormGroupItem: {
    marginBottom: 25,
    width: '100%',
  },
  formGroup: {
    width: '100%'
  },
  advancedPaper: {
    padding: 15,
    paddingTop: 25,
    paddingLeft: 15,
    paddingRight: 15,
  },
  primaryQuestionsPaper: {
    padding: 15
  }
});


SurveysQuestion.defaultProps = {
  questions: [],
  responseDataByQuestionId: {},
  onResponseDataByQuestionIdChange: () => {},
  onSubmit: () => {},
  onBack: () => {},
  previewUrl: ''
}


export default withStyles(styles)(SurveysQuestion);