import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';


const sidebarLayout = props => {
  const { classes } = props;
  return (
    <div className="SidebarLayout">
      { props.nav }

      <div className={classes.navOffset} style={props.navOffsetStyle}>
        <div className={classes.mainWrapper} style={props.mainWrapperStyle}>

          <div className={classes.main} style={props.mainStyle}>
            { props.main }
          </div>

          <div className={classes.sidebar} style={props.sidebarStyle}>
            { props.sidebar }
          </div>

        </div>
      </div>

    </div>
   );
};


const styles = theme => ({
  navOffset: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      top: 65,
    },
  },
  mainWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      // top: 240,
    },
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      height: 400,
      width: '100%',
      position: 'relative',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      // height: 'inherit',
      width: '70%',
    },
  },
  sidebar: {
    position: 'relative',
    width: '100%',
    top: 0,
    bottom: 0,
    right: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: 50,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '30%',
    }
  }
});



export default withStyles(styles)(sidebarLayout);