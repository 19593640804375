import React from 'react';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import GoogleIcon from '../Icons/GoogleIcon';
import { withContext } from 'kn-react';
import { GoogleAuthContext } from '../GoogleAuthLocation/GoogleAuthProvider';


const googleAuth = props => (
  props.isProcessingGoogleAccount ?
    <CircularProgress />
  :
    <Button
      variant="contained"
      size="small"
      className={props.classes.button}
      disabled={!props.canSignIn}
      onClick={e => {
        e.preventDefault();

        if( !props.canSignIn ){
          return;
        }

        props.startGoogleOauth();
      }}
    >
      <GoogleIcon className={classnames(props.classes.leftIcon, props.classes.iconSmall)} />
      Sign in with Google
    </Button>
);

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#2276E4',
    color: 'white',
    '&:hover': { backgroundColor: '#5094ed' },
    '&:focus': { backgroundColor: '#5094ed' },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

googleAuth.defaultProps = {
  startGoogleOauth: function(){},
  canSignIn: true
}

export default withContext(
  GoogleAuthContext,
  withStyles(styles)(googleAuth)
);