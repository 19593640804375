import React from 'react';
import Typography from '@material-ui/core/Typography';
import StarsRating from '../StarsRating/StarsRating';

export default props => {
  const average = (props.average || 0).toFixed(2);

  return (
    <div style={{ textAlign:'center', padding: 5 }}>
      <Typography variant="h2">{average}</Typography>
      <div style={{ display: 'inline-flex' }}>
        <StarsRating average={props.average || 0} />
      </div>
    </div>
  );
}