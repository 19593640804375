import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import CallChip from '../KnChips/CallChip';
import FormChip from '../KnChips/FormChip';
import VirtualWorkRequestChip from '../KnChips/VirtualWorkRequestChip';
import CallCard from '../CallCard/CallCard';
import { format } from 'kn-react';
import FormCard from '../FormCard/FormCard';

 import { minPadding } from '../OpportunityTable/opportunityTableConstants';

class OrganizationOpportunityRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }


  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  }


  isCallOpportunity = opportunity => {
    return opportunity.opportunityType && opportunity.opportunityType.toLowerCase() === 'call'
  }

  isVirtualWorkRequestOpportunity = opportunity => {
    return opportunity.opportunitySource && opportunity.opportunitySource.toLowerCase() === 'companyvirtualformdesign'
  }


  render() {
    const { classes, opportunity } = this.props;

    const name = (this.isCallOpportunity(opportunity)) ? opportunity.callerName : opportunity.name;

    const date = (typeof opportunity.createdAt == 'object') ? format.date(opportunity.createdAt) : opportunity.createdAt

    return (
      <TableBody>
        <TableRow>

          {
            (this.props.showCompanyName) ?
              <TableCell
                style={{
                  paddingLeft: minPadding
                }}
              >
                {opportunity.companyName}
              </TableCell>
            :
              null
          }

          <TableCell
            style={{
              paddingLeft: this.props.showCompanyName ? undefined : minPadding
            }}
          >
            {name}
          </TableCell>

          <TableCell>
            {date}
          </TableCell>

          <TableCell>
            {
              this.isCallOpportunity(opportunity) ?
                <CallChip />
              :
                this.isVirtualWorkRequestOpportunity(opportunity) ?
                  <VirtualWorkRequestChip />
                :
                  <FormChip />
            }
          </TableCell>

          <TableCell>
            {
              <IconButton className={classes.iconButton} onClick={this.toggleExpand}>
                {
                  (!this.state.expanded) ? <AddCircleOutline /> : <RemoveCircleOutline />
                }
              </IconButton>
            }
          </TableCell>
        </TableRow>

        {
          this.isCallOpportunity(opportunity) && this.state.expanded ?
            <TableRow>
              <TableCell className={classes.opportunityCell} colSpan={6}>
                <CallCard
                  audioUrl={opportunity.callRecordingUrl}
                  mainColor={this.props.theme.palette.primary.main}
                  call={opportunity}
                  align='center'
                />
              </TableCell>
            </TableRow>
          :
            null
        }

        {
          !this.isCallOpportunity(opportunity) && this.state.expanded ?
            <TableRow>
              <TableCell className={classes.opportunityCell} colSpan={6}>
                <FormCard
                  mainColor={this.props.theme.palette.primary.main}
                  opportunity={opportunity}
                  align='center'
                  onProcessSpam={this.props.onProcessSpam}
                  showMarkAsSpam={this.props.showMarkAsSpam}
                  showViewOpportunity={this.props.showViewOpportunity}
                  viewOpportunityRoute={this.props.viewOpportunityRoute}
                  onClickViewOpportunity={this.props.onClickViewOpportunity}
                />
              </TableCell>
            </TableRow>
          :
            null
        }
      </TableBody>
    );
  }
}

const styles = theme => ({
  iconButton: {
    color: theme.palette.primary.main
  },
  opportunityCell: {
    verticalAlign: 'middle !important',
    padding: '0 !important'
  },
})

OrganizationOpportunityRow.defaultProps = {
  opportunity: {
    createdAt: null,
    reviewedByUserId: null,
    opportunityType: null,
    callRecordingUrl: null,
    callerName: null,
    callerNumber: null,
    callStatus: null,
    duration: null,
    name: null,
    companyName: null
  },
  showCompanyName: false,
  onProcessSpam: () => Promise.resolve(),
  showMarkAsSpam: false,
  showViewOpportunity: false,
  viewOpportunityRoute: () => '#',
  onClickViewOpportunity: null,
}

export default withStyles(styles, { withTheme: true })(OrganizationOpportunityRow);