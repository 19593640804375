import BuildingHomeIcon from './BuildingHomeIcon';
import BuyingHomeIcon from './BuyingHomeIcon';
import ContractIcon from './ContractIcon';
import MonthCalendarIcon from './MonthCalendarIcon';
import NoIcon from './NoIcon';
import OwnHomeIcon from './OwnHomeIcon';
import WeekCalendarIcon from './WeekCalendarIcon';
import YesIcon from './YesIcon';
import AirConditioningIcon from './AirConditioningIcon';
import HammerIcon from './HammerIcon';
import HomeEmergencyIcon from './HomeEmergencyIcon';
import HomeEnergyIcon from './HomeEnergyIcon';
import HomeSearchIcon from './HomeSearchIcon';
import HomeQuestionIcon from './HomeQuestionIcon';
import ThermometerIcon from './ThermometerIcon';


// https://icon-sets.iconify.design/tabler
export default {
  'HomePersonIcon': OwnHomeIcon,
  'HomeMoneyIcon': BuyingHomeIcon,
  'HomeCheckIcon': BuildingHomeIcon,
  'HomeEmergencyIcon': HomeEmergencyIcon,
  'HomeEnergyIcon': HomeEnergyIcon,
  'HomeSearchIcon': HomeSearchIcon,
  'HomeQuestionIcon': HomeQuestionIcon,
  'ThermometerIcon': ThermometerIcon,
  'NoIcon': NoIcon,
  'YesIcon': YesIcon,
  'ContractIcon': ContractIcon,
  'MonthCalendarIcon': MonthCalendarIcon,
  'WeekCalendarIcon': WeekCalendarIcon,
  'AirConditioningIcon': AirConditioningIcon,
  'HammerIcon': HammerIcon,
};