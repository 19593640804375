import React, { Component } from 'react'
import axios from 'axios';
import { log, withContext } from 'kn-react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SnackbarContext } from '../Snackbar/SnackbarProvider'


class FacebookAuthPageModal extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      pages: []
    }
  }

  componentDidMount(){
    this.getFacebookPages();
  }


  getFacebookPages() {
    const errorCallback = () => {
      this.setState({ loading: false }, this.props.onClose);
    }


    return this.props.getFacebookPagesCallback(
      errorCallback
    )
    .then(pages => {
      if(pages.length <= 1) {
        (pages.length === 1) ? this.props.onSelectPage(pages[0]) : null;
        this.props.onClose();
      } else {
        this.setState({
          pages: pages,
          loading: false,
          modalVisible: true
        });
      }
    });
  }


  onSelectPage = (page) => {
    this.setState({ modalVisible: false });
    this.props.onSelectPage(page);
    this.props.onClose();
  }


  render() {
    const { classes, dialogOpen } = this.props;

    return(
      <Dialog
        open={dialogOpen}
        onClose={e => this.props.onClose()}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogContent className={classes.dialog}>
          {
            this.state.loading ?
              <CircularProgress/>
            :
              <React.Fragment>
                <h3>Please Select The Facebook Page You Want Us To Monitor</h3>

                <List>
                  {
                    this.state.pages.map(page => (
                      <ListItem
                        button
                        key={page.id}
                        onClick={() => this.onSelectPage(page)}
                      >
                        <ListItemText
                          primary={page.name}
                          secondary={page.single_line_address}
                        />
                      </ListItem>
                    ))
                  }
                </List>
              </React.Fragment>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

FacebookAuthPageModal.defaultProps = {
  dialogOpen: false,
  facebookAccount: {},
  onClose: function() {},
  onSelectPage: function() {},
  getFacebookPagesCallback: function(){},
}

const styles = theme => ({
  dialog: {
    textAlign: 'center'
  }
})

export default withContext(SnackbarContext, withStyles(styles)(FacebookAuthPageModal));