import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';

class RespondChip extends React.PureComponent {
	render() {

		const { classes } = this.props;

		return (
			<Chip
				color='primary'
				label={ this.props.label || 'Respond' }
				classes={{ colorPrimary: classes.chipClass }}
				style={{
					opacity: this.props.opacity || 1
				}}
			/>
		);
	}
}

const styles = theme => ({
	chipClass: {
		borderRadius: 3,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
		backgroundColor: '#37caad',
		color: '#fff',
	}
});


export default withStyles(styles, { withTheme: true })(RespondChip);