export default {
  CREATE_COMPANY: 'CREATE_COMPANY',
  CREATE_OAUTH_GRANT: 'CREATE_OAUTH_GRANT',
  CREATE_ORGANIZATION_EXTERNAL_DATA: 'CREATE_ORGANIZATION_EXTERNAL_DATA',
  EDIT_ADS_COMPANY_BOOSTED_SERVICE: 'EDIT_ADS_COMPANY_BOOSTED_SERVICE',
  EDIT_ADS_FACEBOOK_REPORTS: 'EDIT_ADS_FACEBOOK_REPORTS',
  EDIT_ADS_MIGRATIONS: 'EDIT_ADS_MIGRATIONS',
  EDIT_ADS_ORGANIZATION_BOOSTED_SERVICE: 'EDIT_ADS_ORGANIZATION_BOOSTED_SERVICE',
  EDIT_ADS_SEARCH_QUERIES: 'EDIT_ADS_SEARCH_QUERIES',
  EDIT_ADS_TEMPLATES: 'EDIT_ADS_TEMPLATES',
  EDIT_BYO_DOMAIN: 'EDIT_BYO_DOMAIN',
  EDIT_COMPANY_ADS_PROFILE: 'EDIT_COMPANY_ADS_PROFILE',
  EDIT_COMPANY_BILLING: 'EDIT_COMPANY_BILLING',
  EDIT_COMPANY_INFO: 'EDIT_COMPANY_INFO',
  EDIT_COMPANY_MEDIA_ITEMS: 'EDIT_COMPANY_MEDIA_ITEMS',
  EDIT_COMPANY_OPPORTUNITIES: 'EDIT_COMPANY_OPPORTUNITIES',
  EDIT_COMPANY_REVIEWS: 'EDIT_COMPANY_REVIEWS',
  EDIT_COMPANY_SITES_PROFILE: 'EDIT_COMPANY_SITES_PROFILE',
  EDIT_COMPANY_USERS: 'EDIT_COMPANY_USERS',
  EDIT_EXTERNAL_ASSET_CONNECTION: 'EDIT_EXTERNAL_ASSET_CONNECTION',
  EDIT_FACEBOOK_ACCOUNT: 'EDIT_FACEBOOK_ACCOUNT',
  EDIT_FAMILY_ORGANIZATIONS: 'EDIT_FAMILY_ORGANIZATIONS',
  EDIT_GOOGLE_ACCOUNT: 'EDIT_GOOGLE_ACCOUNT',
  EDIT_NOTE: 'EDIT_NOTE',
  EDIT_OPPORTUNITY_IS_MANUAL_SPAM: 'EDIT_OPPORTUNITY_IS_MANUAL_SPAM',
  EDIT_ORGANIZATION_COMPANIES: 'EDIT_ORGANIZATION_COMPANIES',
  EDIT_ORGANIZATION_EXTERNAL_DATA: 'EDIT_ORGANIZATION_EXTERNAL_DATA',
  EDIT_ORGANIZATION_MEDIA_ITEMS: 'EDIT_ORGANIZATION_MEDIA_ITEMS',
  EDIT_ORGANIZATION_PROFILE: 'EDIT_ORGANIZATION_PROFILE',
  EDIT_ORGANIZATION_USERS: 'EDIT_ORGANIZATION_USERS',
  EDIT_REVIEWS_COMPANY_REVIEW_SITE_VISIBILITY: 'EDIT_REVIEWS_COMPANY_REVIEW_SITE_VISIBILITY',
  EDIT_REVIEWS_COMPANY_REVIEW_SITES: 'EDIT_REVIEWS_COMPANY_REVIEW_SITES',
  EDIT_REVIEWS_REVIEW_REQUESTS: 'EDIT_REVIEWS_REVIEW_REQUESTS',
  EDIT_REVIEWS_REVIEW_RESPONSES: 'EDIT_REVIEWS_REVIEW_RESPONSES',
  EDIT_SITES_COLLECTION: 'EDIT_SITES_COLLECTION',
  EDIT_SITES_COMPANY_BLOCK: 'EDIT_SITES_COMPANY_BLOCK',
  EDIT_SITES_COMPANY_SITE_DESIGN: 'EDIT_SITES_COMPANY_SITE_DESIGN',
  EDIT_SITES_IS_SSL_REQUIRED: 'EDIT_SITES_IS_SSL_REQUIRED',
  EDIT_SITES_ORGANIZATION_BLOCK: 'EDIT_SITES_ORGANIZATION_BLOCK',
  EDIT_SITES_ORGANIZATION_SITE_DESIGN: 'EDIT_SITES_ORGANIZATION_SITE_DESIGN',
  EDIT_SITES_SITE_MAINTENANCE_MODE: 'EDIT_SITES_SITE_MAINTENANCE_MODE',
  EDIT_SOCIAL_COMPANY_FEED: 'EDIT_SOCIAL_COMPANY_FEED',
  EDIT_SOCIAL_COMPANY_SOCIAL_PROFILE: 'EDIT_SOCIAL_COMPANY_SOCIAL_PROFILE',
  EDIT_SOCIAL_ORGANIZATION_FEED: 'EDIT_SOCIAL_ORGANIZATION_FEED',
  EDIT_VIRTUAL_COMPANY_VIRTUAL_FORM_DESIGN: 'EDIT_VIRTUAL_COMPANY_VIRTUAL_FORM_DESIGN',
  EDIT_VIRTUAL_COMPANY_VIRTUAL_PROFILE: 'EDIT_VIRTUAL_COMPANY_VIRTUAL_PROFILE',
  EDIT_VIRTUAL_MEETING_ROOM: 'EDIT_VIRTUAL_MEETING_ROOM',
  IMPERSONATE: 'IMPERSONATE',
  ORPHAN_COMPANY: 'ORPHAN_COMPANY',
  ORPHAN_USER: 'ORPHAN_USER',
  RELEASE_NEW_DOMAIN: 'RELEASE_NEW_DOMAIN',
  SEARCH_ORGANIZATION_EXTERNAL_DATA: 'SEARCH_ORGANIZATION_EXTERNAL_DATA',
  SEARCH_USERS: 'SEARCH_USERS',
  TRACK_BYO_DOMAIN_INFO_RECEIVED: 'TRACK_BYO_DOMAIN_INFO_RECEIVED',
  VIEW_ACTIVITY_JSON: 'VIEW_ACTIVITY_JSON',
  VIEW_ADS_COMPANY_ADWORDS_REPORTS: 'VIEW_ADS_COMPANY_ADWORDS_REPORTS',
  VIEW_ADS_COMPANY_BOOSTED_SERVICE: 'VIEW_ADS_COMPANY_BOOSTED_SERVICE',
  VIEW_ADS_COMPANY_FACEBOOK_REPORTS: 'VIEW_ADS_COMPANY_FACEBOOK_REPORTS',
  VIEW_ADS_FACEBOOK_REPORTS: 'VIEW_ADS_FACEBOOK_REPORTS',
  VIEW_ADS_ORGANIZATION_ADWORDS_REPORTS: 'VIEW_ADS_ORGANIZATION_ADWORDS_REPORTS',
  VIEW_ADS_ORGANIZATION_BOOSTED_SERVICE: 'VIEW_ADS_ORGANIZATION_BOOSTED_SERVICE',
  VIEW_ADS_ORGANIZATION_FACEBOOK_REPORTS: 'VIEW_ADS_ORGANIZATION_FACEBOOK_REPORTS',
  VIEW_ADS_SEARCH_QUERIES: 'VIEW_ADS_SEARCH_QUERIES',
  VIEW_ADS_TEMPLATES: 'VIEW_ADS_TEMPLATES',
  VIEW_COMPANY_ACTIVITY: 'VIEW_COMPANY_ACTIVITY',
  VIEW_COMPANY_ADS_PROFILE: 'VIEW_COMPANY_ADS_PROFILE',
  VIEW_COMPANY_BILLING: 'VIEW_COMPANY_BILLING',
  VIEW_COMPANY_INFO: 'VIEW_COMPANY_INFO',
  VIEW_COMPANY_MEDIA_ITEMS: 'VIEW_COMPANY_MEDIA_ITEMS',
  VIEW_COMPANY_OPPORTUNITIES: 'VIEW_COMPANY_OPPORTUNITIES',
  VIEW_COMPANY_REVIEWS: 'VIEW_COMPANY_REVIEWS',
  VIEW_COMPANY_SITES_PROFILE: 'VIEW_COMPANY_SITES_PROFILE',
  VIEW_COMPANY_USERS: 'VIEW_COMPANY_USERS',
  VIEW_DESCENDANT_COLLECTIONS: 'VIEW_DESCENDANT_COLLECTIONS',
  VIEW_EXTERNAL_ASSET_CONNECTION: 'VIEW_EXTERNAL_ASSET_CONNECTION',
  VIEW_FACEBOOK_ACCOUNT: 'VIEW_FACEBOOK_ACCOUNT',
  VIEW_FAMILY_ORGANIZATIONS: 'VIEW_FAMILY_ORGANIZATIONS',
  VIEW_GOOGLE_ACCOUNT: 'VIEW_GOOGLE_ACCOUNT',
  VIEW_NOTE: 'VIEW_NOTE',
  VIEW_ORGANIZATION_ACTIVITY: 'VIEW_ORGANIZATION_ACTIVITY',
  VIEW_ORGANIZATION_COMPANIES: 'VIEW_ORGANIZATION_COMPANIES',
  VIEW_ORGANIZATION_MEDIA_ITEMS: 'VIEW_ORGANIZATION_MEDIA_ITEMS',
  VIEW_ORGANIZATION_OPPORTUNITIES: 'VIEW_ORGANIZATION_OPPORTUNITIES',
  VIEW_ORGANIZATION_PROFILE: 'VIEW_ORGANIZATION_PROFILE',
  VIEW_ORGANIZATION_REVIEWS: 'VIEW_ORGANIZATION_REVIEWS',
  VIEW_ORGANIZATION_USERS: 'VIEW_ORGANIZATION_USERS',
  VIEW_REVIEWS_REVIEW_REQUESTS: 'VIEW_REVIEWS_REVIEW_REQUESTS',
  VIEW_REVIEWS_REVIEW_RESPONSES: 'VIEW_REVIEWS_REVIEW_RESPONSES',
  VIEW_SITES_COLLECTION: 'VIEW_SITES_COLLECTION',
  VIEW_SITES_COMPANY_BLOCK: 'VIEW_SITES_COMPANY_BLOCK',
  VIEW_SITES_COMPANY_DATA: 'VIEW_SITES_COMPANY_DATA',
  VIEW_SITES_COMPANY_SITE_DESIGN: 'VIEW_SITES_COMPANY_SITE_DESIGN',
  VIEW_SITES_ORGANIZATION_BLOCK: 'VIEW_SITES_ORGANIZATION_BLOCK',
  VIEW_SITES_ORGANIZATION_DATA: 'VIEW_SITES_ORGANIZATION_DATA',
  VIEW_SITES_ORGANIZATION_SITE_DESIGN: 'VIEW_SITES_ORGANIZATION_SITE_DESIGN',
  VIEW_SOCIAL_COMPANY_FEED: 'VIEW_SOCIAL_COMPANY_FEED',
  VIEW_SOCIAL_COMPANY_SOCIAL_PROFILE: 'VIEW_SOCIAL_COMPANY_SOCIAL_PROFILE',
  VIEW_SOCIAL_ORGANIZATION_FEED: 'VIEW_SOCIAL_ORGANIZATION_FEED',
  VIEW_VIRTUAL_COMPANY_VIRTUAL_FORM_DESIGN: 'VIEW_VIRTUAL_COMPANY_VIRTUAL_FORM_DESIGN',
  VIEW_VIRTUAL_COMPANY_VIRTUAL_PROFILE: 'VIEW_VIRTUAL_COMPANY_VIRTUAL_PROFILE',
  VIEW_VIRTUAL_FORM_DESIGN: 'VIEW_VIRTUAL_FORM_DESIGN',
  VIEW_VIRTUAL_MEETING_ROOM: 'VIEW_VIRTUAL_MEETING_ROOM',
}