import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

class LocationListItem extends React.PureComponent {

  render() {
    const { classes, name, secondary, onRemove, skipRemove } = this.props;
    return (
      <ListItem key={ name }>
        <ListItemText>{ name }</ListItemText>
        <ListItemText
          style={{ textAlign: 'right' }}
          secondary={ secondary }
        />
        {
           skipRemove === true ?
            null
          :
          <ListItemSecondaryAction>
            <IconButton onClick={onRemove} classes={{ root: classes.iconButtonRoot }} >
              <RemoveCircle/>
            </IconButton>
          </ListItemSecondaryAction>
        }
      </ListItem>
    );
  }
}

LocationListItem.defaultProps = {
  onRemove: () => {}
}

const styles = theme => ({
  iconButtonRoot: {
    padding: 8,
    color: 'lightgray'
  }
})

export default withStyles(styles)(LocationListItem);