/*global window*/
/*global document*/
import React from 'react';

import { Grid, Typography, withStyles, ListItemText, Select, MenuItem, Checkbox, Input, InputLabel, FormControl } from '@material-ui/core';



const VIDEO_ID = 'video-recording';
const ALL = 'All';


class VideoAnalysis extends React.Component {
  constructor(props){
    super(props);


    const filters = [];
    if(
      props.videoAnalysisApiResponse &&
      props.videoAnalysisApiResponse.annotationResults
    ){
      props.videoAnalysisApiResponse.annotationResults.forEach(
        r => r.shotLabelAnnotations.forEach((a, i) => filters.push(
          a.entity.description
        ))
      );
    }

    this.state = {
      filters,
      selectedFilters: filters
    };
  }


  onClick = startTimeSeconds => {
    if( typeof document === 'undefined' ){
      return;
    }


    const video = document.getElementById( VIDEO_ID );
    video.currentTime = startTimeSeconds;
    video.play();

    if( typeof window !== 'undefined' ){
      window.scrollTo(0, 0);
    }
  }


  onChangeSelect = e => {
    const allFilters = e.target.value;
    let selectedFilters = allFilters.filter(f => f !== ALL);


    if(
      allFilters.includes( ALL ) &&
      selectedFilters.length === this.state.filters.length
    ){
      selectedFilters = [];
    } else if( allFilters.includes( ALL ) ){
      selectedFilters = this.state.filters;
    }


    return this.setState({
      selectedFilters
    });
  }


  render(){
    const { classes } = this.props;

    let videoDurationSeconds = (
      ( this.props.endedAt - this.props.createdAt ) / 1000
    );

    // Add small buffer
    videoDurationSeconds += Math.min(videoDurationSeconds*0.05, 5);

    return(
      <Grid container>
        <Grid item xs={12}>
          <video
            controls
            className={classes.video}
            id={VIDEO_ID}
          >
            <source src={this.props.videoRecordingUrl} type="video/mp4" />
            Your browser does not support the video.
          </video>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl
            className={classes.formControl}
          >
            <InputLabel
              htmlFor="filter-video-entities"
            >
              Filter Video Entities
            </InputLabel>

            <Select
              multiple
              fullWidth
              label='Filter Entities'
              className={classes.select}
              value={this.state.selectedFilters}
              input={<Input id="filter-video-entities" />}
              onChange={this.onChangeSelect}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 500,
                    width: 250,
                  },
                },
              }}
              renderValue={
                this.state.selectedFilters.length === this.state.filters.length ?
                  () => ALL
                :
                  selected => selected.join(', ')
              }
            >
              <MenuItem divider value={ALL} style={{ backgroundColor: 'white' }}>
                <Checkbox checked={this.state.selectedFilters.length === this.state.filters.length} />
                <ListItemText primary={'All'} />
              </MenuItem>

              {
                this.state.filters.map(filter => (
                  <MenuItem key={filter} value={filter}>
                    <Checkbox checked={this.state.selectedFilters.indexOf(filter) > -1} />
                    <ListItemText primary={filter} />
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9} />

        <Grid
          item
          xs={12}
          className={classes.videoEntities}
        >
          <Typography
            variant='h6'
          >
            Video Entities
          </Typography>
        </Grid>


        <Grid
          item
          xs={2}
          className={classes.labelTimelineGrid}
        >
          <ListItemText
            primary={'Label'}
            secondary={'Category'}
          />
        </Grid>
        <Grid
          item
          xs={10}
          className={classes.labelTimelineGrid}
        >
          <ListItemText
            primary={'Timeline'}
          />
        </Grid>


        {
          !this.props.videoAnalysisApiResponse || !this.props.videoAnalysisApiResponse.annotationResults ?
            <Grid
              item
              xs={12}
            >
              <ListItemText
                secondary={'No video analysis available'}
              />
            </Grid>
          :
            this.props.videoAnalysisApiResponse.annotationResults.map(r => r.shotLabelAnnotations.map((a, i) => {
              if( !this.state.selectedFilters.includes( a.entity.description ) ){
                return;
              }


              return (
                <React.Fragment key={i}>
                  <Grid
                    item
                    xs={2}
                    className={classes.descriptionGridItem}
                  >
                    <ListItemText
                      primary={a.entity.description}
                      secondary={
                        a.categoryEntities ?
                          a.categoryEntities.map(c => c.description).join(', ')
                        :
                          null
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    className={classes.segmentGridItem}
                  >
                    <div className={classes.segmentBarWrapper}>
                      {
                        a.segments.map((s, j) => {
                          const startSecondsString = (
                            s.segment.startTimeOffset && s.segment.startTimeOffset.seconds ?
                              s.segment.startTimeOffset.seconds
                            :
                              '0'
                          );
                          const startNanosString = (
                            s.segment.startTimeOffset && s.segment.startTimeOffset.nanos ?
                              s.segment.startTimeOffset.nanos
                            :
                              '0'
                          );
                          const startSeconds = +`${ startSecondsString }.${ startNanosString }`;

                          const endSecondsString = (
                            s.segment.endTimeOffset && s.segment.endTimeOffset.seconds ?
                              s.segment.endTimeOffset.seconds
                            :
                              '0'
                          );
                          const endNanosString = (
                            s.segment.endTimeOffset && s.segment.endTimeOffset.nanos ?
                              s.segment.endTimeOffset.nanos
                            :
                              '0'
                          );
                          const endSeconds = +`${ endSecondsString }.${ endNanosString }`;

                          const segmentDurationSeconds = endSeconds - startSeconds;
                          const width = `${ ( segmentDurationSeconds / videoDurationSeconds )*100 }%`;


                          const leftValue = (
                            Math.min(
                              ( startSeconds / videoDurationSeconds )*100,
                              98
                            )
                          );

                          const left = `${ leftValue }%`;

                          return (
                            <button
                              key={j}
                              className={classes.segmentButton}
                              style={{
                                width,
                                left
                              }}
                              onClick={() => this.onClick( startSeconds )}
                            />
                          )
                        })
                      }
                    </div>
                  </Grid>
                </React.Fragment>
              )
            }))
        }
      </Grid>
    );
  }
}



VideoAnalysis.defaultProps = {
  videoAnalysisApiResponse: null,
  videoRecordingUrl: '',
  endedAt: null,
  createdAt: null,
}


const styles = theme => ({
  video: {
    width: '45%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: 25
  },
  segmentBarWrapper: {
    width: '100%',
    backgroundColor: 'whitesmoke',
    minHeight: 40,
    position: 'relative',
    display: 'flex',
  },
  description: {
    lineHeight: '40px'
  },
  descriptionGridItem: {
    marginBottom: 5,
  },
  segmentGridItem: {
    marginBottom: 5
  },
  segmentButton: {
    height: 15,
    borderRadius: 7.5,
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    cursor: 'pointer',
    marginTop: 12.5
  },
  select: {
    marginTop: 15,
    marginBottom: 15,
  },
  videoEntities: {
    marginTop: 15,
    marginBottom: 25,
  },
  formControl: {
    width: '100%'
  },
  labelTimelineGrid: {
    marginBottom: 5
  }
})


export default withStyles(styles)(
  VideoAnalysis
);