import React from 'react';
import Redirect from 'react-router-dom/Redirect';

import { withContext } from 'kn-react';
import { AuthContext } from './AuthProvider';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';

class AdminGuard extends React.Component {
  render(){
    if(this.isAdmin(this.props)){
      return this.props.children;
    } else {
      this.props.showSnackbar('Unauthorized.');
      return <Redirect to={this.props.redirectTo}/>;
    }
  }
}

AdminGuard.defaultProps = {
  redirectTo: '/',
  isAdmin: props => props.user.admin
};


export default withContext(AuthContext, SnackbarContext, AdminGuard);
