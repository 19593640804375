/*global window*/
import axios from 'axios';
import { log } from 'kn-react';
import L from 'leaflet';
import React from 'react';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import './ToggleMap.css';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



class ToggleMap extends React.Component {
  state = {
    currentGeoTypesTitle: ''
  }

  componentDidMount = () => {
    if( typeof window === 'undefined' ){
      return;
    }


    window.mapComponent = this;

    this.map.doubleClickZoom.disable();

    const overlayPane = this.map.getPane('overlayPane');
    this.map.createPane('excludedPane', overlayPane);
    this.map.createPane('includedPane', overlayPane);
    this.map.createPane('clickPane', overlayPane);

    this.includedLayer = L.geoJSON(null, {
      pane: 'includedPane',
      style: this.includedStyle,
    }).addTo(this.map);

    this.excludedLayer = L.geoJSON(null, {
      pane: 'excludedPane',
      style: this.excludedStyle,
    }).addTo(this.map);

    this.clickLayer = L.geoJSON(null, {
      pane: 'clickPane',
      style: this.defaultStyle,
      onEachFeature: this.onEachFeature,
    }).addTo(this.map);


    // this.map.on('zoomend', this.drawAfterTimeout);
    this.map.on('zoomend', this.onZoomEnd);
    this.map.on('moveend', this.drawAfterTimeout);

    this.drawWithLeaflet();

    // Some geographies do not load on initial load. Redraw shapes
    // after a timeout to ensure all are present
    setTimeout(this.drawWithLeaflet, 500);
  }


  onZoomEnd = () => {
    log('zoom end')
    const zoom = this.map.getZoom();
    if(this.props.onZoomEnd){
      this.props.onZoomEnd(zoom);
    }
    this.cleanLayers(zoom)
    this.drawAfterTimeout();
  }

  cleanLayers = (zoom) => {
    const currentGeoTypes = this.getCurrentGeoTypes(zoom);
    const layers = ['state','county','county_subdivision','place','zcta'];
    let clearLayers = layers;
    // if (currentGeoTypes[0] === 'zcta') {
    //   return;
    // } else if (currentGeoTypes[0] === 'county_subdivision') {
    //   clearLayers = ['zcta']
    // } else {
    //   clearLayers = layers.slice(layers.indexOf(currentGeoTypes[0]) + 1);
    // }

    const { includedGeographies,excludedGeographies } = this.props;
    const geos = [...includedGeographies,...excludedGeographies];

    this.clickLayer.eachLayer(layer => {
      if (
          clearLayers.includes(layer.feature.properties.geo_type) &&
          !geos.includes(layer.feature.properties.geo_type_id)
          ) {
        this.clickLayer.removeLayer(layer);
      }
    })
  }



  onMoveEnd = () => {
    if(this.props.onMoveEnd){
      const bounds = this.map.getBounds();
      this.props.onMoveEnd(bounds);
    }
    this.drawAfterTimeout();
  }



  drawAfterTimeout = () => {
    log('drawAfterTimeout')
    clearTimeout(this.drawTimeout);
    this.drawTimeout = setTimeout( () => this.drawWithLeaflet(), 200);
  }


  componentDidUpdate = () => {
    if (this.props.showPositionIcon && !this.didAddPositionIcon) {
      var positionIcon = L.divIcon({
        className: 'css-icon',
        html: `
          <div class="gps-wrapper">
            <div class="gps-ring"></div>
            <div class="gps-dot"></div>
          </div>
        `,
        iconSize: [5,5]
      });
      L.marker(positionWithDefaults( this.props.position ), {icon: positionIcon}).addTo(this.map);
      this.didAddPositionIcon = true;
    }

    this.addMissingGeographies(this.includedLayer, this.props.includedGeographies);
    this.addMissingGeographies(this.excludedLayer, this.props.excludedGeographies);
    this.resetDeselectedGeographies(this.props);
  }


  addMissingGeographies = (layer, geographies) => {
    const layerGeographies = [];
    layer.eachLayer(l => {
      layerGeographies.push( l.feature.properties.geo_type_id );
    });

    this.clickLayer.eachLayer(l => {
      const geoTypeId = l.feature.properties.geo_type_id;
      if (geographies.indexOf(geoTypeId) > -1 && layerGeographies.indexOf(geoTypeId) < 0) {
        layer.addData(l.feature);
      }
    });
  }


  resetDeselectedGeographies = nextProps => {
    const { includedGeographies, excludedGeographies } = this.props;

    const deselectedIncludedGeographies = includedGeographies.filter(g => {
      return nextProps.includedGeographies.indexOf(g) < 0
    });
    const deselectedExcludedGeographies = excludedGeographies.filter(g => {
      return nextProps.excludedGeographies.indexOf(g) < 0
    });

    if (!deselectedIncludedGeographies.length && !deselectedExcludedGeographies.length) {
      return;
    }

    this.removeDeselectedFromLayer(this.includedLayer, deselectedIncludedGeographies);
    this.removeDeselectedFromLayer(this.excludedLayer, deselectedExcludedGeographies);
  }

  removeDeselectedFromLayer = (layer, deselectedGeographies) => {
    layer.eachLayer(l => {
      const geo_type_id = l.feature.properties.geo_type_id;
      if (deselectedGeographies.indexOf(geo_type_id) >= 0){
        layer.removeLayer(l)
      }
    });
  }


  defaultStyle = feature => {
    let fillColor = 'transparent';
    let strokeColor = this.props.strokeColor;

    return {
      weight: 1,
      fillColor: fillColor,
      fillOpacity: 0.5,
      color: strokeColor,
    }
  }


  includedStyle = feature => {
    return {
      ...this.getGeoTypeStyles(feature),
      fillColor: this.props.includedFill,
      color: this.props.includedStrokeColor,
    }
  }


  excludedStyle = feature => {
    return {
      ...this.getGeoTypeStyles(feature),
      fillColor: this.props.excludedFill,
      color: this.props.excludedStrokeColor,
    }
  }


  getGeoTypeStyles = feature => {
    const currentGeoTypes = this.getCurrentGeoTypes();
    const geoType = feature.properties.geo_type;
    const active = currentGeoTypes.indexOf(geoType) > -1;
    return {
      weight: active ? 1 : 1,
      fillOpacity: active ? 0.6 : 0.2,
    };
  }



  onEachFeature = (feature, layer) => {
    layer.on('click', e => {
      this.onClick(feature, layer);
    });
  }




  getCurrentGeoTypes = () => {
    const zoom = this.map.getZoom();
    if ( zoom < 8 ) {
      return ['state'];
    } else if (zoom <= 9) {
      return ['county'];
    } else if (zoom <= 10) {
      return ['place'];
    }
    else if (zoom > 10) {
      return ['zcta'];
    }
  }


  getZoomDelta = newZoom => {
    const zoom = this.map.getZoom();

    if( newZoom === 0 ){
      return 7 - zoom
    } else if( newZoom === 1 ){
      return 9 - zoom
    } else {
      return 11 - zoom
    }
  }


  onGeoTypeLegendChange = (e, value) => {
    const zoomDelta = this.getZoomDelta( value );

    if( zoomDelta >= 0 ){
      this.map.zoomIn( zoomDelta );
    } else{
      this.map.zoomOut( zoomDelta*-1 );
    }
  }


  drawWithLeaflet = () => {
    log('drawing with leaflet')
    const bounds = this.map.getBounds();

    let geoTypes = this.props.geoTypes || this.getCurrentGeoTypes();


    this.setState({
      currentGeoTypesTitle: this.getGeoTypeTitle(geoTypes)
    });

    const { includedGeographies, excludedGeographies } = this.props;
    const selectedFeatures = includedGeographies.concat(excludedGeographies);

    return axios.post(
      this.props.geoTypeIdsUrl,
      {
        south_west_lng: bounds._southWest.lng,
        south_west_lat: bounds._southWest.lat,
        north_east_lng: bounds._northEast.lng,
        north_east_lat: bounds._northEast.lat,
        geo_types: geoTypes,
      }
    )
    .then(response => {
      const geoTypeIdsInBounds = response.data.data;
      log('Found', geoTypeIdsInBounds, 'geographies');

      this.props.onGeographiesChange(geoTypeIdsInBounds);

      const features = selectedFeatures.concat(geoTypeIdsInBounds);
      const uniqueFeatures = features.filter((v, i, a) => a.indexOf(v) === i);

      // Get existing layers
      const existingFeatures = [];
      this.clickLayer.eachLayer(layer => {
        existingFeatures.push(layer.feature.properties.geo_type_id);
      });
      this.includedLayer.eachLayer(layer => {
        existingFeatures.push(layer.feature.properties.geo_type_id);
      });
      this.excludedLayer.eachLayer(layer => {
        existingFeatures.push(layer.feature.properties.geo_type_id);
      });


      this.includedLayer.eachLayer(layer => {
        layer.setStyle(this.includedStyle(layer.feature));
      });
      this.excludedLayer.eachLayer(layer => {
        layer.setStyle(this.excludedStyle(layer.feature));
      });

      // Skip polygons that were already drawn
      uniqueFeatures.forEach(geoTypeId => {
        if (existingFeatures.indexOf(geoTypeId) > -1) {
          return;
        }


        const url = `https://storage.googleapis.com/go-boost-partners-public/census_geographies/${ geoTypeId }.json`;
        axios.get(url)
        .then(response => {
          const feature = response.data;

          if(includedGeographies.indexOf(geoTypeId) > -1) {
            log('add includeLayer',feature)
            this.includedLayer.addData(feature);
          } else if (excludedGeographies.indexOf(geoTypeId) > -1){
            log('add excludeLayer',feature)
            this.excludedLayer.addData(feature);
          }

          if(geoTypes.indexOf(feature.properties.geo_type) > -1){
            log('add click layer',geoTypeId)
            this.clickLayer.addData(feature);
          }
        })
        .catch(error => log('error', error));
      });

    })
    .catch(error => log('error', error));
  }


  onClick = (feature, layer) => {
    if (this.props.canEdit){
      log('Clicked', feature);
      const geoTypeId = feature.properties.geo_type_id;
      const includedIndex = this.props.includedGeographies.indexOf(geoTypeId);
      const excludedIndex = this.props.excludedGeographies.indexOf(geoTypeId);

      if (excludedIndex > -1){
        this.removeExcludedGeography(geoTypeId, excludedIndex);
      }
      else if(includedIndex > -1){
        this.removeIncludedGeography(geoTypeId, includedIndex);
        this.addExcludedGeography(geoTypeId, feature);
      } else {
        this.addIncludedGeography(geoTypeId, feature);
      }
    }
  }


  removeIncludedGeography = (geoTypeId, includedIndex) => {
    log('removeIncludedGeography', geoTypeId);
    this.removeGeoTypeIdFromLayer(geoTypeId, this.includedLayer);
    const includedGeographies = [...this.props.includedGeographies];
    includedGeographies.splice(includedIndex, 1);
    this.props.onIncludedGeographiesChange(includedGeographies);
  }

  removeExcludedGeography = (geoTypeId, excludedIndex) => {
    log('removeExcludedGeography', geoTypeId);
    this.removeGeoTypeIdFromLayer(geoTypeId, this.excludedLayer);
    const excludedGeographies = [...this.props.excludedGeographies];
    excludedGeographies.splice(excludedIndex, 1);
    this.props.onExcludedGeographiesChange(excludedGeographies);
  }

  addExcludedGeography = (geoTypeId, feature) => {
    log('addExcludedGeography', geoTypeId);
    this.excludedLayer.addData(feature);
    const excludedGeographies = [...this.props.excludedGeographies];
    excludedGeographies.push(geoTypeId);
    this.props.onExcludedGeographiesChange(excludedGeographies);
  }


  addIncludedGeography = (geoTypeId, feature) => {
    this.includedLayer.addData(feature);
    const includedGeographies = [...this.props.includedGeographies];
    includedGeographies.push(geoTypeId);
    this.props.onIncludedGeographiesChange(includedGeographies);
    log('includedLayers');
    log('clickLayers');
  }


  removeGeoTypeIdFromLayer = (geoTypeId, parentLayer) => {
    parentLayer.eachLayer(layer => {
      if (geoTypeId === layer.feature.properties.geo_type_id){
        log('Removing', geoTypeId);
        parentLayer.removeLayer(layer);
      }
    });
  }


  mapStyle = (mapStyleKey) => {
    const mapStyles = {
      wikimedia: {
        url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png',
        attribution: '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
        maxZoom: 19,
      },
      cartodb: {
        url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http/://cartodb.com/attributions">CartoDB</a>',
        maxZoom: 19,
        subdomains: 'abcd',
      }
    }
    return mapStyles[mapStyleKey];
  }


  getGeoTypeTitle = currentGeoTypes => {
    const join = currentGeoTypes.join(',');
    log('join', join);
    const titles = {
      'state': 'States',
      'county': 'Counties',
      'zcta': 'ZIP Codes',
      'county_subdivision,place': 'Cities and Towns',
    }
    return titles[join];
  }


  render() {
    const mapStyle = this.mapStyle(this.props.mapStyle);

    const geoTypes = ['States', 'Counties', 'ZIP Codes'];

    return (
      <div className="Map">
        <div style={{...defaultGeoTypeStyles, ...this.props.geoTypeStyles }}>
          { this.state.currentGeoTypesTitle }
        </div>

        <div style={{...defaultGeoTypeSelectorStyles, ...this.props.geoTypeSelectorStyles }}>
          <Paper square>
            <Tabs
              value={geoTypes.findIndex(g => this.state.currentGeoTypesTitle === g)}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.onGeoTypeLegendChange}
            >
              {
                geoTypes.map((g, i) => (
                  <Tab key={i} label={g} />
                ))
              }
            </Tabs>
          </Paper>
        </div>

        <LeafletMap
          tap={false}
          center={positionWithDefaults( this.props.position )}
          zoom={9}
          style={{...defaultMapStyles, ...this.props.style }}
          ref={mapRef => this.map = mapRef ? mapRef.leafletElement : null}>
            <TileLayer
              {...mapStyle}
              style={{ opacity: 0.9 }}
            />
        </LeafletMap>
      </div>
    );
  }
}


ToggleMap.defaultProps = {
  geoTypeIdsUrl: '/api/ads/census_geographies/geo_type_ids_in_bounds',
  geoTypes: null,
  strokeColor: '#999',
  includedFill: '#ECF3F7',
  includedStrokeColor: '#7ADAB7',
  excludedFill: 'red',
  excludedStrokeColor: 'red',
  includedGeographies: [],
  excludedGeographies: [],
  onClick: e => {},
  onGeographiesChange: e => {},
  onIncludedGeographiesChange: e => {},
  onExcludedGeographiesChange: e => {},
  onZoomEnd: null,
  onMoveEnd: null,
  position: [],
  style: {},
  showPositionIcon: false,
  mapStyle: 'cartodb',
  geoTypeStyles: {},
  canEdit: true,
}

const defaultMapStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};

const defaultGeoTypeStyles = {
  position: 'absolute',
  bottom: 10,
  left: 10,
  zIndex: 1200,
  textShadow: '0px 0px 10px rgba(255,255,255, 1), 0px 0px 10px rgba(255,255,255, 1), 0px 0px 10px rgba(255,255,255, 1), 0px 0px 10px rgba(255,255,255, 1)'
};

const defaultGeoTypeSelectorStyles = {
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: 1200,
  textShadow: '0px 0px 10px rgba(255,255,255, 1), 0px 0px 10px rgba(255,255,255, 1), 0px 0px 10px rgba(255,255,255, 1), 0px 0px 10px rgba(255,255,255, 1)'
};


const positionWithDefaults = position => ([
  ( position.length ? position[0] : null ) || 40.4427,
  ( position.length > 1 ? position[1] : null ) || -79.9430
])


export default ToggleMap;
