import React from 'react';
import AppDrawer from './AppDrawer/AppDrawer';
import AppBar from './AppBar';

class AppNavigation extends React.Component {
  state = {
    drawerOpen: false,
  };

  toggleDrawer = drawerOpen => {
    this.setState({ drawerOpen })
  }

  render(){
    const { appDrawerLinks, user, title } = this.props;

    return (
      <div>
        <AppDrawer
          open={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
          user={user}
          links={appDrawerLinks}
        />
        <AppBar
          openDrawer={() => this.toggleDrawer(true)}
          title={title}
          menuItems={this.props.appBarMenuItems}
          collapseMobile={this.props.collapseMobile}
        />
        <div style={{ marginTop: 64 }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}


AppNavigation.defaultProps = {
  user: {},
  appDrawerLinks: [],
  appBarMenuItems: [],
};

export default AppNavigation;

