import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';


class BlankAd extends React.PureComponent {

  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.searchAd }>
        <a href="#" className={ classes.searchAdLink + ' ' + classes.lineOne } onClick={e => e.preventDefault()}/>

        <div style={{ position: 'relative' }}>
          <span className={classes.lineTwo}/>
        </div>
        <div className={classes.lineThree}/>
      </div>
    );
  }

}

BlankAd.defaultProps = {
  headline: '',
  displayUrl: '',
  phoneNumber: '(855) 212-3359',
  description: '$25 Off Heating Repair Services. Where Integrity Matters. Call For Fast Service!',
};


const styles = {
  searchAd: {
    fontFamily: "'Roboto',arial,sans-serif",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 7,
    marginBottom: 28,
    maxWidth: 632,
    overflow: 'hidden',
  },

  searchAdLink: {
    textDecoration: 'none',
    display: 'block',
    color: '#1a0dab',
    fontSize: 18,
  },

  lineOne: {
    height: 21,
    backgroundColor: '#1a0dab',
    opacity: 0.1
  },

  lineTwo: {
    marginTop: 3,
    height: 16,
    width: 310,
    backgroundColor: '#006621',
    opacity: 0.1,
    display: 'block'
  },

  lineThree: {
    marginTop: 3,
    height: 16,
    backgroundColor: '#545454',
    opacity: 0.1
  },
};
export default withStyles(styles)(BlankAd);

