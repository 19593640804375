import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';

class NewChip extends React.PureComponent {
	render() {

		const { classes } = this.props;

		return (
			<Chip
				color='primary'
				// label={ this.props.label || 'Form' }
				label={ this.props.label || 'Virtual Work Request' }
				classes={{ colorPrimary: classes.chipClass }}
			/>
		);
	}
}

const styles = theme => ({
	chipClass: {
		backgroundColor: '#FFFF9D',
		borderRadius: 3,
		color: '#7D7D04',
		fontSize: 14,
	}
});


export default withStyles(styles, { withTheme: true })(NewChip);