import React from 'react';
import DevicesOther from '@material-ui/icons/DevicesOther';
import withStyles from '@material-ui/core/styles/withStyles';

class OtherDevicesIcon extends React.Component {

  render() {

    const { classes } = this.props;

    return (
      <DevicesOther className={classes.otherDevicesIcon} />
    )
  }
}

const styles = theme => ({
  otherDevicesIcon: {
    color: '#5a7af9',
    float: 'left'
  }
})

OtherDevicesIcon.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(OtherDevicesIcon);