import React from 'react';

import { withContext, log } from 'kn-react';
import Axios from 'axios';

import { LinearProgress } from '@material-ui/core';

import { UserRoleContext } from '../UserRoles/UserRoleProvider'
import CompanyModel from './CompanyModel';
import PlanModel from '../Plans/PlanModel';
import PlanProvider from '../Plans/PlanProvider';
import SubscriptionModel from '../Subscription/SubscriptionModel';



const DEFAULT_STATE = {
  companyRoleContextLoading: true,
  company: {},
  plans: [],
  annualDomainPlan: {},
  subscriptions: []
};


export const CompanyRoleContext = React.createContext(DEFAULT_STATE);

// When you switch roles, we need to load the properties of the role you just signed in as...

class CompanyRoleProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  }


  componentDidUpdate = oldProps => {
    if( oldProps.currentUserRole.id !== this.props.currentUserRole.id ){
      return this.setState(
        { companyRoleContextLoading: true },
        this.fetchData
      );
    }
  }


  fetchData = () => {
    return this.fetchCompany()
      .then(this.fetchCompanyPlans)
      .then(this.fetchAnnualDomainPlan)
      .then(this.fetchCompanySubscriptions)
      .finally(() => this.setState({ companyRoleContextLoading: false }));
  }


  fetchCompany = () => {
    const { currentUserRole } = this.props;

    let url = `/api/core/companies/${ currentUserRole.roleTypeId }`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.get(
      url,
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      log('fetchCompany response', response);
      const company = CompanyModel.fromJSON(response.data.company);
      log('fetchCompany company', company);
      this.setState({ company });
    });
  }


  fetchCompanyPlans = () => {
    const { currentUserRole } = this.props;

    let url = `/api/core/companies/${ currentUserRole.roleTypeId }/plans`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.get(
      url,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchCompanyPlans response', response);
      const plans = response.data.plans.map(p => PlanModel.fromJSON(p));
      log('fetchCompanyPlans plans', plans);
      this.setState({ plans });
    });
  }


  fetchAnnualDomainPlan = () => {
    const { currentUserRole } = this.props;

    let url = `/api/core/companies/${ currentUserRole.roleTypeId }/plans/sites/annual_domain`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.get(
      url,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      log('fetchAnnualDomainPlan response', response);
      const annualDomainPlan = PlanModel.fromJSON(
        response.data.plan
      );

      log('fetchAnnualDomainPlan annualDomainPlan', annualDomainPlan);
      this.setState({ annualDomainPlan });
    }).catch(error => {
      log(error)
      if( error.response.status === 404 ){
        return;
      }


      throw error;
    });
  }


  fetchCompanySubscriptions = () => {
    const { currentUserRole } = this.props;

    let url = `/api/core/companies/${ currentUserRole.roleTypeId }/subscriptions`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.get(
      url,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const subscriptions = response.data.subscriptions.map(s => SubscriptionModel.fromJSON(s));
      log('fetchCompanySubscriptions', subscriptions);
      this.setState({ subscriptions });
    }).catch(error => {
      log('fetchCompanySubscriptions error', error);
      if(error.response.status === 404) {
        log('No subscriptions found');
      } else {
        throw error;
      }
    });
  }



  findOrCreateCustomer = () => {
    log('findOrCreateCustomer');
    const { company } = this.state;

    if(company.stripeCustomerId) {
      return Promise.resolve(company.stripeCustomerId);
    }

    const { currentUserRole } = this.props;

    let url = `/api/core/companies/${ company.id }/customer`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.post(
      url,
      {
        email: currentUserRole.userEmail,
        description: `${ currentUserRole.userFirstName } ${ currentUserRole.userLastName }, ${ company.name }`,
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      this.fetchCompany
    );
  }



  updatePaymentMethod = stripeTokenId => {
    log('updatePaymentMethod', stripeTokenId);
    const { company } = this.state;

    let url = `/api/core/companies/${ company.id }/payment_method`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.put(
      url,
      { stripe_token_id: stripeTokenId },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      this.fetchCompany
    );
  }


  findOrCreateSubscription = (selectedPlanId, stripeCouponId) => {
    log('findOrCreateSubscription');
    const { subscriptions } = this.state;
    const existingSubscription = subscriptions.find(s => s.planId === selectedPlanId);


    if( existingSubscription ){
      return this.fetchCompany();
    }

    return this.createSubscription(selectedPlanId, stripeCouponId)
    .then(
      this.parseSubscriptionResponse
    ).then(
      this.fetchCompany
    ).catch(error => {
      if (error.response.status === 409) {
        log('Subscription already exists:', error);
      } else {
        throw error;
      }
    });
  }


  createSubscription = (planId, stripeCouponId) => {
    log('createSubscription', planId);

    let url = `/api/core/subscriptions`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.post(
      url,
      { plan_id: planId, stripe_coupon_id: stripeCouponId },
      { headers: this.props.getUserRoleAuthHeaders() }
    );
  }


  parseSubscriptionResponse = response => {
    const subscriptions = response.data.subscriptions.map(
      s => SubscriptionModel.fromJSON( s )
    );

    log('parseSubscriptionResponse', subscriptions);
    this.setState({ subscriptions });
  }


  updateCompany = updatedAttributes => {
    log('updateCompany', updatedAttributes);
    const { id } = this.state.company;
    const company = new CompanyModel(updatedAttributes);

    if(!updatedAttributes.serviceArea) {
      delete company.serviceArea;
    }


    let url = `/api/core/companies/${ id }`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.put(
      url,
      { company },
      { headers: this.props.getUserRoleAuthHeaders()}
    ).then(response => {
      log('updateCompany response', response);
      const company = CompanyModel.fromJSON(response.data.company);
      this.setState({ company });
    });
  }


  render(){
    const { companyRoleContextLoading } = this.state;

    if( companyRoleContextLoading && this.props.isLinearProgressDisabled ){
      return <React.Fragment></React.Fragment>;
    } else if( companyRoleContextLoading ) {
      return <LinearProgress/>;
    }

    return (
      <CompanyRoleContext.Provider
        value={{
          company: this.state.company,
          updateCompany: this.updateCompany,
          findOrCreateCustomer: this.findOrCreateCustomer,
          updatePaymentMethod: this.updatePaymentMethod,
          findOrCreateSubscription: this.findOrCreateSubscription,
          currentUserRole: this.props.currentUserRole,
          getUserRoleAuthHeaders: this.props.getUserRoleAuthHeaders,
          fetchCompanyRoleProviderData: this.fetchData,
        }}
      >
        <PlanProvider
          currentUserRole={this.props.currentUserRole}
          plans={this.state.plans}
          subscriptions={this.state.subscriptions}
          annualDomainPlan={this.state.annualDomainPlan}
        >
          { this.props.children }
        </PlanProvider>
      </CompanyRoleContext.Provider>
    );
  }
}



CompanyRoleProvider.defaultProps = {
  baseUrl: null,
  isLinearProgressDisabled: false
}



export default withContext(UserRoleContext, CompanyRoleProvider);