/*global chrome*/
import React from 'react';
import { log, withContext, randomString, stringifyParamsObject } from 'kn-react';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';
import isDevelopment from '../UtilityFunctions/isDevelopment';
import isDemo from '../UtilityFunctions/isDemo';
import isStaging from '../UtilityFunctions/isStaging';


const DEFAULT_STATE = {
  oauthState: '',
  isProcessingFacebookAccount: false,
  pageDialogOpen: false,
  confirmationDialogOpen: false,
};


export const FacebookAuthContext = React.createContext(DEFAULT_STATE);


class FacebookAuthProvider extends React.Component {
  state = DEFAULT_STATE;

  // Event Listeners
  componentDidMount = () => {
    if( typeof window === 'undefined' ){
      return;
    }


    this.setState(
      {
        oauthState: `${window.location.origin}-${randomString()}`
      },
      () => window.addEventListener('message', this.onMessage)
    );
  }

  componentWillUnmount = () => {
    if( typeof window === 'undefined' ){
      return;
    }


    window.addEventListener('message', this.onMessage);
  }


  onMessage = messageEvent => {
    const { oauthState, facebookCode } = messageEvent.data;
    if (oauthState !== this.state.oauthState) {
      return;
    }

    log('messageEvent', messageEvent);

    const callback = () => this.setState({ pageDialogOpen: true, isProcessingFacebookAccount: false });
    const errorCallback = () => this.setState({ isProcessingFacebookAccount: false });

    this.setState(
      {
        isProcessingFacebookAccount: true
      },
      () => this.props.onCode(facebookCode, callback, errorCallback)
    );
  }


  // Facebook oauth login popup
  startFacebookOauth = () => {

    if (
      typeof window === 'undefined' ||
      !this.props.canSignIn
    ) {
      return;
    }

    let uriBase;

    if (isDevelopment()) {
      uriBase = window.location.origin;
    } else if (isDemo()) {
      uriBase = 'https://demo.kinertia-apps.com';
    }  else if (isStaging()) {
      uriBase = 'https://staging.kinertia-apps.com';
    } else {
      uriBase = 'https://bots.kinertia-apps.com';
    }

    const facebookOauthUrl = 'https://www.facebook.com/v10.0/dialog/oauth';
    const params = {
      client_id: this.props.clientId,
      redirect_uri: `${ uriBase }${ this.props.facebookRedirectUrl }`,
      scope: 'email,public_profile,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_show_list,pages_manage_posts,pages_manage_engagement,ads_management,business_management,read_insights',
      response_type: 'code',
      state: this.state.oauthState
    };

    const popup = window.open(
      facebookOauthUrl + '?' + stringifyParamsObject(params),
      'Login to Facebook',
      'height=600,width=990'
    );

    if (window.focus) {
      popup.focus();
    }
  }


  onSelectPage = page => {
    this.props.onAuthPageComplete(page);
    this.setState({ pageDialogOpen: false });
  }


  closePageDialog = () => {
    this.setState({ pageDialogOpen: false });
  }


  openConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: true });
  }


  closeConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: false });
  }


  onSignOut = () => {
    if (!this.props.canSignOut) {
      return;
    }

    this.setState(
      {
        confirmationDialogOpen: false,
        isProcessingFacebookAccount: true
      },
      this.props.signOut
    );
  }


  tryAgain = e => {
    e.preventDefault();
    if (this.props.canSignIn) {
      this.setState({ pageDialogOpen: true });
    }
  }


  render() {
    return (
      <FacebookAuthContext.Provider
        value={{
          startFacebookOauth: this.startFacebookOauth,
          onSelectPage: this.onSelectPage,
          closePageDialog: this.closePageDialog,
          confirmationDialogOpen: this.state.confirmationDialogOpen,
          openConfirmationDialog: this.openConfirmationDialog,
          closeConfirmationDialog: this.closeConfirmationDialog,
          onSignOut: this.onSignOut,
          canSignIn: this.props.canSignIn,
          canSignOut: this.props.canSignOut,
          tryAgain: this.tryAgain,
          pageDialogOpen: this.state.pageDialogOpen,
          onSelectPage: this.onSelectPage,
          isProcessingFacebookAccount: this.state.isProcessingFacebookAccount
        }}
      >
        {this.props.children}
      </FacebookAuthContext.Provider>
    );
  }
}

FacebookAuthProvider.defaultProps = {
  onCode: function(){},
  clientId: '',
  facebookRedirectUrl: '/auth/facebook/callback',
  signOut: function(){},
  canSignIn: false,
  canSignOut: false,
  onAuthPageComplete: function(){}
}


export default withContext(SnackbarContext, FacebookAuthProvider);