import React from 'react';
import { withContext } from 'kn-react';


import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import FileCopy from '@material-ui/icons/FileCopy';

import { SnackbarContext } from '../Snackbar/SnackbarProvider';



const createPremadeResponses = setupDetails => {
  const { isVirtualAvailable } = setupDetails;

  const responses = [
    {
      primary: 'Instant Quote',
      secondary: 'Get started right away and quickly send the customer a quote',
      createText: options => `Hi${ !options.firstName ? '' : `, ${ options.firstName }` } - thanks for getting in touch with us. I had a chance to review your request, and based on the information you provided, we can get the job done for [INSERT QUOTE HERE]. Let's talk about getting you on our schedule.`,
      createHref: options => `mailto:${ options.email }?subject=Quote&body=${ options.createText( options ) }`
    }
  ];

  if( isVirtualAvailable ){
    responses.push({
      primary: 'Visit our Virtual Lobby',
      secondary: 'Have the customer visit your Virtual Lobby Meeting Room',
      createText: options => `Hi${ !options.firstName ? '' : `, ${ options.firstName }` } - thanks for getting in touch with us. We want to take care of your request as quickly as possible but need a little more information first. The best and quickest way to get the information we need is for you to visit our Virtual Meeting Room at [INSERT MEETING ROOM URL HERE]. Just join at your earlier convenience and one of our staff members will already be in there ready to talk. We hope to see you in our Virtual Meeting Room soon.`,
      createHref: options => `mailto:${ options.email }?subject=Visit Our Virtual Lobby&body=${ options.createText( options ) }`
    });

    responses.push({
      primary: 'Meet With Us Virtually',
      secondary: 'Schedule a time with the customer to meet in your Virtual Meeting Room',
      createText: options => `Hi${ !options.firstName ? '' : `, ${ options.firstName }` } - thanks for getting in touch with us. We want to take care of your request as quickly as possible but need a little more information first. The best and quickest way to get the information we need is for you to meet with us virtually in one of our Virtual Meeting Rooms. Are you available to meet with us today? We look forward to meeting with you.`,
      createHref: options => `mailto:${ options.email }?subject=Meet With Us Virtually&body=${ options.createText( options ) }`,
    });
  }

  responses.push({
    primary: 'Welcome New Customer',
    secondary: 'Welcome and thank a new customer for choosing your business',
    createText: options => `Hi${ !options.firstName ? '' : `, ${ options.firstName }` } - thank you for choosing ${ options.name }! I had a chance to review your request for an estimate. I think we can get to you very soon. Let's talk about getting you on our schedule, and we look forward to working with you!`,
    createHref: options => `mailto:${ options.email }?subject=Thanks for choosing ${ options.name }!&body=${ options.createText( options ) }`
  });


  return responses;
}


class OpportunitySuggestedResponses extends React.Component {
  copyToClipboard = text => navigator.clipboard.writeText(
    text
  ).then(
    () => {
      this.props.showSnackbar(
        `Suggested email text successfully copied to your clipboard.`
      );

      if( this.props.copyToClipboard ){
        return this.props.copyToClipboard(
          text
        );
      }
    }
  );



  render(){
    const { classes } = this.props;


    return(
      <React.Fragment>
        <Typography variant='body1'>
          Need help getting started? Start with a customized premade email to the customer.
        </Typography>

        <List>
          {
            createPremadeResponses({ isVirtualAvailable: true }).map(
              (r, i) => {
                const options = {
                  ...r,
                  email: this.props.opportunity.email || '',
                  firstName: this.props.firstName || this.props.opportunity.name || '',
                  name: this.props.companyName || ''
                };


                const href = r.createHref( options );
                const text = r.createText( options );

                return (
                  <ListItem
                    key={i}
                    component={'a'}
                    href={href}
                    className={classes.listItem}
                  >
                    <ListItemText
                      primary={r.primary}
                      secondary={r.secondary}
                    />

                    <ListItemSecondaryAction>
                      <Tooltip
                        title='Copy email message to clipboard'
                      >
                        <IconButton
                          onClick={() => this.copyToClipboard( text )}
                        >
                          <FileCopy />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              }
            )
          }
        </List>
      </React.Fragment>
    );
  }
}


const styles = theme => ({
  listItem: {
    '&:hover': {
      backgroundColor: 'whitesmoke'
    }
  },
});


OpportunitySuggestedResponses.defaultProps = {
  opportunity: {},
  firstName: null,
  companyName: null,
  copyToClipboard: () => {}
}


export default withContext(
  SnackbarContext,
  withStyles(styles)(
    OpportunitySuggestedResponses
  )
);