import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon className={props.className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
		<g fillRule="evenodd">
			<path d="M12.918 9.286v3.599H2.082v-3.6H0v4.658A1.05 1.05 0 0 0 1.043 15h12.914A1.05 1.05 0 0 0 15 13.943V9.286h-2.082z"/>
			<path d="M7.685 9.155L4.69 5.695s-.455-.412.039-.412h1.687v-.704V.245S6.35 0 6.736 0H9.11c.278 0 .272.207.272.207V5.179h1.557c.6 0 .148.43.148.43S8.54 8.845 8.185 9.184c-.255.246-.5-.028-.5-.028z"/>
		</g>
	</SvgIcon>
);
