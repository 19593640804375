import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class Incrementer extends React.PureComponent {

  render() {
    const { classes } = this.props;

    return (
      <div style={{ position: 'relative', height: 60, }}>
        <IconButton
          onClick={this.props.onDecrement}
          variant="outlined"
          color="primary"
          className={ classes.iconButton + ' ' + classes.leftButton }
        >
          <RemoveIcon/>
        </IconButton>

        <div>
          <div className={classes.total}>
            {this.props.value || '$50,000.00'}
          </div>

          <div className={classes.label}>
            { this.props.label || 'TODAY\S TOTAL' }
          </div>
        </div>

        <IconButton
          onClick={this.props.onIncrement}
          variant="outlined"
          color="primary"
          className={ classes.iconButton + ' ' + classes.rightButton }
        >
          <AddIcon/>
        </IconButton>
      </div>
    );
  }
}


const styles = theme => ({
  iconButton: {
    position: 'absolute',
    top: 6,
    fontSize: 30,
    backgroundColor: 'whitesmoke'
  },
  leftButton: {
    left: 0,
  },
  rightButton: {
    right: 0,
  },
  total: {
    fontSize: 40,
    height: 50,
    textAlign: 'center'
  },
  label: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
});

Incrementer.defaultProps = {
  onDecrement: () => {},
  onIncrement: () => {},
}


export default withStyles(styles)(Incrementer);