import React from 'react';
import { Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Button
} from '@material-ui/core';

class ReviewsSignOutConfirmationDialog extends React.Component {

  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        onClose={e => this.props.onClose()}
      >
        <DialogTitle>Are you sure?</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Signing out of your { this.props.sourceName } account will delete all of the reviews associated with it. <strong>This action cannot be undone.</strong>
          </DialogContentText>
          <DialogContentText>

          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color="primary" onClick={this.props.onClose}>
            Cancel
          </Button>

          <Button variant='contained' color="primary" onClick={this.props.onSignOut}>
            Sign Out
          </Button>
        </DialogActions>

      </Dialog>
    )
  }
}

ReviewsSignOutConfirmationDialog.defaultProps = {
  dialogOpen: false,
  onClose: function(){},
  onSignOut: function(){},
  sourceName: ''
}

export default ReviewsSignOutConfirmationDialog