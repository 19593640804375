/*global chrome*/
import React from 'react';
import Redirect from 'react-router-dom/Redirect';
import { withContext } from 'kn-react';

import { AuthContext } from './AuthProvider';
import { SnackbarContext } from '../Snackbar/SnackbarProvider';

class AuthRequiredGuard extends React.Component {
  render() {
    if (this.props.hasToken()) {
      return this.props.children;
    } else {
      this.props.showSnackbar('Please sign in to continue.', { autoHide: false });
      return (
        <Redirect
          to={{
            pathname: this.props.redirectTo,
            state: {
              referrer: (
                typeof window === 'undefined' ?
                  ''
                :
                  window.location.pathname + window.location.search
              )
            }
          }}
        />
      );
    }
  }
}


AuthRequiredGuard.defaultProps = {
  redirectTo: '/sign_in',
  hasToken: () => { }
};


export default withContext(AuthContext, SnackbarContext, AuthRequiredGuard);
