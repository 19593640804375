export default {
  reviews: {
    primary: '#fd9a51',
    dark: '#b16b38',
    light: '#fdae73',
    contrastText: '#ffffff',
  },
  ads: {
    primary: '#5C7EF5',
    dark: '#4058ab',
    light: '#7c97f7',
    contrastText: '#ffffff',
  },
  sites: {
    primary: '#40C9AD',
    dark: '#2c8c79',
    light: '#66d3bd',
    contrastText: '#ffffff',
  },
  social: {
    primary: '#9e70ff',
    dark: '#5A36BC',
    light: 'rgba(158, 112, 255, 0.2)',
    contrastText: '#ffffff',
  },
  virtual: {
    primary: '#CCC537',
    dark: '#7D7D04',
    light: '#FFFF9D',
    contrastText: '#ffffff',
  }
};