import React from 'react';
import { format } from 'kn-react';

export default props => {

    const textStyle = {
      position: 'absolute',
      lineHeight: `${ props.height }px` || '14px',
      fontSize: '12px',
      paddingLeft: 5,
    };


    const width = `${(props.widthPercent || 0) * 100}%`;
    return (
      <div
        style={{
          position: 'relative',
          height: props.height || 14,
          backgroundColor: props.backgroundColor || '#fff',
          marginBottom: 5,
        }}
      >
        <div style={{ position: 'relative', width:  `calc(100% - ${ props.marginRight || 90 }px)`}}>
          <div
            style={{
              width,
              height: 14,
              backgroundColor: props.color || '#37caad',
              position: 'absolute',
            }}
          />
          <div style={{...textStyle, left: `calc(${ width } + 5px)`, width: props.marginRight || 90}}>
            {format.percent(props.displayPercent || 0)} { props.number ? `(${ format.commas(props.number) })` : ''}
          </div>
        </div>


      </div>
    );
}